<template>
  <v-list two-line class="text-left" dense max-height="200">
    <v-list-item v-for="(act, index) in activity" :key="index">
      <v-list-item-content>
        <v-list-item-title>{{ act.title }}</v-list-item-title>
        <v-list-item-subtitle v-html="act.subtitle"></v-list-item-subtitle>
        <v-divider></v-divider>
      </v-list-item-content>
    </v-list-item>
  </v-list>
</template>
<script>
export default {
  props: {
    activity: Array
  }
}
</script>
