<template>
  <v-card flat tile color="grey lighten-5">
    <v-card-text v-if="response">
      <v-row class="px-2">
        <v-col cols="12" sm="3">
          <v-row no-gutters>
            <v-col cols="7">
              <v-card tile dark color="primary" flat height="120">
                <v-card-text>
                  <div class="headline h1 mt-4 white--text">
                    {{ project.events.recce.length }}
                  </div>
                  <div class="body-1 mt-2">Retail Sales</div>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="5">
              <v-card tile dark color="primary lighten-1" flat height="120">
                <v-card-text>
                  <div class="title mt-4 white--text">{{ recceCheckins }}</div>
                  <div class="caption mt-2">Checkins</div>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-col>

        <v-col cols="12" sm="3">
          <v-row no-gutters>
            <v-col cols="7">
              <v-card tile dark color="green" flat height="120">
                <v-card-text>
                  <div class="headline h1 mt-4 white--text">
                    {{ project.events.activations.length }}
                  </div>
                  <div class="body-1 mt-2">Activations</div>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="5">
              <v-card tile dark color="green lighten-1" flat height="120">
                <v-card-text>
                  <div class="title mt-4 white--text">
                    {{ activationsCheckins }}
                  </div>
                  <div class="caption mt-2">Checkins</div>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-col>

        <v-col cols="12" sm="3">
          <v-row no-gutters>
            <v-col cols="7">
              <v-card tile dark color="red" flat height="120">
                <v-card-text>
                  <div class="headline h1 mt-4 white--text">
                    {{ project.events.events.length }}
                  </div>
                  <div class="body-1 mt-2">Events</div>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="5">
              <v-card tile dark color="red lighten-1" flat height="120">
                <v-card-text>
                  <div class="title mt-4 white--text">{{ eventCheckins }}</div>
                  <div class="caption mt-2">Checkins</div>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" sm="3">
          <v-row no-gutters>
            <v-col cols="7">
              <v-card tile dark color="purple" flat height="120">
                <v-card-text>
                  <div class="headline h1 mt-4 white--text">
                    {{ project.reports.length }}
                  </div>
                  <div class="body-1 mt-2">Reports</div>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="5">
              <v-card tile dark color="purple lighten-1" flat height="120">
                <v-card-text>
                  <div class="title mt-4 white--text">
                    {{ reportSubmissions }}
                  </div>
                  <div class="caption mt-2">Responses</div>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
      </v-row>

      <v-card flat>
        <v-card-title class="body-2">Exported reports</v-card-title>
        <v-card-text>
          <v-data-table
            :headers="headers"
            :items="exports"
            :items-per-page="5"
            no-data-text="Exported reports will appear here"
          >
            <template v-slot:item.download="{ item }">
              <v-btn
                icon
                @click="downloadReport(item.name)"
                autocapitalize="sentences"
                color="primary"
                class="caption"
              >
              <v-icon>file_download</v-icon>

              </v-btn>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-card-text>
    <v-overlay absolute v-else>
      <v-progress-circular color="red"></v-progress-circular>
    </v-overlay>
  </v-card>
</template>
<script>
import ApiService from "@/services/ApiService";

export default {
  data() {
    return {
      response: null,
      headers: [
        {
          text: "Name",
          align: "start",
          sortable: false,
          value: "name",
        },
        { text: "Time requested", value: "requested" },
        { text: "Time completed", value: "completed" },
        { text: "Status", value: "status" },
        { text: "Download", value: "download" },
      ],
    };
  },
  mounted() {
    this.getProject();
  },
  methods: {
    getProject() {
      ApiService.getProject(this.$route.params.id)
        .then((response) => {
          this.response = response.data.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    downloadReport(name) {
      ApiService.downloadFiles(name).then(function(resp) {
        const headers = resp.headers;
        const blob = new Blob([resp.data], { type: headers["content-type"] });
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = name;
        link.click();
      });
    },
  },
  computed: {
    exports() {
      return this.response.exports;
    },
    project() {
      return this.response.project;
    },
  },
};
</script>
