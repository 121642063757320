import axios from "axios";

export default {
    namespaced: true,
    state: {
        token: null,
        user: null
    },
    mutations: {
        SET_TOKEN(state, token) {
            state.token = token
        },
        SET_USER(state, user) {
            state.user = user
        },
    },
    actions: {
        async signIn({dispatch}, credentials) {
            let response = await axios.post(`${process.env.VUE_APP_API_URL}/login`, credentials)
            await dispatch('attempt', response.data.access_token)
        },

        async attempt({commit, state}, token) {
            if (token) {
                commit('SET_TOKEN', token)

                localStorage.setItem('access_token', token)
                //axios.defaults.headers.common = {'Authorization': `Bearer ${token}`}
            }
            if (!state.token) {
                return
            }

            try {
                let response = await axios.get(`${process.env.VUE_APP_API_URL}/user`, {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                })
                commit('SET_USER', response.data)

            } catch (e) {
                commit('SET_TOKEN', null)
                commit('SET_USER', null)
            }
        },
        signOut({commit}) {
            return axios.get(`${process.env.VUE_APP_API_URL}/user`).then(() => {
                commit('SET_TOKEN', null)
                commit('SET_USER', null)
            })
        }
    },
    getters: {
        checkAuth(state) {
            return state.token != null
        },
        user(state) {
            return state.user
        },
        token(state){
            return state.token
        }
    }
}
