<template>
  <v-card :loading="loading"
      class="mx-auto text-left" :height="height" sm="100%">
    <v-img max-height="400"
           src="https://images.unsplash.com/photo-1593642632823-8f785ba67e45?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1489&q=80"
    >
    </v-img>
    <v-card-text>
      <div class="title black--text">{{ post.question }}</div>
      <div class="body-2 grey--text lighten-2">{{ post.answer }}</div>
      <v-chip small class="my-2" color="primary">{{ post.type }}</v-chip>
    </v-card-text>
  </v-card>
</template>

<script>
import axios from "axios";

export default {
  name: 'HelpPost',
  data: () => ({
    post: [],
    loading:false
  }),
  computed: {
    height() {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs':
        case 'sm':
          return '100vh'
        case 'md':
        case 'lg':
        case 'xl':
          return '100%'
      }
    },
  },
  mounted() {
    this.loadHelpPost()
  },
  methods:{
    loadHelpPost(){
      this.loading = true
      axios.get(`/help/${this.$route.params.id}`)
          .then(response => {
            this.loading = false
            this.post = response.data;
          })
          .catch(error => {
            console.log(error);
          });
    }
  }
}
</script>
