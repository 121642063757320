<template>
    <div>
        <v-container>

            <!--  Snackbar  -->
            <template>
                <div class="text-center">
                    <v-snackbar v-model="snackbar" :timeout="2000">
                        {{snackMessage}}
                        <template v-slot:action="{ attrs }">
                            <v-btn color="blue" text v-bind="attrs" @click="snackbar = false">Close</v-btn>
                        </template>
                    </v-snackbar>
                </div>
            </template>

            <v-card flat>
                <v-data-table
                        :headers="headers"
                        :items="this.customerQueries"
                        class="elevation-1"
                        :loading="this.loading"
                        loading-text="Loading... Please wait"
                >
                    <template v-slot:item.read="{ item }">
                        <v-btn v-model="isRead" color="primary" v-if="isRead" small @click="markUnread(item)">Read
                        </v-btn>
                        <v-btn v-model="isRead" v-else color="error" small @click="markRead(item)">Unread</v-btn>
                    </template>
                </v-data-table>
            </v-card>
        </v-container>
    </div>
</template>

<script>
    import axios from "axios";

    export default {
        name: "ContactMessagesTab",
        mounted() {
            this.getContactMessages()
        },
        data: () => ({
            customerQueries: [],
            isRead: null,
            loading: false,
            snackbar: false,
            snackMessage: null,
            headers: [
                {
                    text: 'Name',
                    align: 'start',
                    value: 'name',
                },
                {text: 'Company', value: 'company'},
                {text: 'Phone Number', value: 'phone'},
                {text: 'Email', value: 'email'},
                {text: 'Message', value: 'message'},
                {text: 'Marked as:', value: 'read', align: 'center'},
            ],
        }),
        methods: {

            // Gets Contact us Messages
            getContactMessages() {
                this.loading = true;
                axios
                    .get("/contact")
                    .then(response => {
                        this.customerQueries = response.data.data;
                        this.loading = false;
                    })
                    .catch(error => {
                        console.log(error);
                    });
            },
            markRead(item) {
                item.read = 1;
                this.isRead = true;
                axios.put(`https://develop.hokela.co.ke/api/admin/contact/${item.id}`, {
                    read: 1,
                }).then(response => {
                    if (response.status === 200) {
                        this.getContactMessages();
                        this.snackbar = true;
                        this.snackMessage =
                            "Message marked as Read.";
                    } else {
                        this.getContactMessages();
                        this.snackMessage =
                            "Unsuccessful. Please check your internet or try again later.";
                    }
                })
            },
            markUnread(item) {
                item.read = 0;
                this.isRead = false;
                axios.put(`https://develop.hokela.co.ke/api/admin/contact/${item.id}`, {
                    read: 0,
                }).then(response => {
                    if (response.status === 200) {
                        this.getContactMessages();
                        this.snackbar = true;
                        this.snackMessage =
                            "Message marked as unread";
                    } else {
                        this.getContactMessages();
                        this.snackMessage =
                            "Unsuccessful. Please check your internet or try again later.";
                    }
                })
            }
        }
    }
</script>
