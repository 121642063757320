<template>
    <div>
            <sidebar/>
        <div class="main_container">
            <TeamList/>
        </div>
    </div>
</template>

<script>
  import Sidebar from '../utils/Sidebar.vue';
  import TeamList from './TeamList.vue';

  export default {
    name: 'team',
    components: {
      Sidebar,
      TeamList,
    },
  };
</script>

