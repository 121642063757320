<template>
  <v-sheet height="100vh">
    <v-img :src="require('@/assets/ic_logo_blue.svg')" class="ml-5" max-height="100px"
           max-width="100px"></v-img>

    <v-row no-gutters>
      <v-col cols="12" md="6">
        <snackbar :text="snackMessage" :snackbar="snack" :color="snackColor"/>

        <!--Enter email to reset -->
        <v-card max-width="600" flat class="mx-auto mt-12 pa-2" outlined v-if="!resetting">
          <div>
            <div class="title" style="color: #0264E4">Password reset</div>
            <div class="caption ml-8">Enter your email</div>
          </div>
          <v-divider></v-divider>

          <v-card-text>
            <v-form v-model="valid" ref="form" lazy-validation>
              <v-text-field :rules="emailRules" v-model="email" type="email" solo flat outlined
                            label="Enter email address">
              </v-text-field>
            </v-form>
            <v-btn color="primary" dark block :loading="loading" @click="forgotPassword">reset password</v-btn>
          </v-card-text>
          <v-card-actions>
            <v-btn text link color="grey" class="caption mt-3" @click="goBack">Back to login</v-btn>

            <v-spacer></v-spacer>

            <v-btn text link color="grey" class="caption mt-3" @click="resetting=!resetting">Enter reset code</v-btn>
          </v-card-actions>
        </v-card>

        <!-- Complete password reset -->
        <v-card max-width="600" flat class="mx-auto mt-12 pa-2" outlined v-if="resetting">
          <div>
            <div class="title" style="color: #0264E4">Password reset</div>
            <div class="caption ml-8">Complete password reset</div>
          </div>
          <v-divider></v-divider>
          <v-card-text>
            <v-form v-model="reset" ref="reset" lazy-validation>
              <v-text-field v-model="token" :rules="[tokenRules.min, tokenRules.required]" type="text" solo flat
                            outlined :disabled="disabled"
                            label="Password reset token"
                            hint="A unique password reset can be found in the reset email that was sent to you">
              </v-text-field>

              <v-text-field :rules="[passwordRules.required, passwordRules.min]" v-model="password" type="password" solo
                            flat outlined
                            label="Enter new password">
              </v-text-field>

              <v-text-field validate-on-blur v-model="passwordConfirmation" :rules="[confirmationRules.equal]"
                            type="password" solo flat
                            outlined
                            label="Confirm new password">
              </v-text-field>
            </v-form>
            <v-btn color="primary" dark block :loading="loading" @click="resetPassword">complete password
              reset
            </v-btn>
          </v-card-text>
          <v-card-actions>
            <v-btn text link color="grey" class="caption mt-3" @click="goBack">Back to login</v-btn>

            <v-spacer></v-spacer>

            <v-btn text link color="grey" class="caption mt-3" @click="resetting=!resetting">Reset password</v-btn>
          </v-card-actions>
        </v-card>

      </v-col>
      <v-col cols="12" md="6">
        <v-card flat color="transparent">
          <v-card-text>
            <v-img :src="require('@/assets/login.png')" aspect-ratio="1.2" contain></v-img>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-footer padless="true">
      <v-card flat tile dark color="black" width="100%">
        <v-card-text></v-card-text>
        <v-card-text class="white--text">
          © 2019 - {{ new Date().getFullYear() }} — <strong>Hokela Interactive Africa</strong> All rights
          reserved
        </v-card-text>
      </v-card>
    </v-footer>

  </v-sheet>
</template>
<script>
import axios from "axios";

export default {
  data() {
    return {
      resetting: false,
      snackMessage: '',
      snack: false,
      snackColor: 'primary',
      loading: false,
      email: '',
      valid: true,
      reset: true,
      tokenRules: {
        required: value => !!value || 'Required.',
        min: v => v.length >= 4 || 'Min 4 characters',
      },
      emailRules: [
        v => !!v || 'E-mail is required',
        v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
      ],
      passwordRules: {
        required: value => !!value || 'Required.',
        min: v => v.length >= 8 || 'Min 8 characters',
      },
      confirmationRules: {
        equal: v => v === this.password || 'Confirmation does not match password'
      },
      password: null,
      passwordConfirmation: null,
      token: null,
      disabled: false,
    }
  },
  methods: {
    goBack() {
      this.$router.replace({name: 'login'})
    },

    forgotPassword() {
      this.loading = true
      this.$refs.form.validate()

      if (this.valid) {

        // reset password
        axios.post('/password/forgot', {
          email: this.email,
        })
            .then((response) => {
              this.snackColor = response.status === 200 ? 'primary' : 'red'
              this.snackMessage = response.data.message
              this.snack = true
              this.loading = false;
            })
            .catch((error) => {
              console.log(error);
              this.snackColor = 'red'
              this.snackMessage = error.message
              this.snack = true
              this.loading = false;
            });
      } else {
        this.snackMessage = 'Email cannot be empty'
        this.snackColor = 'red'
        this.snack = true
        this.loading = false
      }
    },
    resetPassword() {
      this.loading = true
      this.$refs.reset.validate()

      if (this.reset) {

        // reset password
        axios.post('/password/reset', {
          token: this.token,
          password: this.password,
          password_confirmation: this.passwordConfirmation,
        })
            .then((response) => {
              this.snackColor = response.status === 200 ? 'primary' : 'red'
              this.snackMessage = response.data.message
              this.snack = true
              this.loading = false;
              this.goBack()
            })
            .catch((error) => {
              console.log(error);
              this.snackColor = 'red'
              this.snackMessage = error.response.data.message
              this.snack = true
              this.loading = false;
            });
      }
    }
  },
  mounted() {
    if (this.$route.query.token && this.$route.query.token.length > 0) {
      this.resetting = true
      this.token = this.$route.query.token
      this.disabled = true
    }
  }
}
</script>
<style>
.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}

.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */
{
  opacity: 0;
}
</style>
