<template>
  <v-card tile flat class="d-flex flex-row align-stretch" height="100%" min-height="100vh">
    <v-navigation-drawer permanent color="primary" dark class="flex-shrink-0">
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title class="title">
            {{ user.name }}
          </v-list-item-title>
          <v-list-item-subtitle class="mt-3">
            <v-btn text :to="{ name: 'logout' }">Logout</v-btn>
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>

      <v-divider></v-divider>

      <v-list
          dense
          nav expand
          class="text-left"
      >
        <v-list-item link :to="{ name: 'account_page' }">
          <v-list-item-icon>
            <v-icon>assessment</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Overview</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item link :to="{ name: 'client_projects'}">
          <v-list-item-icon>
            <v-icon>assignment</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Projects</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item link :to="{ name: 'client_campaigns'}">
          <v-list-item-icon>
            <v-icon>work</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Campaigns</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item link :to="{ name: 'client_reports'}">
          <v-list-item-icon>
            <v-icon>description</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Reports</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-divider></v-divider>

        <v-list-item link :to="{ name: 'locations' }">
          <v-list-item-icon>
            <v-icon>location_on</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Retail Network</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item link :to="{ name: 'users' }">
          <v-list-item-icon>
            <v-icon>face</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Users</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
    <div class="flex-grow-1 flex-shrink-1">
      <v-toolbar flat>
        <v-toolbar-title>{{ user.account_name }}</v-toolbar-title>

        <v-spacer></v-spacer>
        <v-menu
            transition="slide-y-transition"
            close-on-content-click
            bottom
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
                icon
                light
                v-bind="attrs"
                v-on="on"
            >
              <v-icon>more_vert</v-icon>
            </v-btn>
          </template>
          <v-list class="text-left">
            <v-list-item>
              <v-list-item-title>
                <project :client_id="account.id"/>
              </v-list-item-title>
            </v-list-item>

            <v-list-item>
              <v-list-item-title>
                <v-btn text @click="manageUsers">
                  Manage Users
                </v-btn>
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-toolbar>
      <router-view></router-view>
    </div>
  </v-card>
</template>
<script>
import ProjectList from '../projects/ProjectList.vue';
import Users from '../utils/Users.vue';
import ViewMap from '../utils/map/ViewMap.vue';
import Project from "../modals/Project.vue";
import Overview from "../clients/Overview";
import ApiService from "@/services/ApiService";
import {mapGetters} from "vuex"

export default {
  name: 'tabs',
  components: {
    Overview,
    ProjectList,
    Users,
    ViewMap,
    Project,
  },
  data() {
    return {
      tab: null,
      loading: false,
      dialog: false,
      alert: false,
      alertMessage: '',
      reports: null,
      account: {
        account_name: 'Loading...',
        projects: [],
        admins: [],
      }
    };
  },
  computed: {
    ...mapGetters({
      user: 'auth/user'
    })
  },
  mounted() {
    this.getAccountInfo();
  },
  methods: {
    manageUsers() {
      this.$router.push({
        name: 'create_user'
      });
    },
    getAccountInfo() {
      this.overlay = true;
      let userId = this.user.account_type === 0 ? this.$route.params.id : this.user.id

      ApiService.getAccountInfo(userId)
          .then((response) => {
            this.account = response.data.data;
          })
          .catch((error) => {
            console.log(error);
          });
    },
  },
};
</script>
