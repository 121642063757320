<template>
  <v-dialog v-model="dialog" width="600" scrollable>
    <template v-slot:activator="{ on }">
      <v-btn text color="black" v-on="on" class="body-1">RSM</v-btn>
    </template>
    <v-card flat class="pa-3">
      <v-card-title>RSM</v-card-title>
      <v-card-subtitle> Creating for:{{ project.name }}</v-card-subtitle>

      <v-card-text>

        <v-snackbar
            v-model="snackbar"
            timeout="2000"
        >
          {{ snackMessage }}

          <template v-slot:action="{ attrs }">
            <v-btn
                color="pink"
                text
                v-bind="attrs"
                @click="snackbar = false"
            >
              Close
            </v-btn>
          </template>
        </v-snackbar>

        <v-form>
          <v-text-field prepend-icon="text_fields" v-model="merch_name" label="Merchandising event name"
                        :rules="nameRules"
                        required></v-text-field>

          <v-divider></v-divider>

          <v-autocomplete
              v-model="model"
              :items="items"
              :search-input.sync="search"
              chips
              clearable
              hide-details
              hide-selected
              type="number"
              item-text="phone"
              item-value="name"
              label="Search for a user by phone number"
              return-object
              prepend-icon="phone"
          >
            <template v-slot:no-data>
              <v-list-item>
                <v-list-item-title>
                  Start typing user
                  <strong>Phone number</strong>
                </v-list-item-title>
              </v-list-item>
            </template>
            <template v-slot:selection="{ attr, on, item, selected }">
              <v-chip
                  v-bind="attr"
                  :input-value="selected"
                  color="blue-grey"
                  class="white--text"
                  v-on="on"
              >
                <v-icon left>account_circle</v-icon>

                <span v-text="item.name"></span>
              </v-chip>
            </template>
            <template v-slot:item="{ item }">
              <v-list-item-avatar
                  color="indigo"
                  class="headline font-weight-light white--text"
              >
                {{ item.name.charAt(0) }}
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title v-text="item.name"></v-list-item-title>
                <v-list-item-subtitle v-text="item.phone"></v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-action>
                <v-icon>account_circle</v-icon>
              </v-list-item-action>
            </template>
          </v-autocomplete>

          <v-divider></v-divider>

          <v-switch
              v-model="open_plan"
              label="Open plan routes"
          ></v-switch>

          <div v-if="!open_plan">
            <v-list three-line>
              <div v-if="merchLocations.length===0">Added locations we appear hear</div>

              <v-list-item v-for="(location, index) in merchLocations" :key="index">

                <v-list-item-avatar>
                  <v-icon>location_on</v-icon>
                </v-list-item-avatar>

                <v-list-item-content>
                  <v-list-item-title v-html="location.name"></v-list-item-title>
                  <v-list-item-subtitle v-html="location.start_date"></v-list-item-subtitle>
                </v-list-item-content>

                <v-list-item-icon>
                  <v-icon @click="merchLocations.splice(index)">close</v-icon>
                </v-list-item-icon>
              </v-list-item>
            </v-list>

            <v-card flat outlined>
              <v-card-title>Merchandising location</v-card-title>
              <v-card-text>
                <div class="my-4">
                  <v-autocomplete
                      v-model="locationName"
                      :items="locationSearches"
                      :search-input.sync="locations"
                      chips
                      clearable
                      hide-details
                      hide-selected
                      type="text"
                      item-text="lat"
                      item-value="name"
                      label="Search location by name"
                      return-object
                      outlined
                  >
                    <template v-slot:no-data>
                      <v-list-item>
                        <v-list-item-title>
                          Start typing
                          <strong>location name</strong>
                        </v-list-item-title>
                      </v-list-item>
                    </template>
                    <template v-slot:selection="{ attr, on, item, selected }">
                      <v-chip
                          v-bind="attr"
                          :input-value="selected"
                          color="blue-grey"
                          class="white--text"
                          v-on="on"
                      >
                        <v-icon left>location_on</v-icon>

                        <span v-text="item.name"></span>
                      </v-chip>
                    </template>
                    <template v-slot:item="{ item }">
                      <v-list-item-avatar
                          color="indigo"
                          class="headline font-weight-light white--text"
                      >
                        {{ item.name.charAt(0) }}
                      </v-list-item-avatar>
                      <v-list-item-content>
                        <v-list-item-title v-text="item.name"></v-list-item-title>
                        <v-list-item-subtitle
                            v-text="item.lat+' ,'+item.lng"></v-list-item-subtitle>
                      </v-list-item-content>
                      <v-list-item-action>
                        <v-icon>location_on</v-icon>
                      </v-list-item-action>
                    </template>
                  </v-autocomplete>
                </div>
                <v-menu
                    v-model="menu2"
                    :close-on-content-click="true"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                        v-model="date"
                        label="Starting date"
                        outlined
                        readonly
                        v-bind="attrs"
                        v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker v-model="date" @input="menu2 = false"></v-date-picker>
                </v-menu>

                <v-select v-model="frequency" label="Repeat frequency" outlined item-color="aquablue"
                          :items="['Dont repeat','Weekly', 'Daily', 'Monthly']"></v-select>

                <div class="w-full">
                  <v-btn color="primary" outlined @click="addLocation">Add location</v-btn>
                  <p class="caption mt-3">To add a new location click the locations button</p>
                </div>
              </v-card-text>
            </v-card>
          </div>
          <v-btn
              class="ma-2"
              block
              :loading="loading2"
              :disabled="loading2"
              color="success"
              @click="submitMerchandise"
          >
            Create Event
            <template v-slot:submitting>
              <span>Loading...</span>
            </template>
          </v-btn>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>

import axios from "axios";

export default {
  props: {
    project: Object
  },
  data() {
    return {
      date: new Date().toISOString().substr(0, 10),
      locationName: '',
      dialog: false,
      merch_name: '',
      merchLocations: [],
      nameRules: [
        v => !!v || 'Event name is required',
        v => (v && v.length > 4) || 'Name must be more than characters',
      ],
      model: null,
      search: null,
      locations: null,
      loader: null,
      locationSearches: [],
      items: [],
      snackbar: null,
      snackMessage: null,
      frequency: null,
      loading2: false,
      submitting: null,
      project_report: true,
      open_plan: false,
    }
  },
  methods: {
    addLocation() {

      if (!this.locationName) {
        this.snackbar = true
        this.snackMessage = 'Location cannot be empty'
        return
      }

      if (!this.frequency) {
        this.snackbar = true
        this.snackMessage = 'Select repeat frequency'
        return
      }

      let location = {
        id: this.locationName.id,
        name: this.locationName.name,
        start_date: this.date,
        frequency: this.frequency,
      }

      this.merchLocations.push(location)

      this.locationName = null
    },
    querySelections(val) {
      this.isLoading = true;


      // get event
      axios.get(`/search/users?query=${val}`)
          .then((response) => {
            this.items = response.data;
          })
          .catch((error) => {
            console.log(error);
          })
          .finally(() => (this.isLoading = false));
    },
    queryLocations(val) {
      this.isLoading = true;


      // get event
      axios.get(`/search/location?query=${val}`)
          .then((response) => {
            this.locationSearches = response.data;
          })
          .catch((error) => {
            console.log(error);
          })
          .finally(() => (this.isLoading = false));
    },

    submitMerchandise() {

      console.log(this.project.id)

      this.submitting = 'loading2'

      if (!this.open_plan && this.merchLocations.length === 0) {
        this.snackbar = true
        this.snackMessage = 'Locations cannot be empty'
        this.submitting = null
        return
      }

      let data = {
        name: this.merch_name,
        user: this.model.id,
        locations: this.merchLocations,
        project_report: this.project_report,
        project_id: this.project.id,
        open_plan: this.open_plan,
      }

      // create team
      axios.post('/merch', data)
          .then((response) => {
            if (response.status === 201) {
              this.snackMessage = "RSM created successfully. Refreshing"
              this.snackbar = true

              this.merch_name = null
              this.merchLocations = null

              this.dialog = false
              this.submitting = null
              this.loading = false;
              this.loader = null

              this.$emit('refresh', true)
            } else {
              this.snackMessage = "Unable to create event. Check data or try again later"
              this.snackbar = true
              this.submitting = null
              this.loading = false;
              this.loader = null
            }
          })
          .catch((error) => {
            console.log(error);
            this.snackMessage = 'An error occured while creating the user. This could be caused by a poor internet connection or you lack the permission to complete the action';
            this.snackbar = 'error';
            this.alert = true;
            this.loading = false;
            this.loader = null
          });
    }
  },
  watch: {

    search(val) {
      val && val !== this.select && this.querySelections(val);
    },
    loader() {
      const l = this.loader;
      this[l] = !this[l];
    },

    locations(val) {
      val && val !== this.select && this.queryLocations(val);
    },
    submitting() {
      const l = this.submitting
      this[l] = !this[l]

      //setTimeout(() => (this[l] = false), 3000)

      //this.submitting = null
    },
  },
}
</script>
