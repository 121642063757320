<template>
  <v-dialog v-model="dialog" width="600" scrollable light>
    <template v-slot:activator="{ on }">
      <v-btn v-on="on" class="body-2" color="orange" outlined>
        <v-icon>
          file_copy
        </v-icon>
        Duplicate
      </v-btn>
    </template>
    <v-card>
      <v-card-title>
        <span>Select report to duplicate</span>
        <v-spacer></v-spacer>
        <v-btn icon @click="dialog =false">
          <v-icon>
            close
          </v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-list tile max-height="800" light>
          <v-list-item
            v-for="(rep, index) in reports"
            :key="index"
            @click="duplicateReport(rep)"
            class="text-left"
          >
            <v-list-item-content>
              <v-list-item-title>{{ rep.name }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
import axios from "axios";

export default {
  data() {
    return {
      reports: [],
      dialog: false,
    };
  },
  methods: {
    loadReports() {
      axios
        .get(`/report/duplicate`)
        .then((response) => {
          this.reports = response.data.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    duplicateReport(report) {
      this.$emit("duplicateReport", report);
      this.dialog = false;
    },
  },
  mounted() {
    this.loadReports();
  },
};
</script>
