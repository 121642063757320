<template>
    <v-container>

        <!--  Career Posts Table      -->
        <v-simple-table>
            <template v-slot:default>
                <thead>
                <tr>
                    <th class="text-left">Submissions</th>
                    <th class="text-left">Title</th>
                    <th class="text-left">Skill Set</th>
                    <th class="text-left">Location</th>
                    <th class="text-left">Deadline</th>
                    <th class="text-left" width="400px">Description</th>
                    <th class="text-left">Action</th>
                </tr>
                </thead>
                <tbody>

                <!--  A single Career Post  -->
                <tr v-for="(careerPost, index) in careerPosts" :key="index">
                    <td class="text-left">
                        <v-btn text small @click="viewSubmissions">Submissions</v-btn>
                    </td>
                    <td class="text-left">{{careerPost.title}}</td>
                    <td class="text-left">{{careerPost.level || 'Not set'}}</td>
                    <td class="text-left">{{careerPost.location}}</td>
                    <td class="text-left">{{careerPost.deadline}}</td>
                    <td class="text-left">{{careerPost.description}}</td>
                    <td class="d-flex justify-space-between align-center">
                        <v-btn x-small color="blue" dark @click="onEdit(careerPost.id)">Edit</v-btn>
                        <v-btn x-small color="red" dark @click="onDelete(careerPost.id)">Delete</v-btn>
                    </td>
                </tr>
                </tbody>
            </template>
        </v-simple-table>
    </v-container>
</template>
<script>
    export default {
        name: 'CareerPosts',
        props: {
            careerPosts: {
                type: Array,
            }
        },
        methods: {
            onDelete(id) {
                this.$emit("onDelete", id);
            },
            onEdit(id) {
                this.$emit("onEdit", id);
            },
            viewSubmissions(id) {
                this.$emit("viewSubmissions", id);
            }
        }
    }
</script>
