<template>
  <v-main>
    <v-sheet color="#FAFAFA" height="100vh">
      <v-img :src="require('@/assets/ic_logo_blue.svg')" class="ml-5" max-height="100px"
             max-width="100px"></v-img>
      <v-row no-gutters>
        <v-col cols="12" md="6">
          <v-card max-width="600" outlined flat class="mx-auto mt-12 pa-2">
            <div>
              <v-alert :value="alert" :type="alertType" dark transition="scale-transition">
                {{ alertMessage }}
              </v-alert>
            </div>
            <div>
              <div class="title" style="color: #0264E4">Sign in to Hokela</div>
              <div class="caption ml-8">Enter your email and password</div>
            </div>
            <v-divider></v-divider>
            <v-card-text>
              <v-form
                  class="pa-2"
                  ref="form"
                  v-model="valid"
                  lazy-validation
              >
                <v-text-field
                    v-model="email"
                    :rules="emailRules"
                    label="E-mail"
                    autofocus
                    required
                    outlined
                    class="mt-4"
                ></v-text-field>
                <v-text-field
                    class="mt-4"
                    v-model="password"
                    :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                    :rules="[rules.required, rules.min]"
                    :type="show ? 'text' : 'password'"
                    label="Enter your Hokela password"
                    hint="At least 8 characters"
                    counter
                    outlined
                    @click:append="show = !show"
                ></v-text-field>
                <v-btn :loading="loading" large width="100%" dark color="#0264E4" class="mr-4 body-2"
                       @click="login">
                  login
                </v-btn>
              </v-form>
            </v-card-text>
            <v-btn text link color="#0264E4" class="caption mt-3 lowercase" @click="resetPassword">Reset password
            </v-btn>
          </v-card>
        </v-col>
        <v-col cols="12" md="6">
          <v-card flat color="transparent">
            <v-card-text>
              <v-img :src="require('@/assets/login.png')" aspect-ratio="1.2" contain></v-img>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

      <!--Register dialog-->
      <v-dialog v-model="dialog" persistent max-width="600px">
        <v-card>
          <v-card-title>
            <span class="headline">Complete account registration</span>
          </v-card-title>
          <v-card-text>
            <v-row>
              <v-col cols="12">
                <v-text-field v-model="name" label="User name" required
                              disabled outlined></v-text-field>
              </v-col>

              <v-col cols="12">
                <v-text-field v-model="email" label="User email" required disabled outlined></v-text-field>
              </v-col>

              <v-col cols="12">
                <v-text-field v-model="account_type" label="Account type" required
                              disabled outlined></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field v-model="reg_pass" label="Set password" type="password"
                              :rules="v => (v && v.length >= 8) || 'Min 8 characters'"
                              required outlined></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field v-model="reg_pass_conf" label="Confirm password" type="password"
                              :rules="v => (v && v.length >= 8) || 'Min 8 characters'"
                              required outlined></v-text-field>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="dialog = false">Close</v-btn>
            <v-btn color="blue darken-1" text @click="handleSubmit()">Save</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-footer :padless="true">
        <v-card flat tile dark color="black" width="100%">
          <v-card-text></v-card-text>
          <v-card-text class="white--text">
            © 2019 - {{ new Date().getFullYear() }} — <strong>Hokela Interactive Africa</strong> All rights
            reserved
          </v-card-text>
        </v-card>
      </v-footer>
    </v-sheet>
  </v-main>
</template>
<script>
import axios from 'axios';
import {mapActions} from 'vuex'

export default {
  data() {
    return {
      loading: false,
      show: false,
      valid: true,
      username: '',
      password: '',
      dialog: false,
      name: '',
      email: '',
      emailRules: [
        v => !!v || 'E-mail is required',
        v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
      ],
      overlay: false,
      alert: false,
      alertMessage: '',
      alertType: 'success',
      account_type: '',
      reg_pass: '',
      reg_pass_conf: '',
      rules: {
        required: value => !!value || 'Required.',
        min: v => v.length >= 8 || 'Min 8 characters',
      },
    };
  },
  computed: {
    loggedIn() {
      return this.$store.getters.loggedIn;
    },
  },
  methods: {
    ...mapActions({
      signIn: "auth/signIn",
    }),

    resetPassword() {
      this.$router.push({name: 'passwordReset'});
    },
    login() {
      this.loading = true;

      this.signIn({
        username: this.email,
        password: this.password,
      }).then(() => {
        this.loading = false

        this.$router.push({name: 'dashboard'}).catch((error)=>{
          console.log(error)
        });

      }).catch((error) => {
        this.alertMessage = 'Login error check email and password and try again';
        this.alertType = 'error';
        this.alert = true
        this.loading = false
      })
    },
    getUser() {
      axios.get(`/registration/${this.$route.query.token}`)
          .then(response => {
            this.overlay = false;
            this.email = response.data.data.email;
            this.name = response.data.data.name;

            switch (response.data.data.account_type) {
              case 0:
                this.account_type = 'Super Admin';
                break;
              case 1:
                this.account_type = 'Admin Assistant';
                break;
              case 2:
                this.account_type = 'Client Account';
                break;
              case 3:
                this.account_type = 'Project Manager';
                break;
              case 4:
                this.account_type = 'Assistant Project Manager';
                break;
              case 5:
                this.account_type = 'Client Assistant';
                break;
            }
          })
          .catch(error => {
            this.overlay = false;
            this.dialog = false;
            this.alertMessage = 'Invalid token provided';
            this.alertType = 'error';
            this.alert = true;
          });
    },
    handleSubmit() {
      this.loading = true;
      let data = {
        'password': this.reg_pass,
        'password_confirmation': this.reg_pass_conf,
        'token': this.$route.query.token
      };
      axios.post('/registration', data)
          .then(response => {
            this.overlay = false;
            if (response.status === 201) {

              this.dialog = false;
              this.alertMessage = 'Registration successful. Login with your email and password';
              this.alertType = 'success';
              this.alert = true;
            }
          })
          .catch(error => {
            this.loading = false;
            this.dialog = false;
            this.alertMessage = 'Something went wrong with your registration. Try again later';
            this.alertType = 'error';
          });
    },
  },
  mounted() {
    if (this.$route.query.token && this.$route.query.token.length > 0) {
      this.dialog = true;
      this.overlay = true;
      this.getUser();
    }
  },
  watch: {
    alert() {
      if (this.alert === true) {
        setTimeout(() => (this.alert = false), 3000)
      }
    },
  },
};
</script>
