<template>
  <VChart :option="option" :style="{ height: '400px' }" />
</template>

<script>
import { use } from "echarts/core";
import { CanvasRenderer } from "echarts/renderers";
import { LineChart } from "echarts/charts";
import {
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  GridComponent,
} from "echarts/components";
import VChart, { THEME_KEY } from "vue-echarts";

use([
  CanvasRenderer,
  LineChart,
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  GridComponent,
]);

export default {
  components: {
    VChart,
  },
  props: {
    labels: Array,
    charts: Array,
    title: String,
    seriesName: String,
  },
  provide: {
    [THEME_KEY]: "light",
  },
  data() {
    return {
      option: {},
    };
    /*
    return {
      options: {
        responsive: true,
        maintainAspectRatio: false,
        legend: false,
      },
      chartData: {
        labels: this.labels,
        datasets: [{
          label: this.title,
          backgroundColor: '#f87979',
          data: this.charts
        }]
      },
    }
    */
  },
  methods: {
    downloadChart() {
      const canvas = this.$refs.canvas;
      const image = canvas.toDataURL("image/jpeg", 1.0);
      let link = document.createElement("a");
      link.download = this.title;
      link.href = image;
      link.click();
    },
    loadMap() {
      /*
      this.renderChart(this.chartData, this.options);
      */
    },
    setOption() {
      this.option = {
        title: { text: this.title },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
        },
        scales: {
          yAxes: [
            {
              ticks: {
                beginAtZero: true,
              },
              gridLines: {
                display: true,
              },
            },
          ],
          xAxes: [
            {
              gridLines: {
                display: false,
              },
            },
          ],
        },
        legend: {
          display: true,
        },
        responsive: true,
        maintainAspectRatio: false,
        xAxis: {
          type: "category",
          data: this.labels,
          //boundaryGap: [0, 0.01],
        },
        yAxis: {
          type: "value",
        },
        series: [
          {
            data: this.charts,
            type: "line",
            name: this.seriesName
          },
        ],
      };
    },
  },
  mounted() {
    this.loadMap();
    this.setOption();
  },
  watch: {
    charts() {
      this.setOption();
    },
  },
};
</script>
