<template>
  <div>
    <v-overlay :value="loading">
      <v-progress-circular
        color="red"
        indeterminate
        size="64"
      ></v-progress-circular>
    </v-overlay>

    <v-card
      outlined
      min-height="100vh"
      height="100%"
      color="grey lighten-5"
      class="overflow_static"
    >
      <v-card-title class="body-2">Sales Analysis</v-card-title>
      <v-divider></v-divider>
      <v-card-text class="overflow_card">
        <v-row>
          <v-col cols="12" sm="3">
            <v-card flat dark color="primary">
              <v-card-title class="body-1 mt-2">
                <v-icon>storefront</v-icon>
                <span class="ml-2">Outlets</span>
              </v-card-title>
              <v-divider></v-divider>
              <v-card-text>
                <div class="title white--text">
                  {{ sales.outlet.numberOfOutletsToday }}
                </div>
                <div class="caption mt-3">Total</div>
                <div class="body-2">{{ sales.outlet.numberOfOutlets }}</div>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12" sm="3">
            <v-card flat dark color="red">
              <v-card-title class="body-1 mt-2">
                <v-icon>group</v-icon>
                <span class="ml-2">Agents</span>
              </v-card-title>
              <v-divider></v-divider>
              <v-card-text>
                <div class="title white--text">
                  {{ sales.overview.agents.today }}
                </div>
                <div class="caption mt-3">Assigned</div>
                <div class="body-2">{{ sales.overview.agents.total }}</div>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12" sm="3">
            <v-card flat dark color="green">
              <v-card-title class="body-1 mt-2">
                <v-icon>plus_one</v-icon>
                <span class="ml-2">Conversions</span>
              </v-card-title>
              <v-divider></v-divider>
              <v-card-text>
                <div class="title white--text">
                  {{ sales.overview.conversions.today }}
                </div>
                <div class="caption mt-3">Out of</div>
                <div class="body-2 white--text">
                  {{ sales.overview.conversions.total }}
                </div>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12" sm="3">
            <v-card flat dark color="black">
              <v-card-title class="body-1 mt-2">
                <v-icon>adjust</v-icon>
                <span class="ml-2">Sales Targets</span>
              </v-card-title>
              <v-divider></v-divider>
              <v-card-text>
                <div class="title white--text">
                  {{ sales.overview.targets.today }}
                </div>
                <div class="caption mt-3">Set Targets</div>
                <div class="body-2 white--text">
                  {{ sales.overview.targets.total }}
                </div>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>

        <v-card flat class="my-3">
          <v-card-title class="body-1">Outlets</v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <v-sheet>
              <v-row justify="space-between" class="mt-5 px-4">
                <v-card>
                  <v-card-title class="body-2">Outlets visited</v-card-title>
                  <v-card-text class="title">{{
                    sales.outlet.visited
                  }}</v-card-text>
                </v-card>

                <v-card>
                  <v-card-title class="body-2">Routes visited</v-card-title>
                  <v-card-text class="title">{{
                    sales.outlet.routes
                  }}</v-card-text>
                </v-card>

                <v-card>
                  <v-card-title class="body-2">Sessions started</v-card-title>
                  <v-card-text class="title">{{
                    sales.outlet.sessions
                  }}</v-card-text>
                </v-card>

                <v-card class="">
                  <v-card-title class="body-2"
                    >Avg Outlets per Agent</v-card-title
                  >
                  <v-card-text class="title">{{
                    sales.outlet.outletsPerAgent
                  }}</v-card-text>
                </v-card>
              </v-row>
            </v-sheet>
            <v-card class="mt-10">
              <v-card-title class="body-2">Conversions</v-card-title>
              <v-card-text>
                <line-chart
                  class="bar_chart"
                  seriesName="Conversions"
                  :charts="sales.outlet.graph.values"
                  :labels="sales.outlet.graph.labels"
                />
              </v-card-text>
            </v-card>

            <!-- <v-card class="mt-2">
            <v-card-title class="body-2">Top activation images</v-card-title>
            <v-card-text>
              <v-row>
                <template v-for="(item, n) in sales.outlet.images">
                  <v-col :key="n">
                    <v-img
                      contain
                      transition="fade-transition"
                      :src="item"
                      height="100"
                      width="100"
                    ></v-img
                  ></v-col>
                </template>
              </v-row>
            </v-card-text>
          </v-card> -->

            <v-sheet v-if="hasOutletData" class="mt-2">
              <v-row>
                <v-col cols="12" sm="6">
                  <v-card>
                    <v-card-title class="my-5 body-2"
                      >Outlets by Agents</v-card-title
                    >
                    <v-simple-table
                      :style="{
                        maxHeight: '240px',
                        overflow: 'auto',
                      }"
                    >
                      <template v-slot:default>
                        <thead>
                          <tr>
                            <th class="text-left">User</th>
                            <th class="text-left">Outlet</th>
                            <th class="text-left">Time</th>
                          </tr>
                        </thead>
                        <template v-if="sales.outlet.outletData">
                          <tbody>
                            <template
                              v-for="(outlet, index) in Object.keys(
                                sales.outlet.outletData
                              ).reverse()"
                            >
                              <tr
                                v-for="(submission, n) in sales.outlet
                                  .outletData[outlet]"
                                :key="`${n} ${index}`"
                              >
                                <td>{{ submission.user }}</td>
                                <td>{{ outlet }}</td>
                                <td>{{ submission.createdAt }}</td>
                              </tr>
                            </template>
                          </tbody>
                        </template>
                      </template>
                    </v-simple-table>
                  </v-card>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-card>
                    <v-card-title class="my-5 body-2"
                      >Outlets by Sales</v-card-title
                    >
                    <v-simple-table
                      :style="{
                        maxHeight: '240px',
                        overflow: 'auto',
                      }"
                    >
                      <template v-slot:default>
                        <thead>
                          <tr>
                            <th class="text-left">Outlet</th>
                            <th class="text-left">Sales</th>
                            <!-- <th class="text-left">Time</th> -->
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            v-for="(submissions, outlet, k) in sales.outlet
                              .outletData"
                            :key="k"
                          >
                            <td>{{ outlet }}</td>
                            <td>{{ submissions.length }}</td>
                            <!-- <td>{{ item.time }}</td> -->
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                  </v-card>
                </v-col>
              </v-row>
            </v-sheet>

            <v-card v-if="hasOutletData" class="mt-10">
              <v-card-title class="body-2">Outlets by Sales</v-card-title>
              <v-card-text>
                <bar-chart
                  class="bar_chart"
                  seriesName="Sales"
                  :axisLabelRotate="-15"
                  :charts="
                    Object.values(sales.outlet.outletData).map(
                      (item) => item.length
                    )
                  "
                  :labels="Object.keys(sales.outlet.outletData)"
                />

                <!-- <pie-chart
                  class="bar_chart"
                  seriesName="Sales"
                  :showLegend="false"
                  :pie_data="{
                    labels: Object.keys(sales.outlet.outletData),
                    values: Object.values(sales.outlet.outletData).map(
                      (item) => item.length
                    ),
                  }"
                  :axisLabelRotate="-15"
                  :charts="
                    Object.values(sales.outlet.outletData).map(
                      (item) => item.length
                    )
                  "
                  :labels="Object.keys(sales.outlet.outletData)"
                /> -->
              </v-card-text>
            </v-card>
          </v-card-text>
        </v-card>

        <!-- 
      <v-card flat class="my-3">
        <v-card-title class="body-1">Targets</v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-card class="mt-5">
            <v-card-title class="body-2"
              >WeeklyTarget achievement chart</v-card-title
            >
            <v-card-text>
              <line-chart
                class="bar_chart"
                :charts="sales.targets.graph.values"
                :labels="sales.targets.graph.labels"
              />

              <span class="caption"
                >Calculations averaged by the number of agents on ground per
                day</span
              >
            </v-card-text>
          </v-card>

          <v-sheet class="mt-2">
            <v-row>
              <v-col cols="12" sm="6">
                <v-card class="align-start text-left">
                  <v-card-title>
                    <span class="body-2 ml-3">Today's target perfomance</span>
                  </v-card-title>
                  <v-divider></v-divider>
                  <v-card-text>
                    <pie-chart
                      :title="'Testing'"
                      :pie_data="sales.targets.today"
                    />

                    <span class="caption"
                      >Comparison today, yesterday, and a week earlier</span
                    >
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col cols="12" sm="6">
                <v-card class="align-start text-left">
                  <v-card-title>
                    <span class="body-2 ml-3">SKU to target contribution</span>
                  </v-card-title>
                  <v-divider></v-divider>
                  <v-card-text>
                    <pie-chart
                      :title="'Testing'"
                      :pie_data="sales.targets.sku"
                    />
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-sheet>
        </v-card-text>
      </v-card> -->

        <v-card flat class="my-3">
          <v-card-title class="body-1">Agents</v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <v-card class="mt-5">
              <v-card-title class="body-2">Top Agents</v-card-title>
              <v-card-text>
                <!-- <bar-chart
                class="bar_chart"
                :charts="sales.agents.graph.charts"
                :labels="sales.agents.graph.labels"
              /> -->
                <horizontal-bar
                  class="bar_chart"
                  seriesName="Sales"
                  :axisLabelsRotate="0"
                  :charts="
                    sales.agents.agentsBySales
                      .slice(0, 5)
                      .map((item) => item.sales)
                  "
                  :labels="
                    sales.agents.agentsBySales
                      .slice(0, 5)
                      .map((item) => item.user)
                  "
                />
              </v-card-text>
            </v-card>
            <v-sheet class="mt-2">
              <v-row>
                <v-col v-if="hasOutletData" cols="12" sm="6">
                  <v-card>
                    <v-card-title class="my-5 body-2"
                      >Agents by outlets</v-card-title
                    >
                    <v-simple-table
                      :style="{
                        maxHeight: '240px',
                        overflow: 'auto',
                      }"
                    >
                      <template v-slot:default>
                        <thead>
                          <tr>
                            <th class="text-left">Agent</th>
                            <th class="text-left">Outlets</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            v-for="(item, n) in sales.outlet.agentsByOutlets"
                            :key="n"
                          >
                            <td>{{ item.user }}</td>
                            <td>{{ item.outlets.join(", ") }}</td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                  </v-card>
                </v-col>
                <v-col cols="12" :sm="hasOutletData ? 6 : 12">
                  <v-card>
                    <v-card-title class="my-5 body-2"
                      >Agents by Sales</v-card-title
                    >
                    <v-simple-table
                      :style="{
                        maxHeight: '240px',
                        overflow: 'auto',
                      }"
                    >
                      <template v-slot:default>
                        <thead>
                          <tr>
                            <th class="text-left">Agent</th>
                            <th class="text-left">Sales</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            v-for="(item, n) in sales.agents.agentsBySales"
                            :key="n"
                          >
                            <td>{{ item.user }}</td>
                            <td>{{ item.sales }}</td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                  </v-card>
                </v-col>
              </v-row>
            </v-sheet>
          </v-card-text>
        </v-card>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import BarChart from "../../utils/chart/BarChart.vue";
import HorizontalBar from "../../utils/chart/HorizontalBar.vue";
import LineChart from "../../utils/chart/LineChart.vue";
import PieChart from "../../utils/chart/PieChart.vue";
import DataTableList from "../../utils/DataTableList.vue";
import ApiService from "@/services/ApiService";

export default {
  components: { BarChart, PieChart, DataTableList, LineChart, HorizontalBar },
  data() {
    return {
      sales: {
        overview: {
          outlets: {
            total: 0,
            today: 0,
          },
          agents: {
            total: 0,
            today: 0,
          },
          conversions: {
            total: 0,
            today: 0,
          },
          targets: {
            total: 0,
            today: 0,
          },
        },
        outlet: {
          visited: 0,
          routes: 0,
          sessions: 0,
          outletsPerAgent: 0,
          outletData: null,
          graph: {
            labels: [],
            charts: [],
          },
          images: [],
          outletsByAgents: [],
          outletsBySales: [],
          agentsBySales: [],
          numberOfOutlets: 0,
          numberOfOutletsToday: 0,
        },
        targets: {
          graph: {
            labels: [],
            charts: [],
          },

          today: {
            labels: [],
            values: [],
          },
          sku: {
            labels: [],
            values: [],
          },
        },
        agents: {
          graph: {
            labels: [],
            charts: [],
          },
          agentsByOutlets: [],
          agentsBySales: [],
        },
      },
      loading: true,
    };
  },
  computed: {
    hasOutletData() {
      return (
        this.sales.outlet.outletData &&
        Object.keys(this.sales.outlet.outletData).length > 0
      );
    },
  },
  methods: {
    getSales() {
      ApiService.getSales(this.$route.params.id)
        .then((response) => {
          this.sales = response.data.data;
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
          this.loading = false;
        });
    },
  },
  mounted() {
    this.getSales();
  },
};
</script>
