<template>
  <v-container>
    <v-data-table
      :search="search"
      height="90vh"
      title="Some title here"
      divider="true"
      :headers="headers"
      :items="values"
      :items-per-page="response.per_page"
      :loading="loading"
      :options.sync="options"
      :server-items-length="response.total"
      loading-text="Loading report... Please wait"
      dense
    >

    </v-data-table>

    <v-snackbar v-model="snack" absolute timeout="2500" centered>
      {{ snackMessage }}
    </v-snackbar>
  </v-container>
</template>

<script>
import ApiService from "@/services/ApiService";
export default {
  data() {
    return {
      search: null,
      response: {
        total: 0,
        per_page: 0,
        headers: [],
        values: [],
      },
      loading: true,
      options: {},

    };
  },

  computed: {
    headers() {
      return this.response != null ? this.response.headers : [];
    },

    values() {
      return this.response != null ? this.response.values : [];
    },
  },
  watch: {
    options: {
      handler() {
        console.log(this.options);
        this.fetchTableData(this.options.page);
      },
      deep: true,
    },
  },
  mounted() {
    this.fetchTableData(1);
  },
  methods: {
    fetchTableData(page) {
      this.loading = true;
      ApiService.fetchTableData(this.$route.params.id, page)
        .then((response) => {
          this.response = response.data.data;
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
          this.loading = false;
        });
    },

  },
};
</script>
