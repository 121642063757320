<template>
    <v-container>

        <!--  Snackbar  -->
        <template>
            <div class="text-center">
                <v-snackbar v-model="snackbar" :timeout="2000">
                    {{snackMessage}}
                    <template v-slot:action="{ attrs }">
                        <v-btn color="blue" text v-bind="attrs" @click="snackbar = false">Close</v-btn>
                    </template>
                </v-snackbar>
            </div>
        </template>

        <!--  Career Post Creator   -->
        <v-row justify="center">
            <v-dialog v-model="dialog" persistent max-width="600px">
                <template v-slot:activator="{ on, attrs }">
                    <v-btn
                            color="primary"
                            dark
                            v-bind="attrs"
                            v-on="on"
                    >
                        Add Career
                    </v-btn>
                </template>
                <v-card>
                    <v-card-title>
                        <span class="headline ml-auto">Add Career</span>
                    </v-card-title>
                    <v-divider></v-divider>
                    <v-card-text>
                        <v-container>
                            <v-row>
                                <v-col cols="12" sm="6" md="6">
                                    <v-text-field
                                            label="Title"
                                            v-model="title"
                                            required
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="6" md="6">
                                    <v-text-field
                                            label="Skill Set"
                                            v-model="skillSet"
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="6" md="6">
                                    <v-text-field
                                            label="Location"
                                            v-model="location"
                                            required
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="6" md="6">
                                    <v-text-field
                                            label="Deadline"
                                            type="date"
                                            v-model="deadline"
                                            required
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12">
                                    <v-textarea
                                            label="Role Description"
                                            v-model="description"
                                            filled
                                            required
                                    ></v-textarea>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="blue darken-1" text @click="reset">Reset</v-btn>
                        <v-btn color="blue darken-1" text @click="dialog = false">Close</v-btn>
                        <v-btn color="blue darken-1" text v-if="isChanged" @click="updatePost">Update</v-btn>
                        <v-btn color="success" small v-else @click="savePost">Save</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-row>
        <div v-if="loading" class="grey" max-height="300px" align="center">Loading</div>

        <!--  All Active career posts Table   -->
        <CareerPosts :careerPosts="careerPosts" @viewSubmissions="viewSubmissions($event)" @onDelete="onDelete($event)" @onEdit="onEdit($event)"/>
    </v-container>
</template>
<script>
    import CareerPosts from "./CareerPosts";
    import axios from 'axios';
    export default {
        name: 'CareerPostsTab',
        mounted() {
            this.getCareerPosts()
        },
        components: {CareerPosts},
        data: () => ({
            careerPosts: [],
            dialog: false,
            title: null,
            skillSet: null,
            location: null,
            deadline: null,
            description: null,
            loading: false,
            snackbar: false,
            snackMessage: null,
            isChanged: false,
            editedPost: null,
        }),
        methods: {

            // Gets the active career posts from the server
            getCareerPosts() {
                this.loading = true;
                axios.get("/careers/")
                    .then(response => {
                        this.careerPosts = response.data.data;
                        this.loading = false;
                    })
                    .catch(error => {
                        console.log(error);
                    });
            },

            // Saves a newly created career post
            savePost() {
                axios.post("/careers", {
                    title: this.title,
                    skillSet: this.skillSet,
                    location: this.location,
                    deadline: this.deadline,
                    description: this.description,
                }).then(response => {
                    if (response.status === 201) {
                        this.getCareerPosts();
                        this.dialog = false;
                        this.snackbar = true;
                        this.snackMessage =
                            "New Career posted successfully.";
                        this.title = null;
                        this.skillSet = null;
                        this.location = null;
                        this.deadline = null;
                        this.description = null;
                    } else {
                        this.getCareerPosts();
                        this.dialog = true;
                        this.loading = false;
                        this.snackbar = true;
                        this.snackMessage =
                            "New Career NOT posted. Check your internet or try again later.";
                    }
                })
            },

            // Edits the selected career post
            onEdit(id) {
                this.dialog = true;
                this.careerPosts.forEach((post) => {
                    if (post.id === id) {
                        this.title = post.title;
                        this.skillSet = post.skillSet;
                        this.location = post.location;
                        this.deadline = post.deadline;
                        this.description = post.description;
                        this.isChanged = true;
                        this.editedPost = post;
                    }
                })
            },

            // Updates the edited career post
            updatePost() {
                axios.put(`/careers/${this.editedPost.id}`, {
                    title: this.title,
                    skillSet: this.skillSet,
                    location: this.location,
                    deadline: this.deadline,
                    description: this.description,
                }).then(response => {
                    if (response.status === 200) {
                        this.getCareerPosts();
                        this.dialog = false;
                        this.snackbar = true;
                        this.snackMessage =
                            "New Career updated successfully.";
                        this.title = null;
                        this.skillSet = null;
                        this.location = null;
                        this.deadline = null;
                        this.description = null;
                        this.isChanged = false;
                    } else {
                        this.getCareerPosts();
                        this.dialog = true;
                        this.loading = false;
                        this.snackbar = true;
                        this.snackMessage =
                            "New Career NOT updated. Check your internet or try again later.";
                    }
                })
            },

            // Deletes the selected career post
            onDelete(id) {
                axios.delete(`/careers/${id}`)
                    .then(response => {
                        if (response.status === 200) {
                            this.snackbar = true;
                            this.snackMessage =
                                `Career post id:${id} deleted.`;
                            this.getCareerPosts();
                            this.title = null;
                            this.skillSet = null;
                            this.location = null;
                            this.deadline = null;
                            this.description = null;
                        } else {
                            this.snackbar = true;
                            this.snackMessage =
                                `Career post id:${id} NOT deleted. Check your internet or try again later.`;
                            this.getCareerPosts();
                        }
                    })
                    .catch(error => console.log(error));
            },

            // Resets input values
            reset() {
                this.title = null;
                this.skillSet = null;
                this.location = null;
                this.deadline = null;
                this.description = null;
                this.isChanged = false;
            },

            // TODO
            // Will display submissions for each post when clicked on
            viewSubmissions(){
                window.console.log("View Submissions for this post")
            }
        }
    }
</script>
