<template>
  <div>
    <v-overlay absolute :value="overlay">
      <v-progress-circular
        color="red"
        indeterminate
        size="64"
      ></v-progress-circular>
    </v-overlay>

    <!-- Search Hokela -->
    <div class="d-flex flex-grow-1 mx-3">
      <v-text-field
        class="ml-2"
        flat
        solo
        light
        single-line
        clearable
        background-color="grey lighten-3"
        label="Search Hokela"
        v-model="searchSystem"
      />
      <v-btn-toggle
        color="primary"
        tile
        v-model="searchOption"
        center-active
      >
        <v-btn
          v-for="(option, key) in searchOptions"
          v-text="option.title"
          label
          :key="key"
          :value="option.value"
          @click="$router.push(option.linkObject)"
        ></v-btn>
      </v-btn-toggle>
    </div>

    <!-- Analysis area-->
    <v-row class="mx-3">
      <v-col cols="12">
        <v-card flat>
          <v-card-title class="body-1">System Breakdown</v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <v-row justify="space-around">
              <v-col cols="12" md="6">
                <v-card outlined class="align-start text-left">
                  <v-card-title>
                    <v-icon color="blue">folder</v-icon>
                    <span class="body-2 ml-3">Campaigns breakdown</span>
                  </v-card-title>
                  <v-divider></v-divider>
                  <v-card-text>
                    <pie-chart
                      v-if="resp != null"
                      :title="''"
                      :pie_data="resp.eventData"
                    />
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col cols="12" md="6">
                <v-card outlined class="align-start text-left">
                  <v-card-title>
                    <v-icon color="green">description</v-icon>
                    <span class="body-2 ml-3">Reports breakdown</span>
                  </v-card-title>
                  <v-divider></v-divider>
                  <v-card-text>
                    <pie-chart
                      v-if="resp != null"
                      :title="''"
                      :pie_data="resp.reportData"
                    />
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" md="6">
        <v-card flat>
          <v-card-title class="body-2">
            <div>Recent created campaigns</div>
            <v-spacer></v-spacer>
            <v-btn
              link
              text
              class="caption blue--text text-capitalize"
              @click="openProject"
              >All Projects</v-btn
            >
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <v-data-table
              v-if="resp != null"
              class="caption"
              :headers="resp.recentEventData.headers"
              :items="resp.recentEventData.values"
              :items-per-page="5"
              dense
            >
              <template v-slot:item.name="{ item }" class="text-left">
                <v-btn
                  text
                  link
                  class="text-left text-capitalize"
                  small
                  @click="viewCampaign(item.id)"
                >
                  {{ item.name }}
                </v-btn>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" md="6">
        <v-card flat>
          <v-card-title class="body-1">Top Campaigns by Checkins</v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <horizontal-bar
              v-if="resp != null"
              :charts="resp.topEventsData.values"
              :labels="resp.topEventsData.labels"
            />
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" md="6">
        <v-card flat>
          <v-card-title>
            <v-icon color="orange"> save_alt </v-icon>
            <span class="body-2 ml-2"> Recent Reports Created </span>
            <v-spacer></v-spacer>
            <v-btn
              link
              text
              class="caption blue--text text-capitalize"
              @click="openReports"
              >All Reports</v-btn
            >
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <v-data-table
              v-if="resp != null"
              class="caption"
              single-expand
              :options="{}"
              :headers="resp.recentReportData.headers"
              :items="resp.recentReportData.values"
              :items-per-page="5"
              dense
            >
              <template v-slot:item.name="{ item }" class="text-left">
                <v-btn
                  text
                  link
                  class="text-left text-capitalize"
                  small
                  @click="openReport(item.id)"
                >
                  {{ item.name }}
                </v-btn>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" md="6">
        <v-card flat>
          <v-card-title class="body-2"
            >Top Reports by responses today</v-card-title
          >
          <v-divider></v-divider>
          <v-card-text>
            <horizontal-bar
              v-if="resp != null"
              :charts="resp.topReportsData.values"
              :labels="resp.topReportsData.labels"
              :color="'red'"
            />
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import HorizontalBar from "@/components/utils/chart/HorizontalBar";
import PieChart from "@/components/utils/chart/PieChart";
import ApiService from "@/services/ApiService";

export default {
  components: { PieChart, HorizontalBar },
  props: {},
  data() {
    return {
      resp: null,
      searchSystem: null,
      searchOption: null,
      overlay: true,
      searchOptions: [
        {
          title: "Projects",
          value: "projects",
          linkObject: { name: "dashboard_projects" },
        },
        {
          title: "Reports",
          value: "reports",
          linkObject: { name: "dashboard_reports" },
        },
        {
          title: "Users",
          value: "users",
          linkObject: { name: "users" },
        },
        {
          title: "Accounts",
          value: "accounts",
          linkObject: { name: "dashboard_accounts" },
        },
      ],
    };
  },
  mounted() {
    this.getDashData();
  },
  methods: {
    getDashData() {
      ApiService.getDashData()
        .then((response) => {
          this.resp = response.data.data;
          this.overlay = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    openProject() {
      this.$router.push({ name: "dashboard_projects" });
    },
    openReports() {
      this.$router.push({ name: "dashboard_reports" });
    },
    openReport(id) {
      this.$router.push({ name: "report", params: { id: id } });
    },
    viewCampaign(id) {
      this.$router.push({ name: "event", params: { id: id } });
    },
  },
};
</script>
