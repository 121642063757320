<template>
  <!-- <chart
    :options="pie"
    :init-options="initOptions"
    ref="pie"
    theme="ovilia-green"
    autoresize
  /> -->

  <VChart :option="option" :style="{ height: '400px' }" />
</template>

<script>
import { use } from "echarts/core";
import { CanvasRenderer } from "echarts/renderers";
import { PieChart } from "echarts/charts";
import {
  TitleComponent,
  TooltipComponent,
  LegendComponent,
} from "echarts/components";
import VChart, { THEME_KEY } from "vue-echarts";
//import ECharts from 'vue-echarts'
//import  'echarts/lib/chart/pie'
//import 'echarts/lib/component/legend'
//import 'echarts/lib/component/tooltip'

use([
  CanvasRenderer,
  PieChart,
  TitleComponent,
  TooltipComponent,
  LegendComponent,
]);

export default {
  props: {
    pie_data: Object,
    title: String,
    seriesName: {
      type: String,
      default: "Source",
    },
    showLegend: {
      type: Boolean,
      default: true,
    },
  },
  components: {
    VChart,
  },
  provide: {
    [THEME_KEY]: "light",
  },
  /*
  data() {
    return {
      initOptions: {
        renderer: "canvas",
      },
      pie: {
        tooltip: {
          trigger: "item",
          formatter: "{a} <br/>{b} : {c} ({d}%)",
        },
        radius: 50,
        title: {
          text: this.title,
          x: "center",
        },
        legend: {
          orient: "vertical",
          left: "left",
          data: this.pie_data.labels,
        },
        calculable: true,
        series: [
          {
            name: "",
            type: "pie",
            radius: "60%",
            center: ["50%", "60%"],
            data: this.parseData(),
            itemStyle: {
              normal: {
                label: {
                  show: true,
                  position: "inner",
                  formatter: function (params) {
                    return params.value + "%\n";
                  },
                },
                labelLine: {
                  show: false,
                },
              },
              emphasis: {
                label: {
                  show: true,
                  position: "center",
                  textStyle: {
                    fontSize: "30",
                    fontWeight: "bold",
                  },
                },
              },
            },
          },
        ],
      },
    };
  },
  */
  data() {
    return {};
  },
  computed: {
    option() {
      return {
        title: {
          text: this.title,
          left: "center",
        },
        label: {
          show: false,
        },
        tooltip: {
          trigger: "item",
          formatter: "{a} <br/>{b} : {c} ({d}%)",
        },

        legend: this.showLegend
          ? {
              orient: "vertical",
              left: "left",
              data: this.pie_data.labels,
            }
          : null,
        series: [
          {
            name: this.seriesName,
            type: "pie",
            radius: "55%",
            center: ["50%", "60%"],
            data: this.parseData(),
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: "rgba(0, 0, 0, 0.5)",
              },
            },
          },
        ],
      };
    },
  },
  methods: {
    downloadChart() {
      const canvas = this.$refs.pie;
      console.log(canvas);
      const image = canvas.getDataURL("image/jpeg", 1.0);
      let link = document.createElement("a");
      link.download = this.title;
      link.href = image;
      link.click();
    },
    parseData() {
      let data = [];
      this.pie_data.labels.forEach((label, index) => {
        data.push({
          value: this.pie_data.values[index],
          name: label,
        });
      });
      return data;
    },
  },
};
</script>
