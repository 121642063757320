<template>
  <v-card flat>
    <v-card-text>
      <v-text-field
          v-model="search"
          flat
          light
          solo
          background-color="grey lighten-3"
          color="black"
          label="Search Campaigns"
          prepend-inner-icon="search"
          single-line
          hide-details
      ></v-text-field>

      <v-data-table
          :search="search"
          :headers="headers"
          :items="values"
          :items-per-page="20"
          :loading="loading"
          loading-text="Loading... Please wait"
      >
        <template v-slot:item.name="{ item }" class="text-left">
          <v-btn text link class="text-left text-capitalize capitalize" @click="viewEvent(item.id)">
            {{ item.name }}
          </v-btn>
        </template>
      </v-data-table>

    </v-card-text>
  </v-card>
</template>
<script>
import axios from "axios";
import ApiService from "@/services/ApiService";
import {mapGetters} from "vuex";

export default {
  data() {
    return {
      loading: true,
      search: null,
      campaigns: null,
      dialog: false,
    }
  },

  mounted() {
    switch (this.$route.name) {
      case 'project_events':
        this.getEventsByProject()
        break;
      case 'account_campaigns':
      case 'client_campaigns':
        this.getCampaignsByClient()
        break;
      default:
        this.getEvents()
    }
  },
  computed: {
    ...mapGetters({
      user: 'auth/user'
    }),
    headers() {
      return this.campaigns ? this.campaigns.headers : []
    },
    values() {
      return this.campaigns ? this.campaigns.values : []
    }
  },
  methods: {
    getEvents() {
      ApiService.getEvents()
          .then((response) => {
            this.campaigns = response.data.data
            this.loading = false
          })
          .catch((error) => {
            console.log(error);
          });
    },
    getEventsByProject() {
      ApiService.getEventsByProject(this.$route.params.id)
          .then((response) => {
            this.campaigns = response.data.data
            this.loading = false
          })
          .catch((error) => {
            console.log(error);
          })
    },

    getCampaignsByClient() {

      let id = this.$route.name === 'client_campaigns' ? this.user.id : this.$route.params.id
      ApiService.getCampaignsByClient(id)
          .then((response) => {
            this.campaigns = response.data.data
            this.loading = false
          })
          .catch((error) => {
            console.log(error);
          })
    },

    deleteEvent(id) {
      axios.defaults.headers.common.Authorization = `Bearer ${this.$store.state.token}`;

      // get event
      axios.delete(`/admin/events/${id}`)
          .then((response) => {
            this.updateEvents()
          })
          .catch((error) => {
            console.log(error);
          });
    },
    updateEvents() {
      this.$emit('reloadEvents')
    },
    setEvent() {
      return this.eventType
    },
    openDialog(pos) {
      this.row = pos;
      this.dialog = true
    },
    eventCreatedCallback() {
      // this.snackMessage = 'Created successfully. Refreshing';
      // this.snackbar = true;
      // this.getEvents()
    },

    closeDialog() {
      this.dialog = false
    },

    setLocation(event) {
      if (event.event_type === 0) {

        // Activation
        if (event.geofence === true && event.location != null) {
          return `Location:${event.location.name}`
        } else {
          return 'Location:Not Set'
        }
      } else if (event.event_type === 2) {

        // Event
        if (event.location != null) {
          return `Locaction:${event.location.name}`
        } else {
          return 'Location:Not Set'
        }
      } else {

        // RSM
        if (event.open_plan) {
          return <v-row>
            <v-icon>lock</v-icon>
            <span>'Open Route RSM'</span>
          </v-row>
        } else
          return 'Closed route'
      }
    },
    eventType(event) {
      switch (event.type) {
        case 1:
          return 'Recce';
        case 2:
          return 'Event';
        default:
          return 'Activation'
      }
    },
    nextPage() {
      if (this.page + 1 <= this.numberOfPages) this.page += 1
    },
    formerPage() {
      if (this.page - 1 >= 1) this.page -= 1
    },
    updateItemsPerPage(number) {
      this.itemsPerPage = number
    },
    viewEvent(id) {
      this.$router.push({
        name: 'event',
        params: {id: id}
      });
    },
  }
};
</script>
