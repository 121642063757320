import Vue from 'vue';
import Vuex from 'vuex';
import auth from './auth'

Vue.use(Vuex);

//axios.defaults.baseURL = 'https://develop.hokela.co.ke/api';

export default new Vuex.Store({
    state: {

    },
    mutations: {

    },
    actions: {

    },
    modules: {
        auth
    },
    getters: {

    },
});
