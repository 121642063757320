<template>
  <v-card flat>
    <v-card-text>
      <v-text-field
          v-model="project_search"
          flat
          light
          solo
          background-color="grey lighten-3"
          color="black"
          label="Search Projects"
          prepend-inner-icon="search"
          single-line
          hide-details
      ></v-text-field>

      <v-data-table
          :search="project_search"
          :headers="headers"
          :items="items"
          :items-per-page="20"
          :loading="loading"
          loading-text="Loading... Please wait"
      >
        <template v-slot:item.name="{ item }" class="text-left">
          <v-btn text link class="text-left text-capitalize capitalize" @click="viewProject(item.id)">
            {{ item.name }}
          </v-btn>
        </template>
      </v-data-table>

    </v-card-text>
  </v-card>
</template>
<script>
import axios from "axios";
import ApiService from "@/services/ApiService";
import {mapGetters} from "vuex";

export default {
  data() {
    return {
      projects: null,
      project_search: null,
      loading: true,
    };
  },
  mounted() {
    this.getProjects()
  },
  computed: {
    ...mapGetters({
      user:'auth/user'
    }),
    headers() {
      return this.projects ? this.projects.headers : []
    },

    items() {
      return this.projects ? this.projects.table_data : []
    }
  },
  methods: {
    editProject(id) {
      this.$router.push({
        name: 'edit_project',
        params: {id: id},
        props: true
      });
    },
    deleteProject(id) {
      axios.delete(`/admin/projects/${id}`)
          .then((response) => {
            this.response = response.data.data;
            this.overlay = false;
          })
          .catch((error) => {
            console.log(error);
          });
    },
    viewProject(id) {
      this.$router.push({
        name: 'project',
        params: {id: id}
      });
    },
    getProjects() {
      if (this.$route.name === 'account_projects' || this.$route.name === 'client_projects') {

        let id = this.$route.name === 'account_projects'?this.$route.params.id: this.user.id

        ApiService.getProjectByClient(id)
            .then((response) => {
              this.projects = response.data.data;
              this.loading = false
            })
            .catch((error) => {
              console.log(error);
            });
      } else {
        ApiService.getProjects()
            .then((response) => {
              this.projects = response.data.data;
              this.loading = false
            })
            .catch((error) => {
              console.log(error);
            });
      }
    },

  },
};
</script>
