<template>
    <v-card width="100%" height="100%" class="mx-auto" flat color="transparent" align="center">
        <v-card-text>
            <v-img
                    :src="require('@/assets/404.svg')"
                    :lazy-src="require('@/assets/placeholder.png')"
                    max-width="600"
                    max-height="600"
            >
                <template v-slot:placeholder>
                    <v-row
                            class="fill-height ma-0"
                            align="center"
                            justify="center"
                    >
                        <v-progress-circular
                                indeterminate
                                color="primary"
                        ></v-progress-circular>
                    </v-row>
                </template>
            </v-img>
            <v-btn color="primary" to="/dashboard">
                Back to Homepage
            </v-btn>
        </v-card-text>
    </v-card>

</template>

<script>
    export default {
        name: 'NotFound'
    }
</script>
