<template>
  <div>
    <v-form>
      <!-- RSM name -->
      <v-text-field outlined prepend-inner-icon="text_fields"
                    v-model="event_name" label="Name"
                    class="mt-3"
                    required></v-text-field>

      <!--  Starting date -->
      <v-menu
          ref="menu"
          v-model="menu"
          :close-on-content-click="false"
          :return-value.sync="starting_date"
          transition="scale-transition"
          offset-y
          min-width="290px"
      >
        <template v-slot:activator="{ on }">
          <v-text-field
              outlined
              v-model="starting_date"
              label="Starting date"
              prepend-inner-icon="event"
              readonly
              v-on="on"
          ></v-text-field>
        </template>

        <v-date-picker v-model="starting_date" no-title scrollable>
          <v-spacer></v-spacer>
          <v-btn text color="primary" @click="menu = false">Cancel</v-btn>
          <v-btn text color="primary" @click="$refs.menu.save(starting_date)">
            OK
          </v-btn>
        </v-date-picker>
      </v-menu>

      <!-- ending date -->
      <v-menu
          ref="menu2"
          v-model="menu2"
          :close-on-content-click="false"
          :return-value.sync="ending_date"
          transition="scale-transition"
          offset-y
          min-width="290px"
      >
        <template v-slot:activator="{ on }">
          <v-text-field
              v-model="ending_date"
              outlined
              label="Ending date"
              prepend-inner-icon="event"
              readonly
              v-on="on"
          ></v-text-field>
        </template>
        <v-date-picker v-model="ending_date" no-title scrollable>
          <v-spacer></v-spacer>
          <v-btn text color="primary" @click="menu = false">Cancel</v-btn>
          <v-btn text color="primary" @click="$refs.menu2.save(ending_date)">
            OK
          </v-btn>
        </v-date-picker>
      </v-menu>

      <!--   checkin time-->
      <v-menu
          ref="checkinMenu"
          v-model="checkinMenu"
          :close-on-content-click="false"
          :nudge-right="40"
          :return-value.sync="checkin_time"
          transition="scale-transition"
          offset-y
          max-width="290px"
          min-width="290px"
      >
        <template v-slot:activator="{ on }">
          <v-text-field
              outlined
              v-model="checkin_time"
              label="Expected check-in time"
              prepend-inner-icon="access_time"
              readonly
              v-on="on"
          ></v-text-field>
        </template>
        <v-time-picker
            v-if="checkinMenu"
            v-model="checkin_time"
            full-width
            format="24hr"
            @click:minute="$refs.checkinMenu.save(checkin_time)"
        ></v-time-picker>
      </v-menu>

      <!--   checkout time-->
      <v-menu
          ref="checkoutMenu"
          v-model="checkoutMenu"
          :close-on-content-click="false"
          :nudge-right="40"
          :return-value.sync="checkout_time"
          transition="scale-transition"
          offset-y
          max-width="290px"
          min-width="290px"
      >
        <template v-slot:activator="{ on }">
          <v-text-field
              v-model="checkout_time"
              outlined
              label="Expected check-out time"
              prepend-inner-icon="access_time"
              readonly
              v-on="on"
          ></v-text-field>
        </template>
        <v-time-picker
            v-if="checkoutMenu"
            v-model="checkout_time"
            full-width
            format="24hr"
            @click:minute="$refs.checkoutMenu.save(checkout_time)"
        ></v-time-picker>
      </v-menu>

      <v-switch v-model="open_plan" label="Set RSM Route" inset></v-switch>

      <!--     Recces locations         -->
      <v-card flat outlined v-if="open_plan">

        <v-card-title> RSM routes</v-card-title>

        <div class="caption red--text">Set route RSM takes <strong>only one user</strong> and at least one location.
          Please add them below
        </div>
        <v-card-text>

          <!-- RSM User-->
          <v-autocomplete
              v-model="assigned_user"
              :items="items"
              :search-input.sync="search"
              dense
              class="mt-4"
              chips
              clearable
              hide-details
              hide-selected
              outlined
              type="number"
              item-text="phone"
              item-value="name"
              placeholder="Search for a user by phone number"
              label="Search for a user by phone number"
              return-object
              prepend-inner-icon="phone"
          >
            <template v-slot:no-data>
              <v-list-item>
                <v-list-item-title>
                  Start typing user
                  <strong>Phone number</strong>
                </v-list-item-title>
              </v-list-item>
            </template>
            <template v-slot:selection="{ attr, on, item, selected }">
              <v-chip
                  v-bind="attr"
                  :input-value="selected"
                  color="blue-grey"
                  class="white--text"
                  v-on="on"
              >
                <v-icon left>account_circle</v-icon>

                <span v-text="item.name"></span>
              </v-chip>
            </template>
            <template v-slot:item="{ item }">
              <v-list-item-avatar
                  color="indigo"
                  class="headline font-weight-light white--text"
              >
                {{ item.name.charAt(0) }}
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title v-text="item.name"></v-list-item-title>
                <v-list-item-subtitle v-text="item.phone"></v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-action>
                <v-icon>account_circle</v-icon>
              </v-list-item-action>
            </template>
          </v-autocomplete>

          <div class="my-4">
            <v-autocomplete
                v-model="locationName"
                :items="locationSearches"
                :search-input.sync="locations"
                chips
                dense
                prepend-inner-icon="location_on"
                clearable
                hide-details
                hide-selected
                type="text"
                item-text="lat"
                item-value="name"
                label="Search location by name"
                return-object
                outlined
            >
              <template v-slot:no-data>
                <v-list-item>
                  <v-list-item-title>
                    Start typing
                    <strong>location name</strong>
                  </v-list-item-title>
                </v-list-item>
              </template>
              <template v-slot:selection="{ attr, on, item, selected }">
                <v-chip
                    v-bind="attr"
                    :input-value="selected"
                    color="blue-grey"
                    class="white--text"
                    v-on="on"
                >
                  <v-icon left>location_on</v-icon>

                  <span v-text="item.name"></span>
                </v-chip>
              </template>
              <template v-slot:item="{ item }">
                <v-list-item-avatar
                    color="indigo"
                    class="headline font-weight-light white--text"
                >
                  {{ item.name.charAt(0) }}
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title v-text="item.name"></v-list-item-title>
                  <v-list-item-subtitle
                      v-text="item.lat+' ,'+item.lng"></v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action>
                  <v-icon>location_on</v-icon>
                </v-list-item-action>
              </template>
            </v-autocomplete>
          </div>

          <div class="w-full">
            <v-btn color="primary" outlined @click="addLocation">Add location</v-btn>
            <p class="caption mt-3">To add a new location click the locations button</p>
          </div>

          <v-list dense class="text-left">
            <div v-if="journey_plan.length===0">Added locations we appear hear</div>

            <v-list-item v-for="(location, index) in journey_plan" :key="index">

              <v-list-item-avatar>
                <v-icon>location_on</v-icon>
              </v-list-item-avatar>

              <v-list-item-content>
                <v-list-item-title v-html="location.name"></v-list-item-title>
                <v-list-item-subtitle v-html="location.start_date"></v-list-item-subtitle>
              </v-list-item-content>

              <v-list-item-icon>
                <v-icon @click="journey_plan.splice(index)">close</v-icon>
              </v-list-item-icon>
            </v-list-item>
          </v-list>
        </v-card-text>
      </v-card>
    </v-form>
    <v-card-actions>
      <v-btn color="primary" block @click="createEvent()" :loading="loading">create rsm</v-btn>
    </v-card-actions>
  </div>
</template>
<script>
import axios from "axios";

export default {
  data() {
    return {
      // row: '0',
      // dialog: false,
      event_name: '',
      starting_date: new Date().toISOString()
          .substr(0, 10),
      menu: false,
      menu2: false,
      ending_date: new Date().toISOString()
          .substr(0, 10),
      checkin_time: null,
      checkinMenu: false,
      checkout_time: null,
      checkoutMenu: false,
      locationName: null,
      locationSearches: [],
      locations: null,
      geo_fence: false,
      open_plan: false,
      assigned_user: null,
      search: null,
      items: [],
      journey_plan: [],
      journey_name: '',
      loading: false
    }
  },
  methods: {
    addLocation() {
      if (!this.locationName) {
        this.snackbar = true
        this.snackMessage = 'Location cannot be empty'
        return
      }

      let location = {
        id: this.locationName.id,
        name: this.locationName.name,
        start_date: this.date,
        frequency: this.frequency,
      }

      this.journey_plan.push(location)

      this.locationName = null
    },
    querySelections(val) {
      this.isLoading = true;

      // get event
      axios.get(`/search/users?query=${val}`)
          .then((response) => {
            this.items = response.data;
          })
          .catch((error) => {
            console.log(error);
          })
          .finally(() => (this.isLoading = false));
    },
    queryLocations(val) {
      this.isLoading = true;

      // get event
      axios.get(`/search/location?query=${val}`)
          .then((response) => {
            this.locationSearches = response.data;
          })
          .catch((error) => {
            console.log(error);
          })
          .finally(() => (this.isLoading = false));
    },
    createEvent() {
      this.loading = true


      axios.defaults.headers.common.Authorization = `Bearer ${this.$store.state.token}`;
      axios.post('/events', this.formatRequestData())
          .then(response => {

            //redirect to the created project
            if (response.status === 201) {
              this.loading = false
              this.$emit('updateEvents')
            }
          })
          .catch(error => {
            console.log(error);
            this.loading = false
            this.snackMessage = ' Error occurred creating the event';
            this.color = 'red'
            this.snackbar = true;
          });
    },

    formatRequestData() {
      return {
        'name': this.event_name,
        'date_starting': this.starting_date,
        'date_ending': this.ending_date,
        'checkin_time': this.checkin_time,
        'checkout_time': this.checkout_time,
        'phone': this.phone,
        'project_id': this.$route.params.id,
        'event_type': 1,
        'hokela_id': this.assigned_user ? this.assigned_user.id : null,
        'ba_image_checkin': false,
        'open_plan': this.open_plan,
        'journey_plan': this.journey_plan
      };
    },
  },
  watch: {
    locations(val) {
      val && val !== this.select && this.queryLocations(val);
    },
    search(val) {
      val && this.querySelections(val);
    },
  }
}
</script>
