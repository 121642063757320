<template>
  <v-card class="d-flex flex-row align-stretch grey lighten-5" height="100%" min-height="100vh">
    <v-navigation-drawer permanent class="flex-shrink-0" color="primary" dark>
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title class="title">
            {{ user.name }}
          </v-list-item-title>
          <v-list-item-subtitle class="mt-3">
            <v-btn text :to="{ name: 'logout' }">Logout</v-btn>
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>

      <v-divider dark></v-divider>

      <div class="caption text-center white--text my-3">Quick Access Panel</div>
      <v-list
          nav expand dense
          class="text-left">

        <v-list-item link :to="{ name: 'start' }">
          <v-list-item-icon>
            <v-icon>home</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Dashboard</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-divider></v-divider>

        <v-list-item link :to="{ name: 'locations' }">
          <v-list-item-icon>
            <v-icon>location_on</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Retail Network</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item link :to="{ name: 'users' }">
          <v-list-item-icon>
            <v-icon>face</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Users</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <v-card class="flex-grow-1 flex-shrink-1 flex-shrink-0" height="100%">
      <v-toolbar light flat>
        <v-toolbar-title>Account/User Creation</v-toolbar-title>
      </v-toolbar>

      <v-card-text>
        <v-row no-gutters>
          <v-col cols="12" sm="6">
            <v-card outlined flat height="100%">

              <v-card-text>

                <snackbar :text="snackMessage" :snackbar="snack" :color="snackColor"></snackbar>

                <v-form v-model="form">
                  <v-select
                      label="Select account type"
                      v-model="account_type"
                      :items="accounts"
                      item-text="name"
                      item-value="id"
                      outlined
                  >
                  </v-select>

                  <div v-if="account_type===2">
                    <v-text-field
                        v-model="account_name"
                        label="Enter the Clients' Account name"
                        outlined
                        required>
                    </v-text-field>


                    <v-select
                        label="Select the clients plan"
                        v-model="pricing_select"
                        :items="pricing"
                        item-text="name"
                        item-value="id"
                        outlined
                    >
                    </v-select>

                    <div class="caption mt-3 text-left"> Select an icon to show on the Account. The name initials will
                      be shown of the icon is not selected
                    </div>
                    <v-row align="center">
                      <v-col cols="7">
                        <v-file-input prepend-inner-icon="image"
                                      v-model="client_photo"
                                      class="mt-5"
                                      outlined
                                      chips
                                      show-size
                                      accept="image/*"
                                      label="Client image/logo">
                        </v-file-input>
                      </v-col>

                      <v-col cols="4">
                        <v-avatar height="100" width="100">

                          <v-img :src="loadImage"></v-img>
                        </v-avatar>
                      </v-col>
                    </v-row>

                    <div class="caption mt-3 text-left">Finally create a user to manage the account.
                      The user will have the ability to add and remove users from the Account
                    </div>
                  </div>
                  <v-text-field
                      v-model="name"
                      outlined
                      label="User's name"
                      required>
                  </v-text-field>

                  <v-text-field
                      v-model="email"
                      outlined
                      :rules="emailRules"
                      label="User's e-mail"
                      required>
                  </v-text-field>

                  <div
                      v-if="account_type>2">
                    <v-autocomplete
                        v-model="model"
                        :items="items"
                        :loading="isLoading"
                        :search-input.sync="search"
                        outlined
                        clearable
                        hide-details
                        hide-selected
                        item-text="email"
                        item-value="name"
                        label="Search client by email"
                        return-object>

                      <template v-slot:no-data>
                        <v-list-item>
                          <v-list-item-title>
                            Start typing client
                            <strong>Email</strong>
                          </v-list-item-title>
                        </v-list-item>
                      </template>
                      <template v-slot:selection="{ attr, on, item, selected }">
                        <v-chip
                            v-bind="attr"
                            :input-value="selected"
                            color="blue-grey"
                            class="white--text"
                            v-on="on"
                        >
                          <v-icon left>account_circle</v-icon>

                          <span v-text="item.name"></span>
                        </v-chip>
                      </template>
                      <template v-slot:item="{ item }">
                        <v-list-item-avatar
                            color="indigo"
                            class="headline font-weight-light white--text"
                        >
                          {{ item.name.charAt(0) }}
                        </v-list-item-avatar>
                        <v-list-item-content>
                          <v-list-item-title v-text="item.name"></v-list-item-title>
                          <v-list-item-subtitle v-text="item.phone"></v-list-item-subtitle>
                        </v-list-item-content>
                        <v-list-item-action>
                          <v-icon>account_circle</v-icon>
                        </v-list-item-action>
                      </template>
                    </v-autocomplete>
                  </div>
                  <v-btn dark color="blue darken-1" @click="handleSubmit" :loading="loading" block>Add User</v-btn>
                </v-form>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12" sm="6">
            <v-card outlined height="100vh">
              <v-card-title class="body-1">Pending invites</v-card-title>
              <v-card-subtitle class="caption red--text">All links expire after 48 hours if not used</v-card-subtitle>
              <v-card-text>
                <v-simple-table dense height="70vh">
                  <template v-slot:default>
                    <thead>
                    <tr>
                      <th class="text-left">
                        Name
                      </th>
                      <th class="text-left">
                        Email
                      </th>
                      <th class="text-left">
                        Actions
                      </th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr
                        v-for="item in pending"
                        :key="item.id"
                    >
                      <td>
                        <div class="grey--text darken-2 text-left">
                          {{ item.name }}
                        </div>
                      </td>
                      <td class="text-left">
                        <div class="grey--text darken-2 text-left">
                          {{ item.email }}
                        </div>
                      </td>

                      <td>
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                                @click="resendEmail(item.id)"
                                v-bind="attrs"
                                v-on="on"
                                icon>
                              <v-icon>email</v-icon>
                            </v-btn>
                          </template>
                          <span>Resend email</span>
                        </v-tooltip>

                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                                @click="deleteInvite(item.id)"
                                v-bind="attrs"
                                v-on="on"
                                icon>
                              <v-icon>delete</v-icon>
                            </v-btn>
                          </template>
                          <span>Cancel invite</span>
                        </v-tooltip>

                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                                @click="copyLink(item.token)"
                                v-bind="attrs"
                                v-on="on"
                                icon>
                              <v-icon>link</v-icon>
                            </v-btn>
                          </template>
                          <span>Copy link to invite</span>
                        </v-tooltip>
                      </td>
                    </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-card>
</template>
<script>
import ApiService from "@/services/ApiService";
import {mapGetters} from "vuex"

export default {
  data() {
    return {
      form:null,
      pending: [],
      dialog: false,
      overlay: false,
      pricing: [
        {
          name: 'Basic plan',
          id: 0
        },
        {
          name: 'Standard plan',
          id: 1
        },
        {
          name: 'Enterprise plan',
          id: 2
        },
        {
          name: 'Custom Plan',
          id: 3
        }
      ],
      pricing_select: null,
      name: '',
      account_type: '',
      client_plan: '',
      account_name: '',
      user_type: '',
      search: null,
      email: '',
      client_photo: '',
      numberRules: [
        v => !!v || 'Required',
        v => v >= 1 || 'Only positive numbers are allowed for this option.',
        v => v <= 50000 || 'The limit for this choice is 50,000',
      ],
      emailRules: [
        v => !!v || 'E-mail is required',
        v => /.+@.+/.test(v) || 'E-mail must be valid',
      ],
      account_types: [
        {
          name: 'Client Account',
          id: 2

        },
        {
          name: 'Super Admin',
          id: 0,
        },
        {
          name: 'Assistant Admin',
          id: 1

        },
        {
          name: 'Project Manager',
          id: 3
        },
        {
          name: 'Assistant PM',
          id: 4
        },
        {
          name: 'Client Service',
          id: 5
        }
      ],
      snack: false,
      snackMessage: '',
      loading: false,
      snackColor: 'primary',
      items: [],
      select: null,
      model: null
    };
  },
  watch: {
    search(val) {
      val && val !== this.select && this.querySelections(val);
    },

  },
  methods: {
    resendEmail() {
      ApiService.resendEmail(id)
          .then((response) => {
            this.snackMessage = 'Email sent successful'
            this.snackColor = 'primary'
            this.snack = true
            this.getPendingUsers()
          })
          .catch((error) => {
            this.snackMessage = 'Unable to resend. Expired session or invalid link.'
            this.snackColor = 'primary'
            this.snack = true
            this.getPendingUsers()
          })
    },
    deleteInvite() {
      ApiService.deleteInvite(id)
          .then((response) => {
            this.snackMessage = 'Invite cancelled'
            this.snackColor = 'primary'
            this.snack = true
            this.getPendingUsers()
          })
          .catch((error) => {
            console.log(error);
          })
    },

    copyLink(token) {
      this.$copyText(`https://hokela.co.ke/login?token=${token}`)
          .then(e => {
            this.snackMessage = 'Link copied to clipboard'
            this.snackColor = 'primary'
            this.snack = true
          }).catch(error => {
        this.snackMessage = 'Unable to copy link. Try again later'
        this.snackColor = 'orange'
        this.snack = true
      })
    },

    // TODO validate form before send
    handleSubmit() {
      this.loading = true
      //let acc_type = this.account_types.indexOf(this.account_type);
      let formData = {
        'client_icon': this.client_photo,
        'name': this.name,
        'account_name': this.account_name,
        'account_type': this.account_type,
        'plan': this.pricing_select,
        'email': this.email
      }

      if (this.account_type > 2) {
        formData['client_id'] = this.model.id
      }

      ApiService.addTemporaryUser(formData)
          .then(response => {
            if (response.status === 201) {
              this.loading = false
              this.snackMessage = 'User invited successfully. A confirmation email has been forwarded to the email address'
              this.snackColor = 'green'
              this.snack = true
              this.getPendingUsers()
            } else {
              this.loading = false
              this.snackMessage = response.data.message
              this.snackColor = 'red'
              this.snack = true
            }
          })
          .catch(error => {
            this.loading = false
            this.snackMessage = 'An error occurred trying to invite the user. Try again later'
            this.snackColor = 'red'
            this.snack = true
          });
    },
    querySelections(val) {
      this.isLoading = true;

      ApiService.searchClient(val)
          .then((response) => {
            this.items = response.data;
          })
          .catch((error) => {
            console.log(error);
          })
          .finally(() => (this.isLoading = false));
    },
    getPendingUsers() {
      ApiService.getPendingUsers()
          .then(response => {
            this.pending = response.data.data;
          })
          .catch(error => {
            console.log('error getUser', error);
          });
    }
  },
  mounted() {
    // this.getUser();
    this.getPendingUsers()
  },
  computed: {
    ...mapGetters({
      user: 'auth/user'
    }),
    accounts() {
      let account_type = this.user.account_type;
      let options = this.account_types;
      switch (account_type) {
        case 0:
          return options
        case 1:
          return options.slice(0);
        case 2:
          return options.splice(3);
        case 3:
        case 4:
        case 5:
        default:
          return [];
      }
    },
    loadImage() {
      if (this.client_photo)
        return URL.createObjectURL(this.client_photo);
      else
        return require('@/assets/placeholder.png')
    }
  },
};
</script>
