<template>
  <v-dialog v-model="dialog" width="600" scrollable>
    <template v-slot:activator="{ on }">
      <v-btn :color="theme" v-on="on" class="body-2" text dark>Contact</v-btn>
    </template>

    <v-snackbar v-model="snackbar">
      {{ snackMessage }}
    </v-snackbar>

    <v-card>
      <v-card-title class="title text-center">
        <div class="title">We would like to hear from you</div>
        <v-spacer></v-spacer>
        <v-btn icon @click="dialog = false">
          <v-icon>close</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <v-form
            ref="form"
            v-model="valid"
            lazy-validation
        >
          <v-text-field
              v-model="name"
              label="First and Last name"
              :rules="nameRules"
              required
          ></v-text-field>
          <v-text-field
              v-model="phone"
              label="Your phone number"
              :rules="phoneRules"
              type="phone"
              required
          ></v-text-field>

          <v-text-field
              v-model="email"
              :rules="emailRules"
              label="E-mail address"
              required
          ></v-text-field>

          <v-text-field
              v-model="company"
              :rules="companyRules"
              label="Where do you work?"
              required
          ></v-text-field>

          <v-textarea
              v-model="message"
              :rules="messageRules"
              background-color="amber lighten-4"
              color="orange orange-darken-4"
              hint="Say something am giving up on you... 🎶"
              label="Type your message "
              autocapitalize="sentences"
          ></v-textarea>

          <v-btn class="my-3 body-1" :loading="loading" large block color="primary" dark @click="submit">send
          </v-btn>

          <div class="caption">We might send you informational and or promotional messages about
            Hokela. No spamming, we promise
          </div>

        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
import axios from "axios";

export default {
  props: {
    theme:String
  },
  data() {
    return {
      snackbar: false,
      snackMessage: null,
      dialog: false,
      drawer: false,
      valid: true,
      name: null,
      nameRules: [
        v => !!v || 'Name is required',
      ],
      email: null,
      phone: null,
      phoneRules: [
        v => !!v || 'Phone is required',
        v => (v && v.length >= 10) || 'Phone must be 10 or more characters',
      ],
      emailRules: [
        v => !!v || 'E-mail is required',
        v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
      ],
      company: null,
      companyRules: [
        v => !!v || 'company is required',
      ],
      message: null,
      messageRules: [
        v => !!v || 'message is required',
      ],
      loading: false,
    }
  },

  methods: {

    submit() {
      this.loading = true
      this.$refs.form.validate()

      if (this.valid) {

        // create team
        axios.post('/admin/contact', {
          name: this.name,
          phone: this.phone,
          email: this.email,
          company: this.company,
          message: this.message,
        })
            .then((response) => {
              if (response.status === 201) {
                this.snackMessage = "Contact form submitted successfully. We will in contact soon"
                this.snackbar = true
                this.loading = false;
                this.dialog = false

              } else {
                this.snackMessage = "Unable to feedback. Check data or try again later"
                this.snackbar = true

                this.loading = false;
              }
            })
            .catch((error) => {
              console.log(error);
              this.snackMessage = 'An error occurred while submitting. This could be caused by a poor internet connection. Try again later';
              this.snackbar = true;
              this.loading = false;
            });

      } else {
        this.loading = false
      }
    }
  }
}
</script>
