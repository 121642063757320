<template>
  <v-card tile flat class="d-flex flex-row align-stretch" min-height="100vh" width="100vw" height="100%">
    <v-navigation-drawer permanent color="primary" dark class="flex-shrink-0">
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title class="title">
            {{ user.name }}
          </v-list-item-title>
          <v-list-item-subtitle class="mt-3">
            <v-btn text :to="{ name: 'logout' }">Logout</v-btn>
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>

      <v-divider></v-divider>

      <v-list
          dense
          nav expand
          class="text-left"
      >
        <v-list-item link :to="{ name: 'start' }">
          <v-list-item-icon>
            <v-icon>home</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title class="body-1">Dashboard</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-divider></v-divider>

        <v-list-item link :to="{ name: 'locations'}">
          <v-list-item-icon>
            <v-icon>location_on</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title class="body-1">Locations</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item link :to="{ name: 'retail_map'}">
          <v-list-item-icon>
            <v-icon>explore</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title class="body-1">Map view</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item link :to="{ name: 'users' }">
          <v-list-item-icon>
            <v-icon>face</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title class="body-1">Users</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
    <div class="flex-grow-1 flex-shrink-0">
      <v-toolbar flat>
        <v-toolbar-title class="title">Retail Network</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn color="black d-inline pa-2 body-2" dark @click.stop="dialog = true">Add new Location</v-btn>
        <v-btn color="black d-inline pa-2 body-2 mx-2" dark @click="dialog =true">Import Locations</v-btn>
      </v-toolbar>
      <router-view/>
    </div>
  </v-card>
</template>
<script>
import {mapGetters} from 'vuex'

export default {
  computed: {
    ...mapGetters({
      user: 'auth/user'
    })
  },

}
</script>
