<template>
    <v-dialog class="dialog" v-model="dialog" max-height="800px" max-width="600px">
        <template v-slot:activator="{ on }">
            <v-btn text color="black" class="caption" v-on="on">Preview</v-btn>
        </template>
        <v-card>
            <v-card-title>
                <v-avatar color="grey" v-if="client.client_icon">
                    <v-img :src="`https://develop.hokela.co.ke/api/images?uri=${client.client_icon}`"></v-img>
                </v-avatar>
                <div class="title ml-3">{{client.account_name}}</div>
                <v-spacer></v-spacer>

                <v-btn icon @click="dialog=false">
                    <v-icon>close</v-icon>
                </v-btn>
            </v-card-title>
            <v-divider></v-divider>

            <v-alert
                v-if="alert"
                :type="alertType"
                dense
                >
                {{alertMessage}}
            </v-alert>
            <v-card-text>
                <v-row
                        align="center"
                        justify="space-around"
                >
                    <div class="ma-3 caption">{{client.name}}</div>
                    <div class="ma-3 orange--text caption">{{clientPlan}}</div>
                </v-row>

                <v-row
                        align="center"
                        justify="space-around"
                        class="grey lighten-5"
                >
                    <v-card
                            class="pa-6"
                            flat
                            color="#F0F8FF"
                    >
                        <v-card-title>
                            <div class="grey--text caption">Projects</div>
                        </v-card-title>

                        <v-card-text>
                            <div class="display-2 black--text">
                                {{preview.projects}}
                            </div>
                        </v-card-text>
                    </v-card>

                    <v-card
                            class="pa-6"
                            flat
                            color="#F0F8FF"
                    >
                        <v-card-title>
                            <div class="grey--text caption">Users</div>
                        </v-card-title>

                        <v-card-text>
                            <div class="display-2 black--text">
                                {{preview.users}}
                            </div>
                        </v-card-text>
                    </v-card>

                    <v-card
                            class="pa-6"
                            flat
                            color="#F0F8FF"
                    >
                        <v-card-title>
                            <div class="green--text caption">Active</div>
                        </v-card-title>

                        <v-card-text>
                            <div class="display-2 black--text">
                                {{preview.active}}
                            </div>
                        </v-card-text>
                    </v-card>


                </v-row>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>
<script>
    import axios from "axios";

    export default {
        props: {
            client: Object,
        },
        data() {
            return {
                alert:false,
                alertType:'info',
                alertMessage:'',
                dialog: false,
                client_account: {
                    users: 12,
                    project: 5,
                },
                preview: {
                    projects: 0,
                    users: 0,
                    active: 0,
                },
            }
        },
        computed: {
            clientPlan() {
                let plans = ['Basic Plan', 'Standard Plan', 'Enterprise Plan']
                return plans[this.client.client_plan]
            }
        },
        mounted() {
            this.getPreview();
        },
        methods: {
            getPreview() {
                this.alertMessage = 'Loading preview...';
                this.alertType = 'info';
                this.alert = true;
                axios.defaults.headers.common.Authorization = `Bearer ${this.$store.state.token}`;
                axios.get(`/preview/${this.client.id}`)
                    .then((response) => {
                        if (response.status === 200){
                            this.preview = response.data.data;
                            this.alertMessage = '';
                            this.alert = false;
                        }else {
                            this.alertMessage = 'Error loading preview. Retry later.';
                            this.alertType = 'error';
                            this.alert = true;
                        }
                    })
                    .catch((error) => {
                            this.alertMessage = 'Error loading preview. Retry later.';
                            this.alertType = 'error';
                            this.alert = true;

                        console.log(error);
                    });
            },
        }
    }
</script>
