<template>
  <div>
    <v-menu
      v-model="innerMenuOpen"
      :close-on-content-click="false"
      transition="scale-transition"
      offset-y
    >
      <template #activator="{ on, attrs }">
        <v-text-field
          v-model="innerValue"
          dense
          outlined
          readonly
          :disabled="disabled"
          v-bind="attrs"
          v-on="on"
        >
          <template #label>
            <div class="text-body-2">
              {{ label }}
            </div>
          </template>
        </v-text-field>
      </template>
      <v-time-picker v-model="innerValue">
        <v-btn text small @click="innerMenuOpen = false">Close</v-btn>

        <v-btn text small @click="innerValue = null">Reset</v-btn>
      </v-time-picker>
    </v-menu>
  </div>
</template>

<script>
export default {
  props: {
    menuOpen: {
      type: Boolean,
      default: false,
    },
    value: {
      type: String,
      default: null,
    },
    label: {
      type: String,
      default: "Time",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    innerValue: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },

    innerMenuOpen: {
      get() {
        return this.menuOpen;
      },
      set(val) {
        this.$emit("update:menuOpen", val);
      },
    },
  },
};
</script>