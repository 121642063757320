var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"d-flex flex-row flex-wrap",attrs:{"tile":"","flat":"","min-height":"100vh","color":"primary","width":"100vw","height":"100%"}},[_c('v-navigation-drawer',{staticClass:"flex-shrink-0",staticStyle:{"position":"static"},attrs:{"permanent":"","color":"primary","dense":"","fixed":"","dark":"","height":"100%","min-height":"100vh"}},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"3"}},[_c('v-navigation-drawer',{attrs:{"dark":"","color":"primary darken-1","mini-variant":"","height":"100vh","permanent":""}},[_c('v-list',{attrs:{"dense":"","nav":""}},[_c('v-list-item',{staticClass:"my-5",attrs:{"link":"","to":{ name: 'dashboard' }}},[_c('v-list-item-icon',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v("home")])]}}])},[_c('span',[_vm._v("Home Page")])])],1)],1),_c('v-list-item',{staticClass:"my-5",attrs:{"link":"","to":{ name: 'locations' }}},[_c('v-list-item-icon',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v("location_on")])]}}])},[_c('span',[_vm._v("Retail Network")])])],1)],1),_c('v-list-item',{staticClass:"my-5",attrs:{"link":"","to":{ name: 'users' }}},[_c('v-list-item-icon',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v("face")])]}}])},[_c('span',[_vm._v("Users")])])],1)],1)],1)],1)],1),_c('v-col',[_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"title"},[_vm._v(" "+_vm._s(_vm.user.name)+" ")]),_c('v-list-item-subtitle',{staticClass:"mt-3"},[_c('v-btn',{attrs:{"text":"","to":{ name: 'logout' }}},[_vm._v("Logout")])],1)],1)],1),_c('v-divider'),_c('v-list',{staticClass:"text-left",attrs:{"dense":"","nav":"","width":"100%"}},[_c('v-list-item',{staticClass:"my-2",attrs:{"link":"","to":{
              name: 'report',
            }}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("show_chart")])],1),_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"body-2 text-left"},[_vm._v("Overview")])],1)],1),_c('v-list-item',{staticClass:"my-2",attrs:{"link":"","to":{
              name: 'report_table',
            }}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("table_chart")])],1),_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"body-2 text-left"},[_vm._v("Data Table")])],1)],1),_c('v-list-item',{staticClass:"my-2",attrs:{"link":"","to":{ name: 'report_images' }}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("insert_photo")])],1),_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"body-2 text-left"},[_vm._v("Images")])],1)],1),_c('v-list-item',{staticClass:"my-2",attrs:{"link":"","to":{ name: 'report_edit' }}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("edit")])],1),_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"body-2 text-left"},[_vm._v("Edit Report")])],1)],1)],1)],1)],1)],1),_c('v-card',{staticClass:"flex-grow-1",attrs:{"width":"80%","height":"100vh"}},[_c('router-view')],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }