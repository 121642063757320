<template>
  <div>
    <v-snackbar v-model="snackbar" timeout="2000">
      {{ snackMessage }}
    </v-snackbar>

    <!--      Create location dialog-->
    <v-dialog v-model="dialog" max-width="600px" scrollable>
      <v-card>
        <v-card-title>
          <span v-if="!editing">Add new location to hokela network</span>
          <span v-else>Update location</span>
          <v-spacer></v-spacer>
          <v-btn icon @click="closeDialog">
            <v-icon>close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <form
              ref="form"
              v-model="valid"
              :lazy-validation="true"
          >
            <v-text-field
                v-model="name"
                outlined
                :rules="nameRules"
                label="Location name*"
                required
            ></v-text-field>

            <v-text-field
                v-model="lat"
                outlined
                :rules="latRules"
                label="Location latitude*"
                required
            ></v-text-field>

            <v-text-field
                v-model="lng"
                outlined
                :rules="lngRules"
                label="Location Longitude*"
                required
            ></v-text-field>

            <v-autocomplete
                v-model="category"
                outlined
                :rules="[() => !!category || 'This field is required']"
                :items="categories"
                label="Category*"
                placeholder="Select category..."
                required
            ></v-autocomplete>

            <v-autocomplete
                v-model="county"
                outlined
                :rules="[() => !!county || 'This field is required']"
                :items="counties"
                label="County*"
                placeholder="Select county..."
                required
            ></v-autocomplete>

            <v-autocomplete
                v-model="vehicle_option"
                :rules="[() => !!vehicle_option || 'This field is required']"
                :items="vehicles"
                outlined
                label="Vehicle Option*"
                placeholder="True or False..."
                required
            ></v-autocomplete>

            <v-autocomplete
                v-model="best_visit_day"
                :rules="[() => !!best_visit_day || 'This field is required']"
                :items="days"
                outlined
                label="Best Visit Day*"
                placeholder="Select Day..."
                required
            ></v-autocomplete>

            <v-text-field
                v-model="local_area"
                outlined
                label="Local Area"
                :rules="[() => !!local_area || 'This field is required']"
            ></v-text-field>

            <v-text-field
                v-model="market_name"
                outlined
                label="Market Name"
            ></v-text-field>

            <v-text-field
                outlined
                v-model="street"
                label="Street"
            ></v-text-field>

            <v-text-field
                outlined
                v-model="owner_phone"
                label="Owner Phone"
            ></v-text-field>

            <v-text-field
                outlined
                v-model="owner_email"
                label="Owner Email"
            ></v-text-field>
            <v-btn block color="primary" v-if="this.editing === false" class="mr-4"
                   @click="submit">Create new location
            </v-btn>
            <v-btn block dark color="primary" v-if="this.editing === true" class="mr-4"
                   @click="updateLocation">Update Location details
            </v-btn>
          </form>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-card flat >
      <v-card-title>
        <v-text-field
            v-model="search"
            flat
            prepend-inner-icon="mdi-magnify"
            solo-inverted
            label="Search locations"
            single-line
            hide-details
        ></v-text-field>
      </v-card-title>

      <v-data-table
          dense
          :search="search"
          :headers="headers"
          :items="parsedLocations"
          class="elevation-1"
          :loading="this.loading"
          loading-text="Loading... Please wait"
      >
        <template v-slot:item.read="{ item }">
          <v-row justify="space-around">
            <v-col cols="6">
              <v-btn @click="openDialog(item)" text>
                <v-icon color="blue lighten-1">edit</v-icon>
              </v-btn>
            </v-col>
            <v-col cols="6">
              <v-btn @click="deleteLocation(item)" text>
                <v-icon color="red lighten-1">delete</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>
<script>
import ApiService from "@/services/ApiService";

export default {
  data() {
    return {
      overlay: false,
      search: '',
      locations:[],
      headers: [
        {
          text: 'Location',
          align: 'start',
          value: 'name',
        },
        {text: 'County', value: 'county', align: 'start'},
        {text: 'Category', value: 'category', align: 'start'},
        {text: 'Best Visit Day', value: 'best_visit_day', align: 'start'},
        {text: 'Vehicle Option', value: 'vehicle_option', align: 'start'},
        {text: 'Actions', value: 'read', align: 'center'},
      ],
      best_visit_day: null,
      local_area: null,
      market_name: null,
      street: null,
      owner_phone: null,
      owner_email: null,
      county: null,
      counties: ['Mombasa', 'Kwale', 'Kilifi', 'Tana River', 'Lamu', 'Taita-Taveta', 'Garissa', 'wajir', 'Mandera', 'Marsabit',
        'Isiolo', 'Meru', 'Tharaka-Nithi', 'Embu', 'Kitui', 'Machakos', 'Makueni', 'Nyandarua', 'Nyeri', 'Kirinyaga',
        'Murang\'a', 'Kiambu', 'Turkana', 'West Pokot', 'Samburu', 'Trans Nzoia', 'Uasin Gishu', 'Elgeyo-Marakwet', 'Nandi', 'Baringo',
        'Laikipia', 'Nakuru', 'Narok', 'Kajiado', 'Kericho', 'Bomet', 'Kakamega', 'Vihiga', 'Bungoma', 'Busia',
        'Siaya', 'Kisumu', 'Homa Bay', 'Migori', 'Kisii', 'Nyamira', 'Nairobi'],
      categories: ['Wholesaler', 'Shop', 'Supermarket', 'Kiosk'],
      vehicles: ['False', 'True'],
      days: ['Unknown', 'Mon', 'Tue', 'Wed', 'Thur', 'Fri', 'Sat', 'Sun'],
      sendCategory: 0,
      sendCounty: 0,
      sendVehicle: 0,
      sendDay: 0,
      itemId: null,
      editing: false,
      snackbar: false,
      snackMessage: null,
      dialog: false,
      loading: false,
      category: null,
      vehicle_option: null,
      valid: null,
      lat: '',
      lng: '',
      name: '',
      nameRules: [
        v => !!v || 'Location name is required',
      ],
      latRules: [
        v => /^(-?[1-8]?\d(?:\.\d{1,18})?|90(?:\.0{1,18})?)$/.test(v) || 'Latitude coordinates must be valid',
      ],
      lngRules: [
        v => /^(-?(?:1[0-7]|[1-9])?\d(?:\.\d{1,18})?|180(?:\.0{1,18})?)$/.test(v) || 'Longitude coordinates must be valid',
      ],
    }
  },
  computed: {
    parsedLocations() {
      return this.locations.map(item => {
        item.county = this.counties[item.county - 1]
        item.best_visit_day = this.days[item.best_visit_day]
        item.category = this.categories[item.category]
        item.vehicle_option = this.vehicles[item.vehicle_option]
        return item
      })
    }
  },
  mounted() {
    this.getLocations()
  },
  methods: {
    closeDialog() {
      this.editing = false
      this.dialog = false
    },
    getLocations() {
      this.loading = true;
      ApiService.getLocations()
          .then((response) => {
            this.locations = response.data.data.locations;
            this.loading = false
          })
          .catch((error) => {
            console.log(error);
          });
    },
    submit() {
      let submitlocation = {
        name: this.name,
        lat: this.lat,
        lng: this.lng,
        category: this.sendCategory,
        county: this.sendCounty,
        vehicle_option: this.sendVehicle,
        best_visit_day: this.sendDay,
      }
      ApiService.submitLocation(submitlocation)
          .then((response) => {
            if (response.status === 201) {
              this.name = null;
              this.lat = null;
              this.lng = null;
              this.category = null;
              this.county = null;
              this.vehicle_option = null;
              this.dialog = false;
              this.snackMessage = 'Location created successfully';
              this.snackbar = true;

              this.getLocations()

            } else {
              this.snackbar = true;
              this.snackMessage = 'An error occurred creating the the location';
            }
          })
          .catch((error) => {
            this.snackMessage = error.message;
            this.snackbar = true;
          });
    },

    openDialog(item) {
      this.dialog = true;
      this.editing = true;

      this.name = item.name;
      this.lat = item.lat;
      this.lng = item.lng;
      this.category = item.category;
      this.county = item.county;
      this.vehicle_option = item.vehicle_option;
      this.itemId = item.id;
    },

    // Updates Location
    updateLocation() {
      let locationObj = {
        name: this.name,
        lat: this.lat,
        lng: this.lng,
        category: this.category,
        county: this.county,
        vehicle_option: this.vehicle_option,
      }
      ApiService.updateLocation(this.itemId, locationObj)
          .then(response => {
            if (response.status === 200) {
              this.dialog = false;
              this.snackbar = true;
              this.snackMessage = "location updated.";
              this.getLocations();
              this.itemId = null;
              this.name = null;
              this.lat = null;
              this.lng = null;
              this.category = null;
              this.county = null;
              this.vehicle_option = null;
            } else {
              this.getLocations();
              this.snackbar = true;
              this.snackMessage = "Location NOT updated. Check your internet or try again later.";
            }
          })
    },

    // Deletes location
    deleteLocation(item) {
      ApiService.deleteLocation(itemId)
          .then(response => {
            if (response.status === 200) {
              this.snackbar = true;
              this.snackMessage = `${item.name} location deleted.`;
              this.getLocations();
            } else {
              this.snackbar = true;
              this.snackMessage = `${item.name} location NOT deleted. Check your internet or try again later.`;
            }
          })
          .catch(error => console.log(error));
    },
  },
  watch: {
    category(newVal) {
      this.sendCategory = this.categories.indexOf(newVal) + 1;
    },
    county(newVal) {
      this.sendCounty = this.counties.indexOf(newVal) + 1;
    },
    vehicle_option(newVal) {
      this.sendVehicle = (newVal === 'True') ? 1 : 0;
    },
    best_visit_day(newVal) {
      this.sendDay = this.days.indexOf(newVal) + 1;
    },
  },
}
</script>
