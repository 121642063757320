var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"flat":"","tile":""}},[_c('v-card-text',[_c('v-data-iterator',{attrs:{"hide-default-footer":"","loading":_vm.loading,"page":_vm.page,"items":_vm.images,"loading-text":"Loading image data...","items-per-page":_vm.itemsPerPage},on:{"update:itemsPerPage":function($event){_vm.itemsPerPage=$event},"update:items-per-page":function($event){_vm.itemsPerPage=$event}},scopedSlots:_vm._u([(_vm.selectedImages.length > 0)?{key:"header",fn:function(){return [_c('v-toolbar',{staticClass:"mb-6",attrs:{"dense":""}},[_c('v-toolbar-title',[_vm._v("Selected Images ("+_vm._s(_vm.selectedImages.length)+")")]),_c('div',{staticClass:"d-flex justify-space-between flex-grow-1"},[_c('v-btn',{staticClass:"ml-4",attrs:{"small":"","text":""},on:{"click":_vm.downloadSelectedImages}},[_c('div',{staticClass:"d-flex justify-center align-center"},[_vm._v(" "+_vm._s(_vm.downloadingSelectedImages ? "Downloading" : "Download")+" "),(_vm.downloadingSelectedImages)?_c('v-progress-circular',{staticClass:"ml-2",attrs:{"indeterminate":"","size":"16","color":"blue"}}):_vm._e()],1)]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"small":"","icon":""},on:{"click":function($event){_vm.selectedImages = []}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-close-circle")])],1)]}}],null,false,3112398283)},[_c('span',[_vm._v(" Clear Selection ")])])],1)],1)]},proxy:true}:null,{key:"default",fn:function(props){return [_c('v-row',_vm._l((props.items),function(image){return _c('v-col',{key:image.id,attrs:{"cols":"12","sm":"6","md":"4","lg":"3"}},[_c('v-card',{style:({ position: 'relative' }),attrs:{"max-width":"400px","flat":"","color":"#F0F8FF"}},[_c('v-img',{staticClass:"white--text align-end",attrs:{"src":("https://develop.hokela.co.ke/api/images?uri=" + (image.src)),"height":"250px"},scopedSlots:_vm._u([{key:"placeholder",fn:function(){return [_c('v-row',{staticClass:"fill-height ma-0",attrs:{"align":"center","justify":"center"}},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"black"}})],1)]},proxy:true}],null,true)},[_c('v-card-title',{staticClass:"title"},[_vm._v(_vm._s(image.title || ""))])],1),_c('v-card-subtitle',{staticClass:"pb-0 caption"},[_vm._v(_vm._s(image.subtitle || ""))]),_c('v-card-text',{staticClass:"text--primary caption"},[_c('div',[_vm._v(_vm._s(image.description || ""))])]),_c('v-card-actions',[_c('v-spacer'),_c('v-checkbox',{attrs:{"value":image},model:{value:(_vm.selectedImages),callback:function ($$v) {_vm.selectedImages=$$v},expression:"selectedImages"}}),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.showFullImage(image.src)}}},[_c('v-icon',[_vm._v("mdi-eye")])],1),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.downloadImage(image.src, image.title)}}},[_c('v-icon',[_vm._v("cloud_download")])],1)],1),(_vm.selectedImages.includes(image))?_c('div',{style:({
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  backgroundColor: 'black',
                  opacity: 0.2,
                  width: '100%',
                  height: '100%',
                  borderRadius: '4px',
                  pointerEvents: 'none',
                })}):_vm._e()],1)],1)}),1)]}},{key:"footer",fn:function(){return [_c('v-row',{staticClass:"mt-2",attrs:{"align":"center","justify":"center"}},[_c('v-spacer'),_c('span',{staticClass:"grey--text"},[_vm._v("Items per page")]),_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"ml-2",attrs:{"dark":"","text":"","color":"primary"}},on),[_vm._v(" "+_vm._s(_vm.itemsPerPage)+" "),_c('v-icon',[_vm._v("mdi-chevron-down")])],1)]}}])},[_c('v-list',_vm._l((_vm.itemsPerPageArray),function(number,index){return _c('v-list-item',{key:index,on:{"click":function($event){return _vm.updateItemsPerPage(number)}}},[_c('v-list-item-title',[_vm._v(_vm._s(number))])],1)}),1)],1),_c('span',{staticClass:"mr-5 grey--text"},[_vm._v("Page "+_vm._s(_vm.page)+" of "+_vm._s(_vm.numberOfPages))]),_c('v-btn',{staticClass:"mr-1",attrs:{"text":"","color":"warning"},on:{"click":_vm.formerPage}},[_vm._v(" Previous ")]),_c('v-btn',{staticClass:"ml-1",attrs:{"text":"","color":"blue darken-3"},on:{"click":_vm.nextPage}},[_vm._v(" Next page ")])],1)]},proxy:true}],null,true)}),_c('v-dialog',{attrs:{"overlay-opacity":"0.8","width":"80%","scrollable":""},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',{attrs:{"flat":"","tile":""}},[_c('v-card-title',[_vm._v(" "+_vm._s(_vm.image.title || "")+" "),_c('v-spacer'),_c('v-btn',{staticClass:"mr-10",attrs:{"icon":"","light":""},on:{"click":function($event){_vm.dialog = false}}},[_c('v-icon',[_vm._v("close")])],1)],1),_c('v-card-text',[_c('v-img',{staticClass:"white--text align-end",attrs:{"src":("https://develop.hokela.co.ke/api/images?uri=" + _vm.image),"contain":"","height":"80vh"}},[_c('v-card-title',{staticClass:"title"},[_vm._v(_vm._s(_vm.image.title || ""))])],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }