<template>
  <div>
    <v-snackbar
        v-model="snackbar"
        :timeout="2000"
        absolute
        :color="snackColor"
    >{{ snackMessage }}
    </v-snackbar>
    <v-dialog v-model="dialog" max-width="500px" class="d-inline pa-2" scrollable>
      <template v-slot:activator="{ on }">
        <v-btn text class="subtitle-1" v-on="on">Edit</v-btn>
      </template>
      <v-card>
        <v-card-title>
          <span class="headline">Editing {{ event.name }}</span>
        </v-card-title>
        <v-card-text>

          <v-alert
              :value="alert"
              dismissible
              text
              :type="alertType"
          >
            {{ alertMessage }}
          </v-alert>
          <v-alert
              :value="deleteAlert"
              text
              type="warning"
          >
            <v-row align="center">
              <v-col class="grow">This will delete all reports and remove all users
              </v-col>
              <v-col class="shrink">
                <v-btn @click="handleDelete">Confirm</v-btn>
              </v-col>
            </v-row>
          </v-alert>

          <v-text-field
              prepend-inner-icon="font_download"
              outlined
              v-model="event.name"
              label="Event name"
              required></v-text-field>

          <v-menu
              ref="menu"
              v-model="menu"
              :close-on-content-click="false"
              :return-value.sync="event.date_starting"
              transition="scale-transition"
              offset-y
              min-width="290px"
          >
            <template v-slot:activator="{ on }">
              <v-text-field
                  v-model="event.date_starting"
                  label="Starting date"
                  outlined
                  prepend-inner-icon="event"
                  readonly
                  v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker v-model="event.date_starting" no-title scrollable>
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="menu = false">Cancel</v-btn>
              <v-btn text color="primary" @click="$refs.menu.save(event.date_starting)">
                OK
              </v-btn>
            </v-date-picker>
          </v-menu>

          <!-- ending date -->
          <v-menu
              ref="menu2"
              v-model="menu2"
              :close-on-content-click="false"
              :return-value.sync="event.date_ending"
              transition="scale-transition"
              offset-y
              min-width="290px"
          >
            <template v-slot:activator="{ on }">
              <v-text-field
                  v-model="event.date_ending"
                  label="Ending date"
                  prepend-inner-icon="event"
                  outlined
                  readonly
                  v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker v-model="event.date_ending" no-title scrollable>
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="menu = false">Cancel</v-btn>
              <v-btn text color="primary" @click="$refs.menu2.save(event.date_ending)">
                OK
              </v-btn>
            </v-date-picker>
          </v-menu>

          <!--   checkin time-->
          <v-menu
              ref="checkinMenu"
              v-model="checkinMenu"
              :close-on-content-click="false"
              :nudge-right="40"
              :return-value.sync="event.checkin_time"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="290px"
          >
            <template v-slot:activator="{ on }">
              <v-text-field
                  v-model="event.checkin_time"
                  label="Expected check-in time"
                  prepend-inner-icon="access_time"
                  outlined
                  readonly
                  v-on="on"
              ></v-text-field>
            </template>
            <v-time-picker
                v-if="checkinMenu"
                v-model="event.checkin_time"
                full-width
                format="24hr"
                @click:minute="$refs.checkinMenu.save(event.checkin_time)"
            ></v-time-picker>
          </v-menu>

          <!--   checkout time-->
          <v-menu
              ref="checkoutMenu"
              v-model="checkoutMenu"
              :close-on-content-click="false"
              :nudge-right="40"
              :return-value.sync="event.checkout_time"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="290px"
          >
            <template v-slot:activator="{ on }">
              <v-text-field
                  v-model="event.checkout_time"
                  label="Expected check-out time"
                  prepend-inner-icon="access_time"
                  outlined
                  readonly
                  v-on="on"
              ></v-text-field>
            </template>
            <v-time-picker
                v-if="checkoutMenu"
                v-model="event.checkout_time"
                full-width
                format="24hr"
                @click:minute="$refs.checkoutMenu.save(event.checkout_time)"
            ></v-time-picker>
          </v-menu>

          <v-checkbox
              v-if="event.type !== 1"
              v-model="event.geofence"
              label="Geo Fence"
              color="primary"
              hide-details
          ></v-checkbox>

          <v-checkbox
              v-model="event.ba_image_checkin"
              label="Require BA checkin image (TL is mandatory)"
              color="primary"
              hide-details
          ></v-checkbox>

          <v-switch
              flat
              :loading="joining"
              label="Campaign joining code"
              dense
              inset
              v-model="canJoinViaCode">
          </v-switch>


          <v-card class="yellow lighten-5" flat v-if="canJoinViaCode && joiningCode !==null">
            <v-card-text>
              <span class="title">{{ joiningCode }}</span>
              <v-btn icon @click="copyLink">
                <v-icon class="ml-5">content_copy</v-icon>
              </v-btn>
            </v-card-text>

          </v-card>

        </v-card-text>
        <v-card-actions>

          <v-btn icon color="red" @click="handleDelete">
            <v-icon>delete</v-icon>
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="dialog = false">Close</v-btn>
          <v-btn color="blue darken-1" text :loading="loading" @click="handleSubmit">Save</v-btn>
        </v-card-actions>

      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import axios from "axios";
import ApiService from "@/services/ApiService";

export default {
  props: {
    event: Object
  },
  data() {
    return {
      alertType: "info",
      dialog: false,
      alert: false,
      deleteAlert: false,
      loading: false,
      snackMessage: '',
      snackColor: 'primary',
      message: '',
      checkinMenu: false,
      checkoutMenu: false,
      menu: false,
      menu2: false,
      joining: false,
      canJoinViaCode: null,
      joiningCode: null
    }
  },
  mounted() {
    if (this.event.can_join_with_code === true) {
      this.canJoinViaCode = true
      this.joiningCode = this.event.joining_code
    }
  },
  methods: {
    copyLink() {
      this.$copyText(this.joiningCode)
          .then(e => {
            this.snackMessage = 'Code copied to clipboard'
            this.snackColor = 'primary'
            this.snackbar = true
          }).catch(error => {
        this.snackMessage = 'Unable to copy code. Try again later'
        this.snackColor = 'orange'
        this.snackColor = true
      })
    },
    handleSubmit() {
      this.loading = true;

      ApiService.updateEvent(this.event)
          .then((response) => {
            this.loading = false
            console.log(response)
            if (response.status === 200) {
              this.snackMessage = 'Update success.. Refreshing the page';
              this.snackbar = true;
              this.$emit('fetchEvents')
              this.dialog = false;
            }
          })
          .catch((error) => {
            this.loading = false
            if (error.status === 401) {
              this.dialog = false;
              this.snackMessage = 'Unable to find the event. Might have been deleted or moved';
              this.snackbar = true;

            }
            console.log(error);
          });
    },
    handleDelete() {
      this.deleteAlert = false;
      this.loading = true;
      this.message = 'Deleting... This might take some time be patient.';

     ApiService.deleteEvent(this.event.id)
          .then((response) => {
            if (response.status === 200) {
              this.dialog = false;
              this.snackMessage = 'Event deleted success';
              this.$emit('fetchEvents')
              this.snackbar = true;
            }
          })
          .catch((error) => {
            if (error.status === 401) {
              this.dialog = false;
              this.snackMessage = 'Unable to find the event. Might have been deleted or moved';
              this.snackbar = true;

            }
            console.log(error);
          });
    },

    handleJoiningCode(val) {
      this.joining = true
      ApiService.handleJoiningCode(val, this.event.id)
          .then((response) => {

            this.joining = false
            // A joining code was created by the user
            if (response.status === 201) {
              this.canJoinViaCode = response.data.data.can_join_with_code
              this.joiningCode = response.data.data.joining_code
            } else

                // A code was deleted by the user
            if (response.status === 200) {
              this.canJoinViaCode = false
              this.joiningCode = null
              this.snackMessage = response.data.message
              this.snackColor = 'red'
              this.snackbar = true
            }
          })
          .catch((error) => {
            this.joining = false
            console.log(error);
          });
    }
  },
  watch: {

    canJoinViaCode(val) {
      if (val !== this.event.can_join_with_code) {
        this.handleJoiningCode(val)
      }
    }
  }
}
</script>
