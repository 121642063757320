<template>
  <VChart :option="option" :style="{ height: '400px' }" />
</template>


<script>
import { use } from "echarts/core";
import { CanvasRenderer } from "echarts/renderers";
import { BarChart } from "echarts/charts";
import {
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  GridComponent,
} from "echarts/components";
import VChart, { THEME_KEY } from "vue-echarts";

use([
  CanvasRenderer,
  BarChart,
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  GridComponent,
]);

export default {
  components: {
    VChart,
  },
  props: {
    labels: Array,
    charts: Array,
    title: String,
    seriesName: {
      type: String,
      default: "",
    },
    axisLabelInterval: {
      default: null,
    },
    axisLabelRotate: {
      default: 0,
    },
  },
  provide: {
    [THEME_KEY]: "light",
  },
  data() {
    return {
      option: {},
    };
    /*
    return {
      options: {
        scales: {
          yAxes: [{
            ticks: {
              beginAtZero: true
            },
            gridLines: {
              display: true
            }
          }],
          xAxes: [{
            gridLines: {
              display: false
            }
          }]
        },
        legend: {
          display: true
        },
        responsive: true,
        maintainAspectRatio: false
      },
      chartData: {
        labels: this.labels,
        datasets: [{
          label: this.title,
          borderWidth: 1,
          borderColor: [
            'rgba(255, 99, 132, 0.2)',
            'rgba(54, 162, 235, 0.2)',
            'rgba(255, 206, 86, 0.2)',
            'rgba(75, 192, 192, 0.2)',
            'rgba(153, 102, 255, 0.2)',
            'rgba(255, 159, 64, 0.2)',
            'rgba(255, 99, 132, 0.2)',
            'rgba(54, 162, 235, 0.2)',
            'rgba(255, 206, 86, 0.2)',
            'rgba(75, 192, 192, 0.2)',
            'rgba(153, 102, 255, 0.2)',
            'rgba(255, 159, 64, 0.2)'
          ],
          backgroundColor: [
            'rgba(255,99,132,1)',
            'rgba(54, 162, 235, 1)',
            'rgba(255, 206, 86, 1)',
            'rgba(75, 192, 192, 1)',
            'rgba(153, 102, 255, 1)',
            'rgba(255, 159, 64, 1)',
            'rgba(255,99,132,1)',
            'rgba(54, 162, 235, 1)',
            'rgba(255, 206, 86, 1)',
            'rgba(75, 192, 192, 1)',
            'rgba(153, 102, 255, 1)',
            'rgba(255, 159, 64, 1)'
          ],
          pointBorderColor: '#2554FF',
          data: this.charts
        }]
      },
    };
    */
  },
  methods: {
    loadMap() {
      /*
      this.renderChart(this.chartData, this.options);
      */
    },

    downloadChart() {
      const canvas = this.$refs.canvas;
      const image = canvas.toDataURL("image/jpeg", 1.0);
      let link = document.createElement("a");
      link.download = this.title;
      link.href = image;
      link.click();
    },

    setOption() {
      this.option = {
        title: { text: this.title },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
        },
        scales: {
          yAxes: [
            {
              ticks: {
                beginAtZero: true,
              },
              gridLines: {
                display: true,
              },
            },
          ],
          xAxes: [
            {
              gridLines: {
                display: false,
              },
            },
          ],
        },
        legend: {
          display: true,
        },
        responsive: true,
        maintainAspectRatio: false,
        xAxis: {
          type: "category",
          data: this.labels,
          axisLabel: {
            interval: this.axisLabelInterval,
            rotate: this.axisLabelRotate, //If the label names are too long you can manage this by rotating the label.
          },
          //boundaryGap: [0, 0.01],
        },
        yAxis: {
          type: "value",
        },
        series: [
          {
            data: this.charts,
            type: "bar",
            name: this.seriesName,
          },
        ],
      };
    },
  },
  mounted() {
    this.loadMap();
    this.setOption();
  },
  watch: {
    charts: function () {
      this.loadMap();
      this.setOption();
    },
    labels: function () {
      this.loadMap();
    },
  },
};
</script>
