<template>
    <div>
        <v-card :height="$vuetify.breakpoint.lgAndUp ? '100vh' : '100%'" v-if="!this.$vuetify.breakpoint.xs"
                color="transparent" flat>
            <v-img :src="require('@/assets/ic_logo.svg')" v-if="!this.$vuetify.breakpoint.xs" max-height="100"
                   max-width="100"
                   style="position: absolute; z-index: 9;" class="ml-sm-10"></v-img>
            <v-img :src="require('@/assets/laptop.jpg')" :height="$vuetify.breakpoint.lgAndUp ? '100vh' : '50vh'"
                   v-if="!this.$vuetify.breakpoint.xs">
                <v-card :height="$vuetify.breakpoint.lgAndUp ? '100vh' : '100%'" color="transparent"
                        class="d-flex align-center" flat>
                    <v-card flat :width="$vuetify.breakpoint.xl ? '550' : '450'"
                            class="ml-md-7 mt-md-13 ml-xl-10 text-left">
                        <v-card-text style="color: #0165e4;" class="font-weight-medium">
                            <div v-for="(item, i) in about1" :key="i" class="mb-sm-4">{{item.text}}</div>
                        </v-card-text>
                    </v-card>
                </v-card>
            </v-img>
        </v-card>

        <!--   xs screens    -->
        <v-card height="100vh" v-if="this.$vuetify.breakpoint.xs" flat>
            <v-img :src="require('@/assets/mobileimages/laptop.jpg')" height="100%">
                <v-card color="transparent" flat class="mt-12">
                    <v-card flat max-width="300" class="text-left ma-auto">
                        <v-card-text style="color: #0165e4;">
                            <h5 v-for="(item, i) in about1" :key="i">{{item.text}}</h5>
                        </v-card-text>
                    </v-card>
                </v-card>
            </v-img>
        </v-card>

        <v-card :height="$vuetify.breakpoint.lgAndUp ? '100vh' : '50vh'" v-if="!this.$vuetify.breakpoint.xs" flat>
            <v-img :src="require('@/assets/ic_logo.svg')" v-if="!this.$vuetify.breakpoint.xs" max-height="100"
                   max-width="100"
                   style="position: absolute; z-index: 9;" class="ml-sm-10"></v-img>
            <v-img :src="require('@/assets/about.jpg')" :height="$vuetify.breakpoint.lgAndUp ? '100vh' : '50vh'"
                   v-if="!this.$vuetify.breakpoint.xs">
                <v-card :height="$vuetify.breakpoint.lgAndUp ? '100vh' : '100%'" color="transparent"
                        class="d-flex align-sm-center" flat>
                    <v-card flat :max-width="$vuetify.breakpoint.lg ? '450' : $vuetify.breakpoint.xl? '600' : '380'"
                            color="transparent" class="ml-lg-6 ml-xl-12 text-left" dark>
                        <v-card-text class="font-weight-bold ml-xl-10">
                            <div class="mb-lg-2" v-for="(item, i) in about2" :key="i" style="color: white;">
                                {{item.text}}
                            </div>
                        </v-card-text>
                    </v-card>
                    <v-spacer></v-spacer>
                    <v-card :width="$vuetify.breakpoint.lg ? '500' : $vuetify.breakpoint.xl ? '600' : '400'"
                            class="mt-auto mb-sm-12 mr-sm-12" color="transparent" flat>
                        <v-card-title class="title font-weight-bold">
                            <v-spacer></v-spacer>
                            <div style="color: white;">We help you :</div>
                            <v-spacer></v-spacer>
                        </v-card-title>
                        <v-card-text class="d-flex ml-xl-12">
                            <v-btn text large dark rounded>
                                <div class="font-weight-bold">Track</div>
                            </v-btn>
                            <v-btn text large dark rounded>
                                <div class="font-weight-bold">Report</div>
                            </v-btn>
                            <v-btn text large dark rounded>
                                <div class="font-weight-bold">Analyze</div>
                            </v-btn>
                            <v-btn text large dark rounded>
                                <div class="font-weight-bold">Decide</div>
                            </v-btn>
                        </v-card-text>
                    </v-card>
                    <v-spacer></v-spacer>
                </v-card>
            </v-img>
        </v-card>

        <!--   xs screens    -->
        <v-card height="100vh" v-if="this.$vuetify.breakpoint.xs" flat>
            <v-img :src="require('@/assets/mobileimages/about.jpg')" height="100%">
                <v-card color="transparent" flat class="mt-12">
                    <v-card flat max-width="350" class="text-left ma-auto">
                        <v-card-text style="color: #0165e4;">
                            <h5 v-for="(item, i) in about2" :key="i">{{item.text}}</h5>
                        </v-card-text>
                    </v-card>
                </v-card>
                <v-spacer></v-spacer>
                <v-card class="d-flex flex-column mt-12" color="transparent" flat>
                    <v-spacer></v-spacer>
                    <div class="mb-12"></div>
                    <v-card width="100%" class="mt-12" color="transparent" flat>
                        <v-card-title class="title font-weight-bold mt-12">
                            <v-spacer></v-spacer>
                            <div style="color: white;" class="mt-8">We help you :</div>
                            <v-spacer></v-spacer>
                        </v-card-title>
                        <v-card-text class="d-flex align-center justify-center">
                            <v-btn text x-small dark rounded>
                                <div class="font-weight-bold">Track</div>
                            </v-btn>
                            <v-btn text x-small dark rounded>
                                <div class="font-weight-bold">Report</div>
                            </v-btn>
                            <v-btn text x-small dark rounded>
                                <div class="font-weight-bold">Analyze</div>
                            </v-btn>
                            <v-btn text x-small dark rounded>
                                <div class="font-weight-bold">Decide</div>
                            </v-btn>
                        </v-card-text>
                    </v-card>
                </v-card>
            </v-img>
        </v-card>
    </div>
</template>

<script>
    export default {
        name: 'About',
        data() {
            return {
                about1: [
                    {text: "Our consulting services aim to grow your business in this changing global market."},
                    {text: "At Hokela, we understand how critical Data is in today's world and how to use it to make better decisions for your business."},
                    {text: "We are a Data Science company that provides innovative software solutions for startups and enterprises."},
                ],
                about2: [
                    {text: "Hokela Interactive is an all-in-one marketing solution for field/remote teams and their managers."},
                    {text: "We aid businesses to oversee your field business activities in real time."},
                    {text: "Through our unique Data Automation and Decision Intelligence platform that is simple, intuitive and smart, aimed at automating data-driven decision-making through our Machine Learning functionality."},
                    {text: "We will help you digitize your reporting, sales teams management as well as place your product to the supplier using Smart routes."}
                ]
            }
        },
    }
</script>

