<template>
  <v-sheet color="#FAFAFA" height="100vh">
    <v-img :src="require('@/assets/ic_logo_blue.svg')" class="ml-5" max-height="100px"
           max-width="100px"></v-img>
    <v-card flat tile>
      <v-card-title>
        Set password to complete registration to Hokela
      </v-card-title>
      <v-flex offset-md-3 xs6>
        <v-text-field
            autocomplete="current-password"
            :value="userPassword"
            label="Enter password"
            hint="Your password passed! Password rules are not meant to be broken!"
            :append-icon="!value ? 'mdi-eye' : 'mdi-eye-off'"
            @click:append="() => (value = !value)"
            :type="value ? 'password' : 'text'"
            :rules="[rules.password]"
            @input="_=>userPassword=_"
        ></v-text-field>
        <v-text-field
            label="Confirm password"
            :type="confirmation ? 'password' : 'text'"
            @click:append="() => (confirmation = !confirmation)"
            :append-icon="value ? 'mdi-eye' : 'mdi-eye-off'"
            @input="_=>confirmPassword=_"
        >
        </v-text-field>
      </v-flex>
      <v-card-actions class="center">
        <v-btn @click="handleSubmit">Complete Registration</v-btn>
      </v-card-actions>
    </v-card>
  </v-sheet>
</template>

<script>
import axios from 'axios';

export default {
  data: () => ({
    userPassword: '',
    confirmPassword: '',
    confirmation: true,
    valid: true,
    value: true,
    token: '',
    rules: {
      required: value => !!value || 'Required.',
      password: value => {
        const pattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/;
        return (
            pattern.test(value) ||
            'Min. 8 characters with at least one capital letter, a number and a special character.'
        );
      },
    }
  }),
  methods: {
    handleSubmit() {
      let data = {
        password: this.userPassword,
        password_confirmation: this.confirmPassword,
        token: this.token
      };
      axios.post('https://develop.hokela.co.ke/api/register', data)
          .then(response => {
            this.$router.push({name: 'login'});
          })
          .catch(error => console.log('registration error', error));
    },
    getUser() {
      let token = this.$route.params.token;
      this.token = token;
      if (token) {
        axios.get(`/register/${token}`)
            .then(response => {

              console.log('getUser res', response);
            })
            .catch(error => console.log('error', error));
      }
    }
  },
  mounted() {
    this.getUser();
  }
};
</script>
