<template>
  <div>
    <snackbar :snackbar="snackbar" :color="snackColor" :text="snackMessage"/>
    <v-form>
      <v-text-field prepend-inner-icon="text_fields" class="mt-3" outlined v-model="event_name" label="Name"
                    required></v-text-field>
      <v-menu
          ref="menu"
          v-model="menu"
          :close-on-content-click="false"
          :return-value.sync="starting_date"
          transition="scale-transition"
          offset-y
          min-width="290px"
      >
        <template v-slot:activator="{ on }">
          <v-text-field
              v-model="starting_date"
              outlined
              label="Starting date"
              prepend-inner-icon="event"
              readonly
              v-on="on"
          ></v-text-field>
        </template>

        <v-date-picker v-model="starting_date" no-title scrollable>
          <v-spacer></v-spacer>
          <v-btn text color="primary" @click="menu = false">Cancel</v-btn>
          <v-btn text color="primary" @click="$refs.menu.save(starting_date)">
            OK
          </v-btn>
        </v-date-picker>
      </v-menu>

      <!--   ending date -->
      <v-menu
          ref="menu2"
          v-model="menu2"
          :close-on-content-click="false"
          :return-value.sync="ending_date"
          transition="scale-transition"
          offset-y
          min-width="290px"
      >
        <template v-slot:activator="{ on }">
          <v-text-field
              v-model="ending_date"
              outlined
              label="Ending date"
              prepend-inner-icon="event"
              readonly
              v-on="on"
          ></v-text-field>
        </template>
        <v-date-picker v-model="ending_date" no-title scrollable>
          <v-spacer></v-spacer>
          <v-btn text color="primary" @click="menu = false">Cancel</v-btn>
          <v-btn text color="primary" @click="$refs.menu2.save(ending_date)">
            OK
          </v-btn>
        </v-date-picker>
      </v-menu>

      <!--   checkin time-->
      <v-menu
          ref="checkinMenu"
          v-model="checkinMenu"
          :close-on-content-click="false"
          :nudge-right="40"
          :return-value.sync="checkin_time"
          transition="scale-transition"
          offset-y
          max-width="290px"
          min-width="290px"
      >
        <template v-slot:activator="{ on }">
          <v-text-field
              v-model="checkin_time"
              outlined
              label="Expected check-in time"
              prepend-inner-icon="access_time"
              readonly
              v-on="on"
          ></v-text-field>
        </template>
        <v-time-picker
            v-if="checkinMenu"
            v-model="checkin_time"
            full-width
            format="24hr"
            @click:minute="$refs.checkinMenu.save(checkin_time)"
        ></v-time-picker>
      </v-menu>

      <!--   checkout time-->
      <v-menu
          ref="checkoutMenu"
          v-model="checkoutMenu"
          :close-on-content-click="false"
          :nudge-right="40"
          :return-value.sync="checkout_time"
          transition="scale-transition"
          offset-y
          max-width="290px"
          min-width="290px"
      >
        <template v-slot:activator="{ on }">
          <v-text-field
              v-model="checkout_time"
              outlined
              label="Expected check-out time"
              prepend-inner-icon="access_time"
              readonly
              v-on="on"
          ></v-text-field>
        </template>
        <v-time-picker
            v-if="checkoutMenu"
            v-model="checkout_time"
            full-width
            format="24hr"
            @click:minute="$refs.checkoutMenu.save(checkout_time)"
        ></v-time-picker>
      </v-menu>

      <v-switch v-model="geo_fence" label="GEO FENCE TO LOCATION"></v-switch>

      <div v-if="geo_fence">

        <!--  Recces locations  -->
        <v-card flat outlined>
          <div class="grey--text"> Geo fence will restrict user checkin to the set location</div>
          <v-card-text>
            <div class="my-4">
              <v-autocomplete
                  v-model="location"
                  :items="locationSearches"
                  :search-input.sync="locations"
                  chips
                  clearable
                  hide-details
                  hide-selected
                  type="text"
                  item-text="lat"
                  item-value="name"
                  label="Search location by name"
                  return-object
                  outlined
              >
                <template v-slot:no-data>
                  <v-list-item>
                    <v-list-item-title>
                      Start typing
                      <strong>location name</strong>
                    </v-list-item-title>
                  </v-list-item>
                </template>
                <template v-slot:selection="{ attr, on, item, selected }">
                  <v-chip
                      v-bind="attr"
                      :input-value="selected"
                      color="blue-grey"
                      class="white--text"
                      v-on="on"
                  >
                    <v-icon left>location_on</v-icon>

                    <span v-text="item.name"></span>
                  </v-chip>
                </template>
                <template v-slot:item="{ item }">
                  <v-list-item-avatar
                      color="indigo"
                      class="headline font-weight-light white--text"
                  >
                    {{ item.name.charAt(0) }}
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title v-text="item.name"></v-list-item-title>
                    <v-list-item-subtitle
                        v-text="item.lat+' ,'+item.lng"></v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-action>
                    <v-icon>location_on</v-icon>
                  </v-list-item-action>
                </template>
              </v-autocomplete>
            </div>
          </v-card-text>
        </v-card>
      </div>
    </v-form>
    <v-divider></v-divider>

    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="primary" block @click="createEvent()" :loading="loading">Create Event</v-btn>
    </v-card-actions>
  </div>
</template>
<script>
import axios from "axios";

export default {
  data() {
    return {
      event_name: '',
      starting_date: new Date().toISOString()
          .substr(0, 10),
      menu: false,
      menu2: false,
      ending_date: new Date().toISOString()
          .substr(0, 10),
      checkin_time: null,
      checkinMenu: false,
      checkout_time: null,
      checkoutMenu: false,
      location: '',
      locationSearches: null,
      locations: null,
      geo_fence: false,
      open_plan: false,
      ba_image_checkin: false,
      snackbar: false,
      snackMessage: '',
      snackColor: 'primary',
      loading: false
    }
  },
  methods: {
    queryLocations(val) {
      this.isLoading = true;

      // get event
      axios.get(`/search/location?query=${val}`)
          .then((response) => {
            this.locationSearches = response.data;
          })
          .catch((error) => {
            console.log(error);
          })
          .finally(() => (this.isLoading = false));
    },
    createEvent() {
      this.loading = true
      axios.defaults.headers.common.Authorization = `Bearer ${this.$store.state.token}`;
      axios.post('/events', this.formatRequestData())
          .then(response => {

            //redirect to the created project
            if (response.status === 201) {
              this.loading = false
              this.snackMessage = response.data.data.name + ' created successfully. Switch tabs to view it';
              this.snackbar = true;
              this.$emit('updateEvents')
            }
          })
          .catch(error => {
            console.log(error);
            this.loading = false
            this.snackMessage = ' Error occurred creating the event';
            this.color = 'red'
            this.snackbar = true;
          });
    },

    formatRequestData() {
      return {
        'name': this.event_name,
        'date_starting': this.starting_date,
        'date_ending': this.ending_date,
        'checkin_time': this.checkin_time,
        'checkout_time': this.checkout_time,
        'address_address': this.location,
        'phone': this.phone,
        'project_id': this.$route.params.id,
        'geo_fence': this.geo_fence,
        'event_type': 0,
        'open_plan': this.open_plan,
        'ba_image_checkin': this.ba_image_checkin,
        'location': this.location.id
      };
    },
  },
  watch: {
    locations(val) {
      val && val !== this.select && this.queryLocations(val);
    },
  }
}
</script>
