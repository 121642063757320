<template>
  <v-dialog v-model="dialog" width="600" scrollable :persistent="true">

    <template v-slot:activator="{ on, attrs }">
      <v-btn v-if="icon"
             color="primary"
             icon outlined
             v-bind="attrs"
             v-on="on"
             width="105"
             height="105"
      >
        <v-icon size="100">add</v-icon>
      </v-btn>

      <v-btn v-if="!icon && row==='1'"
             text
             v-bind="attrs"
             v-on="on"
      >
        New RSM
      </v-btn>

      <v-btn v-if="!icon && row==='0'"
             text
             v-bind="attrs"
             v-on="on"
      >
        New Activation
      </v-btn>

      <v-btn v-if="!icon && row==='2'"
             text
             v-bind="attrs"
             v-on="on"
      >
        New Event
      </v-btn>
    </template>

    <snackbar v-model="snackbar" :text="snackMessage" :color="color"></snackbar>
    <v-card>
      <v-card-title class="heading grey lighten-2" primary-title>
        <div v-if="row==='0'">Create new Activation</div>
        <div v-else-if="row==='1'">Create new RSM</div>
        <div v-else-if="row==='2'">Create new Event</div>

        <v-spacer></v-spacer>
        <v-btn icon @click="dialog=false">
          <v-icon>
            close
          </v-icon>
        </v-btn>
      </v-card-title>

      <v-card-subtitle class="caption grey lighten-2 text-left" primary-title>
        <div v-if="row==='0'">
          Creates an event that runs long term in one or more locations. Most common type of event
        </div>
        <div v-else-if="row==='1'">Allows you to undertake tracking & data collection on route basis. Suited for Retail,
          Sales
          & recces
        </div>
        <div v-else-if="row==='2'">
          Short, one time, single location events that run from a few hours to a single weekend.
        </div>
      </v-card-subtitle>

      <v-card-text>
        <div v-if="row ==='0'">
          <activation @updateEvents="closeDialog"/>
        </div>

        <div v-if="row ==='1'">
          <r-s-m @updateEvents="closeDialog"/>
        </div>

        <div v-if="row ==='2'">
          <event @updateEvents="closeDialog"/>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from "axios";
import Activation from "@/components/modals/events/Activation";
import RSM from "@/components/modals/events/RSM";
import Event from "@/components/modals/events/Event";

export default {
  components: {Event, RSM, Activation},
  props: {
    row: {
      type: String,
      default: '0',
    },
    icon: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      // row: '0',
      dialog: false,
      event_name: '',
      starting_date: new Date().toISOString()
          .substr(0, 10),
      menu: false,
      menu2: false,
      ending_date: new Date().toISOString()
          .substr(0, 10),
      checkin_time: null,
      checkinMenu: false,
      checkout_time: null,
      checkoutMenu: false,
      locationName: '',
      locationSearches: null,
      locations: null,
      geo_fence: false,
      open_plan: false,
      ba_image_checkin: false,
      journey_plan: [],
      journey_name: '',
      phone: '',
      isLoading: false,
      loading: false,
      snackbar: false,
      snackMessage: null,
      color: 'primary',
      model: null,
      search: null,
      items: [],
    }
  },
  methods: {
    closeDialog() {
      this.$emit('closeEvents')
      this.dialog = false
    },
    querySelections(val) {
      this.isLoading = true;

      // get event
      axios.get(`/search/users?query=${val}`)
          .then((response) => {
            this.items = response.data;
          })
          .catch((error) => {
            console.log(error);
          })
          .finally(() => (this.isLoading = false));
    },
    queryLocations(val) {
      this.isLoading = true;

      // get event
      axios.get(`/search/location?query=${val}`)
          .then((response) => {
            this.locationSearches = response.data;
          })
          .catch((error) => {
            console.log(error);
          })
          .finally(() => (this.isLoading = false));
    },

    addLocation() {
      if (!this.locationName) {
        this.snackbar = true;
        this.snackMessage = 'Location cannot be empty'
        return
      }

      let location = {
        id: this.locationName.id,
        name: this.locationName.name,
        start_date: this.date,
        frequency: this.frequency,
      }
      this.journey_plan.push(location);
      this.locationName = null;
    },

    deleteJourney(index) {
      this.journey_plan.splice(index, 1);
    },

    createEvent() {
      this.loading = true
      axios.post('/events', this.formatRequestData())
          .then(response => {

            //redirect to the created project
            if (response.status === 201) {
              this.loading = false;
              this.snackMessage = response.data.data.name + ' created successfully. Switch tabs to view it';
              this.snackbar = true;
              this.$emit('updateEvents');
            }
          })
          .catch(error => {
            console.log(error);
            this.loading = false;
            this.snackMessage = ' Error occurred creating the event';
            this.color = 'red';
            this.snackbar = true;
          });
    },

    formatRequestData() {
      return {
        'name': this.event_name,
        'date_starting': this.starting_date,
        'date_ending': this.ending_date,
        'checkin_time': this.checkin_time,
        'checkout_time': this.checkout_time,
        'address_address': this.location,
        'phone': this.phone,
        'project_id': this.$route.params.id,
        'geo_fence': this.geo_fence,
        'event_type': this.row,
        'open_plan': this.open_plan,
        'ba_image_checkin': this.ba_image_checkin,
        'journey_plan': this.journey_plan
      };
    },
  },
  watch: {
    locations(val) {
      val && val !== this.select && this.queryLocations(val);
    },
    search(val) {
      val && this.querySelections(val);
    },
  }
}
</script>
