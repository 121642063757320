<template>
  <v-card flat tile height="100%" color="#FAFAFA">
    <v-toolbar dark color="primary">
      <v-toolbar-title>
        <div>Hokela</div>
      </v-toolbar-title>
      <v-spacer></v-spacer>
    </v-toolbar>
    <v-card-text>
      <v-row>
        <v-col cols="3">
          <v-card class="text-left" flat height="90vh">
            <v-card-title class="align-center">
              <v-spacer></v-spacer>
              <v-avatar height="100" width="100">
                <v-img src="https://picsum.photos/510/300?random"></v-img>
              </v-avatar>
              <v-spacer></v-spacer>
            </v-card-title>

            <v-card-text>
              <div class="heading">{{ user.name }}</div>
              <div class="caption">Joined: {{ user.created_at }}</div>
              <v-divider></v-divider>
              <v-list disabled>

                <v-list-item-group color="primary">
                  <v-list-item>
                    <v-list-item-icon>
                      <v-icon>payment</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title v-text="user.national_id"></v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>

                  <v-list-item>
                    <v-list-item-icon>
                      <v-icon>person_pin_circle</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title v-text="user.county"></v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>

                  <v-list-item>
                    <v-list-item-icon>
                      <v-icon>phone</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title v-text="user.phone"></v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>

                  <v-list-item>
                    <v-list-item-icon>
                      <v-icon>star_half</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title v-text="user.rating"></v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
              <v-btn :loading="isLoading" rounded dark :color="user.active===1?'red':'green'" block>
                {{ user.active === 1 ? 'Suspend user' : 'Activate user' }}
              </v-btn>
            </v-card-actions>

          </v-card>
        </v-col>
        <v-col cols="6">
          <v-card flat height="90vh">
            <v-card-text>
              <activities :activities="activities"/>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="3">
          <v-card flat height="90vh">
            <v-card-title>Recent Activity</v-card-title>
            <v-divider></v-divider>
            <v-card-text>
              <activity-list :activity="checkins"/>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>
<script>
import Activities from "@/components/users/Activities";
import ActivityList from "@/components/users/ActivityList";
import axios from "axios";

export default {
  components: {
    Activities,
    ActivityList
  },
  data() {
    return {
      isLoading: false,
      user: {
        name: 'John Does',
        gender: 1,
        national_id: 494839303,
        county: 'Nairobi',
        created_at: '28th Sept 2020',
        rating: 2.8,
        activities: {
          rsm: [],
          recce: [],
          activation: [],
          events: [],
        },
        checkins: [],
      }
    }
  },
  computed: {
    activities() {
      let activation = [];
      this.user.events.forEach(element =>
          activation.push({
            title: element.event.name,
            subtitle: `Role:${this.getUserType(element.user_type)} <span>&#9864;</span> Added:${element.created_at} <span>&#9864;</span> Type:${this.getEventType(element.event.type)}`
          })
      )
      let rsm = [];
      this.user.merch.forEach(item =>
          rsm.push({
            title: item.name,
            subtitle: `Project:${item.project !== null ? item.project.name : ''}`
          })
      )

      let reports = [];

      this.user.submissions.forEach(item => {
            reports.push({
              title: item.report !== null ? item.report.name : '*Deleted report*',
              subtitle: item.created_at
            })
          }
      )
      return {
        'events': activation,
        'rsm': rsm,
        'reports': reports
      }
    },
    checkins(){

      return{

      }
    }
  },
  methods: {
    getUserType(user_type) {
      if (user_type === 1) {
        return 'T.L'
      } else return 'B.A'
    },
    getEventType(event_type) {
      switch (event_type) {
        case 0:
          return 'Activation'
        case 1:
          return 'Recce'
        case 2:
          return 'Event'
      }
    },
    suspendUser() {
      // axios.defaults.baseURL = 'https://develop.hokela.co.ke/api';
      // axios.defaults.headers.common.Authorization = `Bearer ${this.$store.state.token}`;
      // axios.get(`/admin/hokela/${this.$route.params.id}`)
      //     .then((response) => {
      //       this.response = response.data.data;
      //     })
      //     .catch((error) => {
      //       console.log(error);
      //     });
    },
    getGender(gender) {
      if (gender === 1) {
        return 'Male'
      } else {
        return 'Female'
      }
    },
    getPageInfo() {
      axios.get(`/hokela/${this.$route.params.id}`)
          .then((response) => {
            this.user = response.data.data;
            console.log(response);
          })
          .catch((error) => {
            console.log(error);
          });
    },
  },
  mounted() {
    this.getPageInfo()
  }
}
</script>
