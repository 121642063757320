<template>
    <div>
        <v-snackbar
                v-model="snackbar"
                :timeout="2000"
        >Not available at the moment.
        </v-snackbar>
        <v-dialog v-model="dialog" max-width="500px" class="d-inline pa-2">
            <template v-slot:activator="{ on }">
                <v-btn text dark color="#0264E4" class="caption" v-on="on">Edit</v-btn>
            </template>
            <v-card>
                <v-card-title>
                    <span class="headline">Editing {{client.account_name}}</span>
                </v-card-title>
                <v-card-text>

                    <v-alert
                            :value="alert"
                            dismissible
                            text
                            :type="alertType"
                    >
                        {{alertMessage}}
                    </v-alert>

                    <v-col>
                        <v-file-input v-model="client_photo" chips show-size accept="image/*"
                                      label="Client logo"></v-file-input>
                    </v-col>
                    <v-col cols="12">
                        <v-text-field v-model="account_name" label="Account name"
                                      required></v-text-field>
                    </v-col>
                    <v-col>
                        <v-select
                                :items="pricing"
                                label="Client Plan"
                                required
                                v-model="pricing_select"
                        ></v-select>
                    </v-col>
                </v-card-text>
                <v-card-actions>

                    <v-btn icon color="red" @click="snackbar =true">
                        <v-icon>delete</v-icon>
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="dialog = false">Close</v-btn>
                    <v-btn color="blue darken-1" text @click="handleSubmit">Save</v-btn>
                </v-card-actions>

            </v-card>
        </v-dialog>
    </div>
</template>
<script>
    import axios from "axios";

    export default {
        props: {
            client: Object,
        },
        data() {
            return {
                alert: false,
                alertMessage: '',
                alertType: 'info',
                snackbar: false,
                account_name: '',
                client_photo: '',
                pricing: ['Basic plan', 'Standard plan', 'Enterprise plan',],
                pricing_select: null,
                dialog: false,
            }
        },
        methods: {
            handleSubmit() {
                this.alertMessage = 'Updating user';
                this.alertType = 'info';
                this.alert = true;

                // Create client data form data
                let formData = new FormData();
                formData.append('account_name', this.account_name);
                formData.append('_method', 'PUT');
                formData.append('client_plan', `${this.pricing.indexOf(this.pricing_select)}`);
                formData.append('client_icon', this.client_photo);


                // get event
                axios.post(`/clients/${this.client.id}`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                })
                    .then((response) => {
                        if (response.status === 200) {
                            this.alert = false;
                            this.dialog = false;
                            this.$emit('fetchClient')
                        } else {
                            this.alertMessage = response.data.message;
                            this.alertType = 'error';
                            this.alert = true;
                        }
                    })
                    .catch((error) => {
                        console.log(error);
                    })
                    .finally(() => (this.isLoading = false));
            },
            handleDelete() {

            }
        },
        mounted() {
            this.account_name = this.client.account_name;
            this.pricing_select = this.pricing[this.client.client_plan]

        }
    }
</script>
