<template>
    <v-sheet flat tile>
        <v-toolbar dark color="primary" flat>
            <v-toolbar-title>
                <v-img :src="require('@/assets/ic_logo.svg')" max-height="70px"
                       max-width="70px"></v-img>
            </v-toolbar-title>
        </v-toolbar>
        <v-card flat tile class="text-center">
            <v-card-text>
                <div class="display-2 text-capitalize">Let's work together</div>
                <div class="body-1 mt-5">Work with a smart and energetic team of individuals building solutions for
                    Africa
                </div>
            </v-card-text>
        </v-card>

        <!--  Perks   -->
        <v-card flat>
            <v-card-title>
                <v-spacer></v-spacer>
                <div class="display-1 blue--text">Perks</div>
                <v-spacer></v-spacer>
            </v-card-title>
            <v-container>
                <v-row>
                    <v-spacer></v-spacer>
                    <v-col cols="12" sm="6" md="6" lg="3" align="center">
                        <v-card outlined>
                            <v-img
                                    height="100" width="100"
                                    contain
                                    :src="require('@/assets/flexible.png')"
                                    light
                                    class="fluid">
                            </v-img>
                            <v-card-text>
                                <div class="body-1">Flexible Hours</div>
                            </v-card-text>
                        </v-card>
                    </v-col>
                    <v-col cols="12" sm="6" md="6" lg="3" align="center">
                        <v-card outlined>
                            <v-img
                                    height="100" width="100"
                                    contain
                                    :src="require('@/assets/pro_tools.png')"
                                    light
                                    class="fluid">
                            </v-img>
                            <v-card-text>
                                <div class="body-1">Pro Tools</div>
                            </v-card-text>
                        </v-card>
                    </v-col>
                    <v-col cols="12" sm="6" md="6" lg="3" align="center">
                        <v-card outlined>
                            <v-img
                                    height="100" width="100"
                                    contain
                                    :src="require('@/assets/remote.png')"
                                    light
                                    class="fluid">
                            </v-img>
                            <v-card-text>
                                <div class="body-1">Remote Friendly</div>
                            </v-card-text>
                        </v-card>
                    </v-col>
                    <v-col cols="12" sm="6" md="6" lg="3" align="center">
                        <v-card outlined>
                            <v-img
                                    height="100" width="100"
                                    contain
                                    :src="require('@/assets/nature.png')"
                                    light
                                    class="fluid">
                            </v-img>
                            <v-card-text>
                                <div class="body-1">Ambient Environment</div>
                            </v-card-text>
                        </v-card>
                    </v-col>
                    <v-spacer></v-spacer>
                </v-row>
            </v-container>
        </v-card>

        <!--  Career Postings Section   -->
        <v-card flat>
            <v-card-title class="h1 text-center">
                <v-spacer></v-spacer>
                <div class="display-1 blue--text">Open Positions</div>
                <v-spacer></v-spacer>
            </v-card-title>

            <v-card-text>
                <v-row justify="space-around">
                    <v-spacer></v-spacer>
                    <v-list two-line width="600" class="align-center justify-center">
                        <template v-for="(item, index) in jobLinks">
                            <v-list-item :key=index @click="showCareer(item.id)">
                                <v-list-item-content>
                                    <v-list-item-title>{{ item.title }}</v-list-item-title>
                                    <v-list-item-subtitle>{{ item.location }}</v-list-item-subtitle>
                                </v-list-item-content>
                                <v-spacer></v-spacer>
                                <v-list-item-icon>
                                    <v-icon>arrow_forward_ios</v-icon>
                                </v-list-item-icon>
                            </v-list-item>
                            <v-divider :key=index></v-divider>
                        </template>
                    </v-list>
                    <v-spacer></v-spacer>
                </v-row>
            </v-card-text>
        </v-card>

        <!--   Footer     -->
        <v-footer
                dark
                padless
                absolute
        >
            <v-card
                    class="flex text-center"
                    flat
                    tile
            >
                <v-card-text class="py-2 white--text">
                    {{ new Date().getFullYear() }} — <strong>Hokela Interactive Africa</strong>
                </v-card-text>
            </v-card>
        </v-footer>
    </v-sheet>
</template>

<script>
    import axios from 'axios';

    export default {
        mounted() {
            axios.defaults.baseURL = 'https://develop.hokela.co.ke/api';
            //axios.defaults.headers.common.Authorization = `Bearer ${this.$store.state.token}`;
            axios.get('/admin/careers/')
                .then((response) => {
                    // this.team = response.data.data;
                    console.log(response.data);
                    this.jobLinks = response.data.data
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        components: {},
        data() {
            return {
                jobLinks: []
            }
        },
        methods: {
            showCareer(career_id) {
                this.$router.push({name: 'career', params: {id: career_id}});
            },
        },
    }
</script>
