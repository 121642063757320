<template>
  <v-dialog
      v-model="dialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
          color="primary"
          dark
          v-bind="attrs"
          v-on="on"
      >
        <v-icon>add</v-icon>
        <span>New analysis</span>
      </v-btn>
    </template>
    <v-snackbar
        absolute
        v-model="snackbar"
        :timeout="2000"
    >
      {{ snackMessage }}

      <template v-slot:action="{ attrs }">
        <v-btn
            color="blue"
            text
            v-bind="attrs"
            @click="snackbar = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>

    <v-card flat tile>
      <v-toolbar
          dark
          color="primary"
      >
        <v-btn
            icon
            dark
            @click="dialog = false"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>Custom analysis</v-toolbar-title>
      </v-toolbar>

      <v-alert
      border="right"
      color="red"
      dark
    >
     This report does not have adequate data to generate accurate analysis. Results might be incomprehensible
    </v-alert>

      <v-card-text>
        <v-card flat tile>
          <v-card-text>
            <v-stepper v-model="step" vertical non-linear>
              <v-stepper-step :complete="step>1" step="1">
                Set up
                <small>Fill basic info about the analysis</small>
              </v-stepper-step>
              <v-stepper-content step="1">
                <v-card class="mb-12" outlined>
                  <v-card-text>
                    <div class="body-1 text-left">Select how you want to show your data *</div>
                    <v-chip-group v-model="card.type">
                      <v-chip text value="pie" active-class="primary">Pie Chart</v-chip>
                      <v-chip text value="bar" active-class="primary">Bar Graph</v-chip>
                      <v-chip text value="line" active-class="primary">Line Graph</v-chip>
                      <v-chip text value="list" hidden active-class="primary">Item List</v-chip>
                      <v-chip text active-class="primary" value="table">Table</v-chip>
                      <v-chip text value="map" hidden active-class="primary">Map</v-chip>
                      <v-chip text value="county" hidden active-class="primary">County Map</v-chip>
                    </v-chip-group>

                    <v-text-field
                        class="mt-5" outlined
                        label="Give your data a title Eg Nairobi county sales"
                        type="text" v-model="card.title"
                    ></v-text-field>

                    <div class="body-1 text-left">Select data visibility duration</div>

                    <v-chip-group v-model="card.duration" value="all">
                      <v-chip text active-class="primary" value="all">All</v-chip>

                      <v-chip text value="day" active-class="primary">Day</v-chip>

                      <v-chip text value="week" active-class="primary">Week</v-chip>

                      <v-chip text value="month" active-class="primary">Month</v-chip>

                      <v-chip text value="year" active-class="primary">Year</v-chip>
                    </v-chip-group>

                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" @click="pageOne">next step</v-btn>
                  </v-card-actions>
                </v-card>
              </v-stepper-content>

              <v-stepper-step :complete="step > 2" step="2">
                Modify
                <small>Modification and Algorithms</small>
              </v-stepper-step>
              <v-stepper-content step="2">
                <v-card class="mb-12" outlined>
                  <v-card-subtitle>
                    Select questions for use from the available questions
                  </v-card-subtitle>
                  <v-card-text>

                    <v-combobox
                        v-model="card.formula.questions"
                        :items="report.questions"
                        item-text="label"
                        item-value="id"
                        outlined
                        color="primary"
                        deletable-chips
                        label="Select Questions"
                        multiple
                        chips
                    ></v-combobox>
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn text @click="step--">Back</v-btn>
                    <v-btn color="primary" @click="pageTwo">next step</v-btn>
                  </v-card-actions>
                </v-card>
              </v-stepper-content>

              <v-stepper-step :complete="step > 3" step="3">
                Show
                <small>Add data to {{ card.type }} </small>
              </v-stepper-step>
              <v-stepper-content step="3">
                <v-card class="mb-12" outlined>
                  <v-card-text>

                    <!-- Bar chart-->
                    <div v-if="card.type === 'bar' || card.type === 'line'">
                      <div class="caption"> A bar/line chart takes X(labels).
                        number
                      </div>

                      <v-card max-width="800">
                        <v-row>
                          <v-col cols="4">
                            <div class="body-1">Select X axis values</div>
                          </v-col>
                          <v-col cols="7">
                            <v-combobox
                                v-model="barXValue"
                                item-text="label"
                                item-value="id"
                                chips
                                multiple
                                dense
                                outlined
                                deletable-chips
                                :items="card.formula.questions"
                            ></v-combobox>
                          </v-col>
                        </v-row>

                        <v-row>
                          <v-col cols="4">
                            <div class="body-1">Select Y axis value</div>
                          </v-col>
                          <v-col cols="7">
                            <v-combobox
                                v-model="barYValue"
                                item-text="label"
                                item-value="id"
                                chips
                                dense
                                outlined
                                :items="integerValues"
                            ></v-combobox>
                          </v-col>
                        </v-row>

                        <v-row>
                          <v-col cols="4">
                            <div class="body-1">Grouping options</div>
                          </v-col>
                          <v-col cols="7">
                            <v-combobox
                                v-model="barGrouping"
                                dense
                                outlined
                                chips
                                :items="barChartGrouping"
                                item-text="name"
                            ></v-combobox>
                            <div class="caption grey--text">You can select multiple Y values</div>
                          </v-col>
                        </v-row>
                      </v-card>
                    </div>

                    <!-- Pie chart-->
                    <div v-if="card.type === 'pie'">
                      <div class="caption"> A pie chart takes labels and values of each label</div>
                      <v-card max-width="800" outlined>
                        <v-card-text>

                          <v-row>
                            <v-col cols="4">
                              <div class="body-1">Select pie chart values</div>
                            </v-col>
                            <v-col cols="7">
                              <v-combobox
                                  v-model="pieValues"
                                  dense
                                  outlined
                                  chips deletable-chips
                                  :items="card.formula.questions"
                                  item-text="label"
                                  item-value="id"
                              >
                              </v-combobox>
                            </v-col>

                            <v-col cols="4">
                              <div class="body-1">Sorting options</div>
                            </v-col>
                            <v-col cols="7">
                              <v-combobox
                                  v-model="sortingModel"
                                  dense
                                  outlined
                                  chips deletable-chips
                                  :items="pieSortingOptions"
                                  item-text="label"
                                  item-value="id"
                              >
                              </v-combobox>
                            </v-col>
                          </v-row>
                          <div class="caption red--text">Value count is only available for number values</div>
                        </v-card-text>
                      </v-card>
                    </div>

                    <!-- Item list -->
                    <div v-if="card.type === 'list'">
                      <div class="caption"> A list take a title, subtitle & description.Titles are mandatory</div>
                      <v-card max-width="1200">
                        <v-row>
                          <v-col cols="4">
                            <div class="body-1">Title <span class="red--text">*</span></div>
                          </v-col>
                          <v-col cols="4">
                            <v-combobox
                                v-model="itemListTitle"
                                dense
                                filled
                                chips
                                deletable-chips
                                :items="card.formula.questions"
                                label="Select question"
                                item-text="label"
                                item-value="id"
                            ></v-combobox>
                          </v-col>
                          <v-col cols="4">
                            <v-combobox
                                v-model="itemListTitleValue"
                                dense
                                filled
                                chips
                                deletable-chips
                                :items="fieldValues"
                                item-text="name"
                                item-value="key"
                                label="Field value"
                            ></v-combobox>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col cols="4">
                            <div class="body-1">Subtitle</div>
                          </v-col>
                          <v-col cols="4">
                            <v-combobox
                                v-model="itemListSubtitle"
                                dense
                                filled
                                chips
                                deletable-chips
                                :items="card.formula.questions"
                                label="Select question"
                                item-text="label"
                                item-value="id"
                            ></v-combobox>
                          </v-col>
                          <v-col cols="4">
                            <v-combobox
                                v-model="itemListSubtitleValue"
                                dense
                                filled
                                chips
                                deletable-chips
                                :items="fieldValues"
                                item-text="name"
                                item-value="key"
                                label="Field value"
                            ></v-combobox>
                          </v-col>
                        </v-row>
                      </v-card>
                    </div>

                    <!-- Data map-->
                    <div v-if="card.type === 'map'">
                      <div class="caption">
                        A map displays values depending on the tagged location.
                      </div>

                      <v-card max-width="800">
                        <v-row>
                          <v-col cols="4">
                            <div class="body-1">Select location values</div>
                          </v-col>
                          <v-col cols="7">
                            <v-combobox
                                v-model="mapValue"
                                :items="locationValues"
                                item-text="label"
                                dense
                                filled
                            ></v-combobox>
                          </v-col>
                        </v-row>

                        <v-row>
                          <v-col cols="4">
                            <div class="body-1">Select display values</div>
                          </v-col>
                          <v-col cols="7">
                            <v-combobox
                                v-model="mapDisplayValue"
                                dense
                                filled
                                item-text="label"
                                :items="card.formula.questions"
                            ></v-combobox>
                            <div class="caption grey--text">A map displays the location values categorised in dates or submissions
                            </div>
                          </v-col>
                        </v-row>
                      </v-card>
                    </div>

                    <!-- Table item list-->
                    <div v-if="card.type=== 'table'">
                      <div class="caption"> Tables show data per submission
                      </div>

                      <v-card max-width="1200">
                        <v-row>
                          <v-col cols="4">
                            <div class="body-1">Select table columns</div>
                          </v-col>
                          <v-col cols="7">
                            <v-combobox
                                v-model="tableValues"
                                item-text="label"
                                item-value="id"
                                chips
                                multiple
                                deletable-chips
                                dense
                                filled
                                :items="card.formula.questions"
                            ></v-combobox>
                          </v-col>
                        </v-row>
                      </v-card>

                    </div>

                    <!-- County map -->
                    <div v-if="card.type === 'county'">
                      <v-card max-width="1200">
                        <v-row>
                          <v-col cols="4">
                            <div class="body-1">County visualization options</div>
                          </v-col>
                          <v-col cols="7">
                            <v-combobox
                                v-model="tableValues"
                                item-text="label"
                                item-value="id"
                                chips
                                deletable-chips
                                dense
                                filled
                                :items="card.formula.questions"
                            ></v-combobox>
                          </v-col>
                        </v-row>
                      </v-card>
                    </div>
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn text color="grey" @click="step--">Back</v-btn>
                    <v-btn color="primary" @click="submitCard" :loading="loading">Add analysis</v-btn>
                  </v-card-actions>
                </v-card>
              </v-stepper-content>
            </v-stepper>
          </v-card-text>
        </v-card>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
import axios from "axios";

export default {
  props: {
    report: Object,
  },
  data() {
    return {
      step: 1,
      snackbar: false,
      loading: false,
      dialog: false,
      snackMessage: 'All field are required',
      sortingModel: null,
      card: {
        title: '',
        duration: 'all',
        type: null,
        cardSize: 'null',
        formula: {
          questions: []
        },
      },
      staticCard: {
        title: '',
        duration: 'all',
        type: null,
        cardSize: 'null',
        formula: {
          questions: []
        },
      },
      barYValue: '',
      barXValue: null,
      pieValues: null,
      mapValue: null,
      mapDisplayValue: null,
      barChartGrouping: [
        {
          name: 'Day',
          id: 'day',
        },
        {
          name: 'Submission',
          id: 'submission',
        },
      ],
      barGrouping: null,
      fieldValues: [
        {
          name: 'Set question',
          key: 'label'
        },
        {
          name: 'Submitted values',
          key: 'value'
        }
      ],
      itemListTitle: null,
      itemListTitleValue: null,
      itemListSubtitle: null,
      itemListSubtitleValue: null,
      tableValues: []
    }
  },
  methods: {
    pageOne() {
      if (!this.card.title) {
        this.snackMessage = 'Title is a required field'
        this.snackbar = true
        return
      }
      if (!this.card.type) {
        this.snackMessage = 'Please select what you want to build'
        this.snackbar = true
        return
      }

      this.step += 1
    },
    pageTwo() {
      if (this.card.formula.questions.length === 0) {
        this.snackMessage = 'Select one or more questions for use'
        this.snackbar = true
        return
      }

      this.step += 1
    },
    submitCard() {
      this.loading = true
      switch (this.card.type) {
        case 'bar':
        case 'line':
          this.processBarChart()
          break
        case 'pie':
          this.processPieChart()
          break
        case 'list':
          this.processItemList()
          break
        case 'table':
          this.processTableItems()
          break
        case 'map':
          this.processMapItems()
          break
      }
    },
    processPieChart() {
      if (this.pieValues) {
        let pieData = {
          title: this.card.title,
          type: this.card.type,
          duration: this.card.duration,
          report_id: this.$route.params.id,
          value: this.pieValues.id,
          sorting: this.sortingModel.id
        };

        this.postDataToServer(pieData)
      } else {
        this.snackMessage = 'Pie chart requires a value'
        this.snackbar = true
        this.loading = false
      }
    },
    processBarChart() {
      if (this.barXValue && this.barYValue) {

        let barData = {
          title: this.card.title,
          type: this.card.type,
          duration: this.card.duration,
          report_id: this.$route.params.id,
          grouping: this.barGrouping.id,
          values: {
            yAxis: this.barYValue.id,
            xAxis: this.barXValue.map(item => {
              return item.id
            })
          }
        };

        this.postDataToServer(barData)
      } else {
        this.snackMessage = 'You need to select question for X axis'
        this.snackbar = true
        this.loading = false
      }
    },
    processItemList() {
      if (this.itemListTitle != null
          && this.itemListTitleValue != null
          && this.itemListSubtitle != null
          && this.itemListSubtitleValue != null) {
        let listData = {
          title: this.card.title,
          type: this.card.type,
          duration: this.card.duration,
          report_id: this.$route.params.id,
          values: {
            title: {
              question: this.itemListTitle.id,
              value: this.itemListTitleValue.key
            },
            subtitle: {
              question: this.itemListSubtitle.id,
              value: this.itemListSubtitleValue.key
            }
          }
        }
        this.postDataToServer(listData)
      } else {
        this.snackMessage = 'You need to select question for X axis'
        this.snackbar = true
        this.loading = false
      }
    },
    processTableItems() {
      let tableData = {
        title: this.card.title,
        type: this.card.type,
        duration: this.card.duration,
        report_id: this.$route.params.id,
        values: []
      }

      this.tableValues.forEach(q => {
        tableData.values.push(q.id)
      })

      this.postDataToServer(tableData)

    },
    processMapItems() {
      let mapData = {
        title: this.card.title,
        type: this.card.type,
        duration: this.card.duration,
        report_id: this.$route.params.id,
        values: {
          location: this.mapValue.id,
          values: this.mapDisplayValue.id
        }
      }
      this.postDataToServer(mapData)
    },
    postDataToServer(data) {
      axios.post(`/report/card`, data)
          .then((response) => {
            this.loading = false
            this.card = this.staticCard
            this.step = 1
            this.$emit('reload')
            this.dialog = false
          })
          .catch((error) => {
            this.loading = false
            this.snackMessage = 'An error occurred creating  the card. Please try again later'
            this.snackbar = true
          });
    },
  },
  computed: {
    pieSortingOptions() {
      let values = [
        {
          label: 'Value count',
          id: 'count'
        },
        {
          label: 'Value Entered',
          id: 'value'
        },
        {
          label: 'Days of week',
          id: 'days'
        },
      ]

      this.integerValues.forEach(value => {
        values.push(value)
      })

      return values
    },
    labels() {
      console.log(this.questions[0]);
      let label = [];

      this.questions.forEach(question =>
          label.push(question.label)
      )
      return label
    },

    integerValues() {
      let values = [];
      this.card.formula.questions.forEach(q => {
            if (q.type === 'number') {
              values.push(q)
            }
          }
      )
      return values
    },
    locationValues() {
      let values = [];
      this.card.formula.questions.forEach(q => {
            if (q.type === 'gps') {
              values.push(q)
            }
          }
      )
      return values
    },
  },
}

</script>
