import axios from "axios";

export default {
  // Dashboard.vue
  getHomePage() {
    return axios.get("/home");
  },

  // AccountPage.vue
  getAccountInfo(userID) {
    return axios.get(`/clients/${userID}`);
  },

  getAllClients() {
    return axios.get("/clients");
  },

  getDashData() {
    return axios.get("/dashboard");
  },
  getClientsById(id) { },

  getClients() {
    return axios.get("/client_data");
  },

  // EditProject.vue
  deleteProject(id) {
    return axios.get(`/projects/${id}`);
  },

  getAssigned(id) {
    return axios.get(`/assigned/${id}`);
  },

  getProject(id) {
    return axios.get(`/projects/${id}`);
  },

  getProjectInfo(id) {
    return axios.get(`/project/${id}`);
  },

  updateProject(id, obj = {}) {
    return axios.put(`/projects/${id}`, obj);
  },

  addAssigned(addAssigned) {
    return axios.post("/assigned", addAssigned);
  },

  deleteAssigned(id) {
    return axios.delete(`/assigned/${id}`);
  },

  searchClientService(val) {
    return axios.get(`/search/client_service?query=${val}`);
  },

  // EventPage.vue
  getEvent(id) {
    return axios.get(`event/show/${id}`);
  },

  getEventOverview(id) {
    return axios.get(`events/${id}`);
  },

  getMaps(id) {
    return axios.get(`/maps/${id}`);
  },

  getAllMaps() {
    return axios.get("/maps");
  },

  getTimestampsByEvent(id) {
    return axios.get(`/timestamps/${id}`);
  },

  getTimestamps() {
    return axios.get(`/timestamps`);
  },

  // Locations.vue
  getLocations() {
    return axios.get("/location");
  },
  submitLocation(submitlocation) {
    return axios.post("/location", submitlocation);
  },
  updateLocation(itemId, locationObj) {
    return axios.put(`/location/${itemId}`, locationObj);
  },
  deleteLocation(itemId) {
    return axios.delete(`/location/${itemId}`);
  },

  // Merch.vue
  loadMerch() {
    return axios.get("/merch");
  },

  // Manager.vue
  getProjects() {
    return axios.get("/projects");
  },
  createProject(projectName) {
    return axios.post("/projects", { name: projectName });
  },

  //Route.vue
  getRoute(id) {
    return axios.get(`/routes/${id}`);
  },

  //RSMLocations.vue
  getRSMLocations(id) {
    return axios.get(`/events/${id}`);
  },

  // TeamList.vue
  getTeamData(id) {
    return axios.get(`/teams/${id}`);
  },

  // UserManagement.vue
  resendEmail(id) {
    return axios.get(`/pending/resend/${id}`);
  },
  deleteInvite(id) {
    return axios.delete(`/pending/${id}`);
  },
  searchClient(val) {
    return axios.get(`/search/clients?query=${val}`);
  },
  getPendingUsers() {
    return axios.get("/pending");
  },
  addTemporaryUser(formData) {
    return axios.post("/pending", formData);
  },

  //Users.vue
  hokelaPage() {
    return axios.get("/hokela");
  },
  users() {
    return axios.get("/users");
  },

  //ViewEvent.vue
  getReportsByEvent(id) {
    return axios.get(`/event/reports/${id}`);
  },

  //ViewMerch
  loadMerchItem(id) {
    return axios.get(`/merch/${id}`);
  },

  getReports() {
    return axios.get("/reports");
  },

  getReportInfo(id) {
    return axios.get(`/reports/${id}`);
  },
  getReportsByProject(id) {
    return axios.get(`project/reports/${id}`);
  },

  // get projects by clients
  getProjectByClient(id) {
    return axios.get(`client/projects/${id}`);
  },

  // Joining code for campaigns
  handleJoiningCode(val, id) {
    return axios.post(`event/code/${id}`, { action: val });
  },

  /**
   *
   * @param id is the Event id
   */
  exportDailyCheckins(id) {
    return axios.get(`export/today/${id}`, { responseType: "arraybuffer" });
  },

  /**
   *
   * @param id is the Event id
   */
  exportTimestamps(id) {
    return axios.get(`export/all/${id}`, { responseType: "arraybuffer" });
  },

  getEvents() {
    return axios.get("/events");
  },

  getEventsByProject(id) {
    return axios.get(`/project/campaigns/${id}`);
  },

  getTeamByEvent(id) {
    return axios.get(`event/team/${id}`);
  },

  getCampaignsByClient(id) {
    return axios.get(`client/campaigns/${id}`);
  },

  getReportsByClient(id) {
    return axios.get(`client/reports/${id}`);
  },

  getRoutesByCampaign(id) {
    return axios.get(`event/routes/${id}`);
  },

  getEventImages(id) {
    return axios.get(`event/images/${id}`);
  },
  getReportImages(id) {
    return axios.get(`report/images/${id}`);
  },

  deleteTeam(id) {
    return axios.delete(`teams/${id}`);
  },

  updateEvent(event) {
    return axios.put(`events/${event.id}`, event);
  },

  deleteEvent(id) {
    return axios.delete(`events/${id}`);
  },

  fetchTableData(id, page) {
    return axios.get(`report/table/${id}`, {
      params: {
        page: page,
      },
    });
  },

  exportReport(id, dates) {
    return axios.post(`export/reports/${id}`, dates);
  },

  downloadFiles(name) {
    return axios.get(`downloads/${name}`, { responseType: "arraybuffer" });
  },

  getReportExports(id) {
    return axios.get(`report/exports/${id}`);
  },

  getSales(id) {
    return axios.get(`report/sales/${id}`);
  },

  getReportWithQuestions(id){
    return axios.get(`report-questions/${id}`)
  }
};
