<template>
    <div>
        <sidebar/>
        <v-card flat tile height="100vh" class="main_container" color="#FAFAFA" v-if="merch">
            <v-toolbar dark color="primary">
                <v-toolbar-title class="text-capitalize title">
                    {{merch.name}}
                </v-toolbar-title>

                <v-spacer></v-spacer>
            </v-toolbar>

            <!-- Daily schedule section -->
            <v-card class="mt-3" flat>
                <v-card-title class="title">Today's Schedule</v-card-title>
                <v-card-text>

                    <v-row class="overflow-y-auto">
                        <v-col v-for="(item, index) in merch.schedule" :key="index" cols="auto">

                            <v-card outlined flat tile min-width="250px">

                                <div class="title black--text ma-2">{{item.title}}</div>
                                <div class="body-1 grey--text ma-2">{{item.subtitle}}</div>

                                <v-card outlined class="ma-2" flat v-for="(location, index_) in item.locations"
                                        :color="cardColor(location.status)" :key="index_">
                                    <v-card-text class="body-2">
                                        {{location.name}}
                                    </v-card-text>
                                    <v-card-actions class="caption">
                                        {{locationStatus(location.status)}}
                                    </v-card-actions>
                                </v-card>
                            </v-card>
                        </v-col>

                    </v-row>
                </v-card-text>
            </v-card>

            <!-- History area -->
            <v-card class="ma-3" flat>

                <v-card-subtitle class="text-left body-1 grey--text">Checkin History</v-card-subtitle>
                <v-card-text>
                    <v-simple-table>
                        <thead>
                        <tr>
                            <th class="text-left body-1">Date</th>
                            <th class="text-left body-1">Location</th>
                            <th class="text-left body-1">Checkin</th>
                            <th class="text-left body-1">Checkout</th>
                            <th class="text-left body-1">Time Spent</th>
                            <th class="text-left body-1">Latitude(Checkin)</th>
                            <th class="text-left body-1">Longitude(Checkin)</th>
                            <th class="text-left body-1">GPS Accuracy (meters)</th>
                        </tr>
                        </thead>

                        <tbody>
                        <tr v-for="item in merch.checkins" :key="item.id">
                            <td class="text-left body-2">{{ toDate(item.created_at) }}</td>
                            <td class="text-left body-2">{{ searchLocations(item.merch_location_id) }}</td>
                            <td class="text-left body-2">{{ toTime(item.checkin_time) }}</td>
                            <td class="text-left body-2">{{ item.checkout_time?toTime(item.checkout_time):'-'}}</td>
                            <td class="text-left body-2">{{ timeDifference(item.checkin_time, item.checkout_time) }}
                            </td>
                            <td class="text-left body-2">{{ item.checkin_lat }}</td>
                            <td class="text-left body-2">{{ item.checkin_lng }}</td>
                            <td class="text-left body-2">{{ item.checkin_accuracy }}</td>
                        </tr>
                        </tbody>
                    </v-simple-table>
                </v-card-text>
            </v-card>

            <!--   Assigned Locations      -->
            <v-card flat class="mx-3 mt-3 grey lighten-5">

                <v-card-title>
                    <v-icon large left>storefront</v-icon>
                    <span class="title black--text">
                    {{merch.hokela.name}}
                    </span>

                </v-card-title>
                <v-card-subtitle class="text-left caption">{{merch.hokela.phone}}</v-card-subtitle>
                <v-divider light></v-divider>
                <v-card-text>

                    <v-row>
                        <div class="title text-left">Assigned Locations</div>
                        <v-spacer></v-spacer>

                        <v-btn color="primary" text link class="caption" @click="">Manage Locations</v-btn>
                    </v-row>
                    <v-row class="overflow-y-auto">
                        <v-col v-for="(item, index) in merch.merch" :key="index" cols="auto">

                            <v-card flat min-width="250px">

                                <v-card-title class="body-1 black--text ma-2">{{item.location.name}}</v-card-title>
                                <v-card-text class="caption grey--text ma-2">
                                    {{item.location.lat+','+item.location.lng}}
                                </v-card-text>

                                <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <v-btn class="caption" dark outlined color="primary">View on Maps</v-btn>
                                </v-card-actions>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>

        </v-card>
    </div>
</template>

<script>
    import Sidebar from "../utils/Sidebar.vue";
    import PieChart from "../utils/chart/PieChart.vue";
    import BarChart from "../utils/chart/BarChart.vue";
    import Map from "../utils/map/Map.vue";
    import axios from "axios";
    import ApiService from "@/services/ApiService";

    export default {
        components: {
            Sidebar,
            PieChart,
            BarChart,
            Map,
        },
        data() {
            return {
                loading: true,
                merch: null
            }
        },
        methods: {

            loadMerchItem() {
                ApiService.loadMerchItem(this.$route.params.id)
                    .then((response) => {
                        this.loading = false
                        this.merch = response.data.data;
                    })
                    .catch((error) => {
                        console.log(error);
                    });
            },

            toDate(dateTime) {

                let date = new Date(Date.parse(dateTime))

                const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
                    "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
                ];


                return `${date.getDate()}, ${monthNames[date.getMonth()]}, ${date.getFullYear()} `
            },

            toTime(dateTime) {
                let date = new Date(Date.parse(dateTime))
                return `${('0' + date.getHours()).slice(-2)} : ${('0' + date.getMinutes()).slice(-2)} : ${('0' + date.getSeconds()).slice(-2)}`
            },

            searchLocations(query) {
                let obj = this.merch.merch.find(o => o.id === query);

                return obj.location.name
            },

            timeDifference(startDate, endDate) {
                if (endDate) {
                    let date1 = new Date(Date.parse(startDate));
                    let date2 = new Date(Date.parse(endDate));

                    let msec = date2.getTime() - date1.getTime();
                    let hh = Math.floor(msec / 1000 / 60 / 60);
                    msec -= hh * 1000 * 60 * 60;
                    let mm = Math.floor(msec / 1000 / 60);
                    msec -= mm * 1000 * 60;
                    let ss = Math.floor(msec / 1000);
                    msec -= ss * 1000;

                    return `${('0' + hh).slice(-2)} : ${('0' + mm).slice(-2)} : ${('0' + ss).slice(-2)}`;
                } else {
                    return '-'
                }
            },
            cardColor(status) {
                switch (status) {
                    case 0:
                        return '#F5F5F5';
                    case 1:
                        return '#C8E6C9'
                    case 2:
                        return '#FFE0B2'
                }
            },
        },
        mounted() {
            this.loadMerchItem()
        },
        computed: {
            chart() {
                return [
                    {
                        label: 'Stock requests',
                        backgroundColor: '#0264E4',
                        data: this.value,
                    },
                ];
            },
            markers() {
                let marker = this.merch.markers;
                return marker.length;
            },
        },

    }
</script>
