<template>
  <v-sheet flat tile>
    <v-row v-if="enabled">
      <v-col cols="4">
        <v-autocomplete
          v-model="selected"
          :disabled="isUpdating"
          :items="getUsers"
          outlined
          dense
          clearable
          label="Filter by user"
          item-text="name"
          item-value="id"
        >
          <template v-slot:selection="data">
            <v-chip
              v-bind="data.attrs"
              :input-value="data.selected"
              close
              dark
              color="primary"
              @click="data.select"
              @click:close="remove(data.item)"
            >
              <v-avatar left>
                <v-img :src="data.item.avatar"></v-img>
              </v-avatar>
              {{ data.item.name }}
            </v-chip>
          </template>
          <template v-slot:item="data">
            <template v-if="typeof data.item !== 'object'">
              <v-list-item-content v-text="data.item"></v-list-item-content>
            </template>
            <template v-else>
              <v-list-item-avatar>
                <img :src="data.item.avatar" />
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title v-html="data.item.name"></v-list-item-title>
                <v-list-item-subtitle
                  v-html="data.item.phone"
                ></v-list-item-subtitle>
              </v-list-item-content>
            </template>
          </template>
        </v-autocomplete>
      </v-col>

      <v-col cols="2">
        <v-menu
          v-model="menu2"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="290px"
        >
          <template v-slot:activator="{ on }">
            <v-text-field
              v-model="dateSelect"
              label="Set date"
              dense
              prepend-inner-icon="event"
              outlined
              readonly
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            :max="maxDate"
            v-model="dateSelect"
            @input="menu2 = false"
          ></v-date-picker>
        </v-menu>
      </v-col>

      <v-col cols="2">
        <v-menu
          ref="menu"
          v-model="time1"
          :close-on-content-click="false"
          :nudge-right="40"
          :return-value.sync="start"
          transition="scale-transition"
          offset-y
          max-width="290px"
          min-width="290px"
        >
          <template v-slot:activator="{ on }">
            <v-text-field
              v-model="start"
              outlined
              dense
              label="Starting time"
              prepend-inner-icon="access_time"
              readonly
              v-on="on"
            ></v-text-field>
          </template>
          <v-time-picker
            v-if="time1"
            v-model="start"
            :max="end"
            format="HH:MM"
            full-width
            @click:minute="$refs.menu.save(start)"
          ></v-time-picker>
        </v-menu>
      </v-col>
      <v-col cols="2">
        <v-menu
          ref="end"
          v-model="time2"
          :close-on-content-click="false"
          :nudge-right="40"
          :return-value.sync="end"
          transition="scale-transition"
          offset-y
          max-width="290px"
          min-width="290px"
        >
          <template v-slot:activator="{ on }">
            <v-text-field
              v-model="end"
              label="End time"
              outlined
              dense
              prepend-inner-icon="access_time"
              readonly
              v-on="on"
            ></v-text-field>
          </template>
          <v-time-picker
            v-if="time2"
            v-model="end"
            format="HH:MM"
            :min="start"
            full-width
            @click:minute="$refs.end.save(end)"
          ></v-time-picker>
        </v-menu>
      </v-col>
      <v-col cols="2">
        <v-btn dark block color="primary" @click="handleFilter">Filter</v-btn>
      </v-col>
    </v-row>
    <v-card flat>
      <v-card-text>
        <LeafletMap :markers="markers" />
        <!-- <Map :markers="getMarker"/> -->
      </v-card-text>
    </v-card>
  </v-sheet>
</template>

<script>
import Map from "./Map.vue";
import LeafletMap from "./LeafletMap.vue";
import ApiService from "@/services/ApiService";

export default {
  components: { Map, LeafletMap },
  data() {
    return {
      enabled: false,
      selected: null,
      isUpdating: false,
      dateSelect: null,
      maxDate: new Date().toISOString().substr(0, 10),
      start: null,
      end: null,
      menu2: false,
      time2: false,
      time1: false,
      maps: {
        checkins: [],
        users: [],
      },
      user: 0,
      markers: [],
    };
  },
  mounted() {
    this.getMaps();
  },
  methods: {
    handleFilter() {
      this.user = this.selected;
    },

    getMaps() {
      if (this.$route.name === "event_map")
        ApiService.getMaps(this.$route.params.id)
          .then((response) => {
            this.maps = response.data.data;
            this.markers = this.getMarkers(this.maps);
          })
          .catch((error) => {
            console.log(error);
          });
      else
        ApiService.getAllMaps()
          .then((response) => {
            this.maps = response.data.data;
          })
          .catch((error) => {
            console.log(error);
          });
    },

    getMarkers(data) {
      if (data.checkins) {
        return data.checkins.map((checkin) => {
          const marker = checkin.position;
          if (marker) {
            return marker;
          }
          return {};
        });
      }

      return [];
    },
  },
  computed: {
    getUsers() {
      let users = this.maps.users;
      users.unshift({
        name: "All Users",
        id: 0,
      });
      return users;
    },
    getMarker() {
      if (this.user === 0) return this.maps.checkins;
      else return this.maps.users.filter((obj) => obj.name === this.user);
    },
  },
};
</script>
