<template>
    <v-sheet tile flat>
        <v-data-iterator
                :items="merch"
                hide-default-footer
                :page="page"
                :items-per-page.sync="itemsPerPage">
            <template v-slot:default="props">
                <v-row>
                    <v-col
                            v-for="event in props.items"
                            :key="event.id"
                            cols="12"
                            sm="6"
                            md="4"
                            lg="3"
                    >
                        <v-card style="overflow: hidden;" flat color="#F0F8FF" height="150"
                                @click="viewMerch(event)">
                            <v-card-text>
                                <div>
                                    <p class="title text-wrap text-left text-capitalize">
                                        {{event.name}}
                                    </p>
                                </div>
                                <div class="caption  text-left mt-2">
                                    User: {{event.hokela.name}}
                                </div>
                                <div class="caption  text-left">
                                    <div class="mt-2">
                                        Created: {{event.created_at}}
                                    </div>

                                    <div class="mt-2">
                                        Locations: {{event.locations_count}}
                                    </div>
                                </div>

                            </v-card-text>

                        </v-card>

                    </v-col>
                </v-row>
            </template>
            <template v-slot:footer>
                <v-row class="mt-2" align="center" justify="center">
                    <v-spacer></v-spacer>
                    <span class="grey--text">Items per page</span>
                    <v-menu offset-y>
                        <template v-slot:activator="{ on }">
                            <v-btn
                                    dark
                                    text
                                    color="primary"
                                    class="ml-2"
                                    v-on="on"
                            >
                                {{ itemsPerPage }}
                                <v-icon>mdi-chevron-down</v-icon>
                            </v-btn>
                        </template>
                        <v-list>
                            <v-list-item
                                    v-for="(number, index) in itemsPerPageArray"
                                    :key="index"
                                    @click="updateItemsPerPage(number)"
                            >
                                <v-list-item-title>{{ number }}</v-list-item-title>
                            </v-list-item>
                        </v-list>
                    </v-menu>

                    <span class="mr-5 grey--text">Page {{ page }} of {{ numberOfPages }}</span>
                    <v-btn
                            text
                            color="warning"
                            class="mr-1"
                            @click="formerPage"
                    >
                        Previous
                    </v-btn>
                    <v-btn
                            text
                            color="blue darken-3"
                            class="ml-1"
                            @click="nextPage"
                    >
                        Next page
                    </v-btn>
                </v-row>
            </template>

        </v-data-iterator>
    </v-sheet>
</template>
<script>
    import Merch from "../modals/CreateMerch.vue";
    import ApiService from "@/services/ApiService";

    export default {
        components: {
            Merch,
        },
        props: {
            merch: Array
        },
        data() {
            return {
                itemsPerPage: 8,
                itemsPerPageArray: [8, 12, 16, 20],
                page: 1,
                // merch: [],
            }
        },
        methods: {
            parseFrequency(item) {


            },
            loadMerch() {
                // get event
                ApiService.loadMerch()
                    .then((response) => {
                        this.merch = response.data.data;
                    })
                    .catch((error) => {
                        console.log(error);
                    });
            },
            viewMerch(event) {
                this.$router.push({name: 'merch', params: {id: event.id}});
            }
        },
        computed: {
            numberOfPages() {
                return Math.ceil(this.merch.length / this.itemsPerPage)
            },
        },
        beforeMount() {
            // this.loadMerch()
        },
    }
</script>
