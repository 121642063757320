<template>
  <v-card tile flat class="d-flex flex-row align-stretch" min-height="100vh">
    <v-navigation-drawer permanent class="flex-shrink-0" dark color="primary">
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title class="title">
            {{ user.name }}
          </v-list-item-title>
          <v-list-item-subtitle class="mt-3">
            <v-btn text :to="{ name: 'logout' }">Logout</v-btn>
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>

      <v-divider></v-divider>

      <v-list dense nav expand class="text-left">
        <v-list-item link :to="{ name: 'dashboard' }">
          <v-list-item-icon>
            <v-icon>home</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Dashboard</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-divider></v-divider>

        <v-list-item class="caption text-center blue--text"
          >Project Menu Options</v-list-item
        >

        <v-divider></v-divider>

        <v-list-item link :to="{ name: 'project' }">
          <v-list-item-icon>
            <v-icon>assessment</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Overview</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item
          link
          :to="{ name: 'project_events', params: { events: allEvents } }"
        >
          <v-list-item-icon>
            <v-icon>today</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Campaigns</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item
          link
          :to="{
            name: 'project_reports',
            params: { reports: project.reports },
          }"
        >
          <v-list-item-icon>
            <v-icon>description</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Reports</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-divider></v-divider>

        <v-list-item class="caption text-center blue--text"
          >System shortcuts</v-list-item
        >

        <v-divider></v-divider>

        <v-list-item link :to="{ name: 'locations' }">
          <v-list-item-icon>
            <v-icon>location_on</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Retail Network</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <div class="flex-grow-1 flex-shrink-1 grey lighten-5">
      <snackbar
        :snackbar="snackbar"
        :text="snackMessage"
        :color="color"
      ></snackbar>
      <v-toolbar flat>
        <v-toolbar-title>{{ ` ${project.name} Overview` }}</v-toolbar-title>

        <v-spacer></v-spacer>

        <v-menu transition="slide-y-transition" close-on-content-click bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon light v-bind="attrs" v-on="on">
              <v-icon>more_vert</v-icon>
            </v-btn>
          </template>
          <v-list class="text-left">
            <v-list-item>
              <v-list-item-title>
                <events row="1" :icon="false" />
              </v-list-item-title>
            </v-list-item>
            <v-divider></v-divider>
            <v-list-item>
              <v-list-item-title>
                <events row="0" :icon="false" />
              </v-list-item-title>
            </v-list-item>
            <v-divider></v-divider>
            <v-list-item>
              <v-list-item-title>
                <events row="2" :icon="false" />
              </v-list-item-title>
            </v-list-item>
            <v-divider></v-divider>

            <v-list-item>
              <v-list-item-content>
                <v-list-item-title class="text-left">
                  <v-btn class="text-left body-1" text @click="newReport"
                    >Report</v-btn
                  >
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-divider></v-divider>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title class="text-left">
                  <v-btn text @click="openLocations" class="body-1 text-left"
                    >Locations</v-btn
                  >
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item>
              <v-list-item-content>
                <v-list-item-title class="text-left">
                  <v-btn text @click="editProject" class="body-1 text-left"
                    >Edit Project</v-btn
                  >
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-toolbar>
      <router-view></router-view>
    </div>
  </v-card>
</template>

<script>
import Events from "../modals/Events.vue";
import ProjectMerch from "./EventOverview.vue";
import CreateMerch from "../modals/CreateMerch.vue";
import Reports from "@/components/events/Reports";
import ApiService from "@/services/ApiService";
import { mapGetters } from "vuex";

export default {
  components: {
    Events,
    ProjectMerch,
    CreateMerch,
    Reports,
  },
  data() {
    return {
      breadcrumbs: [
        {
          text: "Dashboard",
          disabled: false,
          href: "/",
        },
        {
          text: "Projects",
          disabled: false,
          href: "/projects",
        },
      ],
      dialog: false,
      row: "0",
      tab: null,
      alert: false,
      alertMessage: "",
      overlay: true,
      absolute: true,
      project: {
        name: "Loading..",
      },
      snackbar: false,
      snackMessage: null,
      color: "primary",
    };
  },
  computed: {
    ...mapGetters({
      user: "auth/user",
    }),

    recceCheckins() {
      let value = 0;
      this.project.events.recce.forEach((item) => {
        value += item.activity_count;

        item.routes.forEach((route) => {
          value += route.checkins.length;
        });
      });

      return value;
    },

    eventCheckins() {
      let value = 0;
      this.project.events.events.forEach((item) => {
        value += item.activity_count;
      });

      return value;
    },
    activationsCheckins() {
      let value = 0;
      this.project.events.activations.forEach((item) => {
        value += item.activity_count;
      });

      return value;
    },
    reportSubmissions() {
      let count = 0;
      this.project.reports.forEach((report) => {
        count += report.submissions.length;
      });
      return count;
    },

    allEvents() {
      let all = [];
      if (this.project.events) {
        this.project.events.recce.forEach((item) => {
          all.push(item);
        });
        this.project.events.activations.forEach((item) => {
          all.push(item);
        });
        this.project.events.events.forEach((item) => {
          all.push(item);
        });
      }

      return all;
    },
  },
  methods: {
    editProject() {
      this.$router.push({
        name: "edit_project",
        params: { id: this.$route.params.id },
        props: true,
      });
    },
    viewEvent(id) {
      this.$router.push({
        name: "event",
        params: { id: id },
      });
    },
    openReport(id) {
      this.$router.push({
        name: "report",
        params: { id: id },
      });
    },
    eventCreatedCallback() {
      this.snackMessage = "Created successfully. Refreshing";
      this.snackbar = true;
      this.getProject();
    },
    newReport() {
      this.$router.push({
        name: "reports.create",
        params: { id: this.$route.params.id },
      });
    },
    openLocations() {
      this.$router.push({ name: "locations" });
    },
    getProject() {
      this.overlay = true;
      ApiService.getProjectInfo(this.$route.params.id)
        .then((response) => {
          this.overlay = false;
          this.project = response.data.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  mounted() {
    this.getProject();
  },
};
</script>
