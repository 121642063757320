<template>
  <div>
    <v-tabs v-model="tab" grow>

      <v-tab>
        RSM
      </v-tab>
      <v-tab>
        Events
      </v-tab>
      <v-tab>
        Reports
      </v-tab>
    </v-tabs>

    <v-tabs-items v-model="tab">
      <v-tab-item>
        <v-card flat height="100%">
          <v-card-text>
            <activity-list :activity="activities.rsm"/>
          </v-card-text>
        </v-card>
      </v-tab-item>

      <v-tab-item>
        <v-card flat>
          <v-card-text>
            <activity-list :activity="activities.events"/>
          </v-card-text>
        </v-card>
      </v-tab-item>

      <v-tab-item>
        <v-card flat>
          <v-card-text>
            <activity-list :activity="activities.reports"/>
          </v-card-text>
        </v-card>
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
import ActivityList from "@/components/users/ActivityList";

export default {
  components: {
    ActivityList
  },
  props: {
    activities: Object
  },
  data() {
    return {
      tab: null
    }
  }
}
</script>
