<template>
  <v-card
    tile
    flat
    class="d-flex flex-row flex-wrap"
    min-height="100vh"
    color="primary"
    width="100vw"
    height="100%"
  >
    <v-navigation-drawer
      permanent
      color="primary"
      dense
      fixed
      dark
      class="flex-shrink-0"
      height="100%"
      min-height="100vh"
      style="position:static"
    >
      <v-row no-gutters>
        <v-col cols="3">
          <v-navigation-drawer
            dark
            color="primary darken-1"
            mini-variant
            height="100vh"
            permanent
          >
            <v-list dense nav>
              <v-list-item link :to="{ name: 'dashboard' }" class="my-5">
                <v-list-item-icon>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon v-bind="attrs" v-on="on">home</v-icon>
                    </template>
                     <span>Home Page</span>
                  </v-tooltip>
                </v-list-item-icon>
              </v-list-item>

              <v-list-item link :to="{ name: 'locations' }" class="my-5">
                <v-list-item-icon>
                <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon v-bind="attrs" v-on="on">location_on</v-icon>
                    </template>
                     <span>Retail Network</span>
                  </v-tooltip>
                </v-list-item-icon>
              </v-list-item>

              <v-list-item link :to="{ name: 'users' }" class="my-5">
                <v-list-item-icon>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon v-bind="attrs" v-on="on">face</v-icon>
                    </template>
                     <span>Users</span>
                  </v-tooltip>
                </v-list-item-icon>
              </v-list-item>
            </v-list>
          </v-navigation-drawer>
        </v-col>
        <v-col>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title class="title">
                {{ user.name }}
              </v-list-item-title>
              <v-list-item-subtitle class="mt-3">
                <v-btn text :to="{ name: 'logout' }">Logout</v-btn>
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <v-divider></v-divider>
          <v-list dense nav class="text-left" width="100%">
            <v-list-item
              class="my-2"
              link
              :to="{
                name: 'report',
              }"
            >
              <v-list-item-icon>
                <v-icon>show_chart</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title class="body-2 text-left"
                  >Overview</v-list-item-title
                >
              </v-list-item-content>
            </v-list-item>

            <v-list-item
              class="my-2"
              link
              :to="{
                name: 'report_table',
              }"
            >
              <v-list-item-icon>
                <v-icon>table_chart</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title class="body-2 text-left"
                  >Data Table</v-list-item-title
                >
              </v-list-item-content>
            </v-list-item>

            <v-list-item class="my-2" link :to="{ name: 'report_images' }">
              <v-list-item-icon>
                <v-icon>insert_photo</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title class="body-2 text-left"
                  >Images</v-list-item-title
                >
              </v-list-item-content>
            </v-list-item>

            <v-list-item class="my-2" link :to="{ name: 'report_edit' }">
              <v-list-item-icon>
                <v-icon>edit</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title class="body-2 text-left"
                  >Edit Report</v-list-item-title
                >
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-col>
      </v-row>
    </v-navigation-drawer>

    <v-card class="flex-grow-1" width="80%" height="100vh">
      <router-view />
    </v-card>
  </v-card>
</template>
<script>
import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters({
      user: "auth/user",
    }),
  },
};
</script>
