<template>
  <v-card flat height="100vh" class="d-flex flex-row align-stretch">
    <v-navigation-drawer permanent class="flex-shrink-0" color="primary" dark>
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title class="title">
            {{ user.name }}
          </v-list-item-title>
          <v-list-item-subtitle class="mt-3">
            <v-btn text :to="{ name: 'logout' }">Logout</v-btn>
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>

      <v-divider dark></v-divider>

      <div class="caption text-center white--text my-3">Quick Access Panel</div>
      <v-list
          nav
          dense
          class="text-left">

        <v-list-item link :to="{ name: 'start' }">
          <v-list-item-icon>
            <v-icon>home</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title class="body-1">Dashboard</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-divider dark></v-divider>

        <v-list-group
            active-class="white"
            prepend-icon="account_circle"
        >
          <template v-slot:activator>
            <v-list-item-title class="body-1">Accounts</v-list-item-title>
          </template>

          <template v-for="(account, key) in accounts">
            <v-list-item link :key="key" @click="showAccountPage(account.id)">
              <v-list-item-title class="text-left ml-5 body-2" v-text="account.name"></v-list-item-title>
            </v-list-item>
          </template>
        </v-list-group>
        <v-divider></v-divider>

        <v-list-group
            active-class="white"
            prepend-icon="description"
        >
          <template v-slot:activator>
            <v-list-item-title class="body-1">Reports</v-list-item-title>
          </template>

          <v-list-group
              class="ml-3"
              active-class="white"
              prepend-icon="shopping_basket"
          >
            <template v-slot:activator>
              <v-list-item-title class="body-1">Stocks</v-list-item-title>
            </template>

            <template v-for="(account, key) in accounts">
              <v-list-item link :key="key" @click="showAccountPage(account.id)">
                <v-list-item-title class="text-left ml-5 body-2" v-text="account.name"></v-list-item-title>
              </v-list-item>
            </template>
          </v-list-group>
          <v-list-group
              class="ml-3"
              active-class="white"
              prepend-icon="shopping_cart"
          >
            <template v-slot:activator>
              <v-list-item-title class="body-1">Orders</v-list-item-title>
            </template>

            <template v-for="(account, key) in accounts">
              <v-list-item link :key="key" @click="showAccountPage(account.id)">
                <v-list-item-title class="text-left ml-5 body-2" v-text="account.name"></v-list-item-title>
              </v-list-item>
            </template>
          </v-list-group>
          <v-list-group
              class="ml-3"
              active-class="white"
              prepend-icon="monetization_on"
          >
            <template v-slot:activator>
              <v-list-item-title class="body-1">Sales</v-list-item-title>
            </template>

            <template v-for="(account, key) in accounts">
              <v-list-item link :key="key" @click="showAccountPage(account.id)">
                <v-list-item-title class="text-left ml-5 body-2" v-text="account.name"></v-list-item-title>
              </v-list-item>
            </template>
          </v-list-group>

        </v-list-group>
        <v-divider></v-divider>

        <v-list-item link :to="{ name: 'users' }">
          <v-list-item-icon>
            <v-icon>supervisor_account</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title class="body-1">Users</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-divider dark></v-divider>

        <v-list-item link :to="{ name: 'tools' }">
          <v-list-item-icon>
            <v-icon>build</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title class="body-1 ">Tools</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-divider></v-divider>

      </v-list>
    </v-navigation-drawer>

    <div class="flex-grow-1 flex-shrink-1">
      <v-tabs
          background-color="white"
          color="primary"
          centered
          grow
      >
        <v-tab v-for="tab in tabs" :key="tab">
          {{ tab }}
        </v-tab>
        <v-tab-item>
          <v-container fluid>
            <ContactMessagesTab/>
          </v-container>
        </v-tab-item>
        <v-tab-item>
          <v-container fluid>
            <CareerPostsTab/>
          </v-container>
        </v-tab-item>
        <v-tab-item>
          <v-container fluid>
            <HelpPostsTab/>
          </v-container>
        </v-tab-item>
      </v-tabs>
    </div>
  </v-card>
</template>

<script>
import ContactMessagesTab from "./contactmessages/ContactMessagesTab";
import CareerPostsTab from "./careerposts/CareerPostsTab";
import HelpPostsTab from "./helppage/HelpPostsTab";
import axios from "axios";
import {mapGetters} from 'vuex'

export default {
  name: 'Home',
  components: {HelpPostsTab, CareerPostsTab, ContactMessagesTab},
  data() {
    return {
      tabs: ["Contact Messages", "Career Posts", "Help Page"],
      accounts: [],
    }
  },
  mounted() {
    this.getClients()
  },
  methods: {
    showAccountPage(id) {
      this.$router.push({
        name: 'account',
        params: {id: id}
      });
      this.$root.$emit('save_client_projects', this.users);
    },
    getClients() {
      axios.get('/client_data')
          .then((response) => {
            this.accounts = response.data.data;
          })
          .catch((error) => {
            console.log(error);
          });
    },
  },
  computed: {
    ...mapGetters({
      user: 'auth/user'
    })
  }
}
</script>
