<template>
  <v-card flat>
    <v-card-text>
      <v-text-field
        v-model="search"
        flat
        light
        solo
        background-color="grey lighten-3"
        color="black"
        label="Search Reports"
        prepend-inner-icon="search"
        single-line
        hide-details
      ></v-text-field>

      <div class="caption" v-if="this.$route.name === 'event_reports'">
        To show the submissions per report, open it and use the filter option on
        the data table
      </div>

      <v-data-table
        :search="search"
        :headers="headers"
        :items="values"
        :items-per-page="20"
        :loading="loading"
        no-data-text="We could not find reports now. Check later or reload page"
        loading-text="Loading... Please wait"
      >
        <template v-slot:item.name="{ item }" class="text-left">
          <v-btn text link class="text-left" @click="viewReport(item.id)">
            {{ item.name }}
          </v-btn>
        </template>

        <template v-slot:item.action="{ item }">
          <v-btn dark color="red" @click="deleteReport(item.id)">
            Delete
          </v-btn>
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>
<script>
import FlaggedChart from "../utils/DataTableList.vue";
import axios from "axios";
import ApiService from "@/services/ApiService";
import { mapGetters } from "vuex";

export default {
  name: "Reports",
  components: {
    FlaggedChart,
  },
  data() {
    return {
      loading: true,
      search: null,
      snackbar: false,
      snackMessage: null,
      deleteId: 0,
      response: null,
    };
  },
  computed: {
    ...mapGetters({
      user: "auth/user",
    }),
    headers() {
      return this.response ? this.response.headers : [];
    },
    values() {
      return this.response ? this.response.values : [];
    },
  },
  mounted() {
    switch (this.$route.name) {
      case "project_reports":
        this.getReportsByProject();
        break;
      case "client_reports":
      case "account_reports":
        this.getReportsByClient();
        break;
      case "event_reports":
        this.getReportsByEvent();
        break;
      default:
        this.getReports();
    }
  },
  methods: {
    viewReport(id) {
      this.$router.push({
        name: "report",
        params: { id: id },
      });
    },

    getReports() {
      ApiService.getReports()
        .then((response) => {
          if (response.status === 200) {
            this.response = response.data.data;
            this.loading = false;
          }
        })
        .catch((error) => {
          console.log(error);
          this.loading = false;
        });
    },
    getReportsByProject() {
      ApiService.getReportsByProject(this.$route.params.id)
        .then((response) => {
          if (response.status === 200) {
            this.response = response.data.data;
            this.loading = false;
          }
        })
        .catch((error) => {
          console.log(error);
          this.loading = false;
        });
    },
    getReportsByClient() {
      let id =
        this.$route.name === "account_reports"
          ? this.$route.params.id
          : this.user.id;
      ApiService.getReportsByClient(id)
        .then((response) => {
          if (response.status === 200) {
            this.response = response.data.data;
            this.loading = false;
          }
        })
        .catch((error) => {
          console.log(error);
          this.loading = false;
        });
    },

    getReportsByEvent() {
      ApiService.getReportsByEvent(this.$route.params.id)
        .then((response) => {
          if (response.status === 200) {
            this.response = response.data.data;
            this.loading = false;
          }
        })
        .catch((error) => {
          console.log(error);
          this.loading = false;
        });
    },

    deleteReport(id) {
      axios
        .delete(`/reports/${id}`)
        .then((response) => {
          if (response.status === 200) {
            this.getReports();
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    undoDelete() {
      this.snackbar = false;
      axios
        .post(`/reports/undelete/${this.deleteId}`)
        .then((response) => {
          if (response.status === 200) {
            this.$emit("onReload");
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>
