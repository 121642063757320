<template>
  <v-card tile flat class="d-flex flex-row align-stretch grey lighten-5" height="100%" min-height="100vh">
    <v-navigation-drawer permanent class="flex-shrink-0" color="primary" dark>
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title class="title">
            {{ user.name }}
          </v-list-item-title>
          <v-list-item-subtitle class="mt-3">
            <v-btn text :to="{ name: 'logout' }">Logout</v-btn>
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>

      <v-divider dark></v-divider>

      <div class="caption text-center white--text my-3">Quick Access Panel</div>
      <v-list
          nav
          expand
          dense
          class="text-left">

        <v-list-item link :to="{ name: 'dashboard' }">
          <v-list-item-icon>
            <v-icon>home</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title class="body-2">Dashboard</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item link :to="{ name: 'dashboard_accounts' }">
          <v-list-item-icon>
            <v-icon>face</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title class="body-2">Accounts</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item link :to="{ name: 'dashboard_projects' }">
          <v-list-item-icon>
            <v-icon>assignment</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title class="body-2">Projects</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item link :to="{ name: 'dashboard_campaigns' }">
          <v-list-item-icon>
            <v-icon>work</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title class="body-2">Campaigns</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item link :to="{ name: 'dashboard_reports', params:{reports:reports} }">
          <v-list-item-icon>
            <v-icon>description</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title class="body-2">Reports</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item link :to="{ name: 'locations' }">
          <v-list-item-icon>
            <v-icon>location_on</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title class="body-2">Retail Network</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item link :to="{ name: 'users' }">
          <v-list-item-icon>
            <v-icon>face</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title class="body-2">Users</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
    <div class="flex-grow-1 flex-shrink-1 flex-shrink-0">
      <v-toolbar flat class="title">
        <v-toolbar-title>
          <v-img alt="Hokela" height="60px" width="60px" aspect-ratio="1.4"
                 :src="require('@/assets/ic_logo_blue.svg')"></v-img>
        </v-toolbar-title>
        <v-spacer></v-spacer>

        <v-menu
            transition="slide-y-transition"
            close-on-content-click
            bottom
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
                icon
                light
                v-bind="attrs"
                v-on="on"
            >
              <v-icon>more_vert</v-icon>
            </v-btn>
          </template>

          <v-list>
            <v-list-item>
              <v-list-item-title>
                <v-btn text @click="manageUsers">
                  Manage Accounts
                </v-btn>
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-toolbar>
      <router-view></router-view>
    </div>
  </v-card>
</template>
<script>
import PieChart from "@/components/utils/chart/PieChart";
import DataTableList from "@/components/utils/DataTableList";
import BarChart from "@/components/utils/chart/BarChart";
import HorizontalBar from "@/components/utils/chart/HorizontalBar";
//import MapChart from "@/components/utils/chart/MapChart";
import ApiService from "@/services/ApiService";

export default {
  name: 'dashboard',
  components: {/*MapChart,*/ HorizontalBar, BarChart, DataTableList, PieChart},
  data() {
    return {
      messages: 0,
      show: true,
      accounts: [],
      mapData: [
        {
          name: 'All users',
          phone: '',
          photo_url: '',
          markers: []
        },
      ],
      chartData: {
        checkin: [],
        checkout: [],
        labels: [],
      },
      timestamps: [],
      reports: null,
    }
  },
  computed: {
    charts() {
      return this.chartData;
    },
    map() {
      return this.mapData;
    },
    user() {
      return this.$store.getters["auth/user"];
    }
  },
  mounted() {
    this.getHomePage()
  },
  methods: {
    manageUsers() {
      this.$router.push({
        name: 'create_user'
      });
    },

    getHomePage() {
      ApiService.getHomePage()
          .then((response) => {
            this.chartData = response.data.data.chartData;
          })
          .catch((error) => {
            console.log(error);
          });
    },
    showAccountPage(id) {
      this.$router.push({
        name: 'account',
        params: {id: id}
      });
      this.$root.$emit('save_client_projects', this.users);
    },
  }
}
;
</script>

<style>
</style>
