<template>
  <v-card flat tile color="grey lighten-5" height="100vh">
    <v-row no-gutters>
      <v-col cols="9">
        <v-card height="100%" flat tile class="scroll">
          <v-card-text v-if="overview_data">
            <sales></sales>
          </v-card-text>
          <div v-else>
            <v-overlay absolute width="100%" height="100%">
              <v-progress-circular indeterminate color="red">
              </v-progress-circular>

              <div class="title">
                Crunching data... This page might take a while to load. Please
                be patient
              </div>
            </v-overlay>
          </div>
        </v-card>
      </v-col>
      <v-col cols="3">
        <v-card flat outlined min-height="100vh">
          <v-card-title class="body-2">Report excel exports</v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <div class="d-flex">
              <!-- <v-menu
                v-model="menu2"
                :close-on-content-click="false"
                :nudge-left="48"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="dates.start"
                    dense
                    outlined
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  >
                    <template #label>
                      <span class="text-body-2"> Start date </span>
                    </template>
                  </v-text-field>
                </template>
                <v-date-picker
                  v-model="dates.start"
                  @input="menu2 = false"
                ></v-date-picker>
              </v-menu> -->

              <DatePicker
                label="Start Date"
                :menuOpen.sync="startDateMenuOpen"
                v-model="dates.start"
                :close-on-content-click="false"
                :nudge-left="48"
              />

              <TimePicker
                class="ml-2"
                label="Start Time"
                :menuOpen.sync="startingTimeMenuOpen"
                v-model="dates.startingTime"
                :disabled="dates.start === null"
              />
            </div>

            <div class="d-flex">
              <!-- <v-menu
                v-model="menu2"
                :close-on-content-click="false"
                :nudge-left="48"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="dates.end"
                    outlined
                    dense
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  >
                    <template #label>
                      <span class="text-body-2"> End date </span>
                    </template>
                  </v-text-field>
                </template>
                <v-date-picker
                  v-model="dates.end"
                  @input="menu2 = false"
                ></v-date-picker>
              </v-menu> -->

              <DatePicker
                label="End Date"
                :menuOpen.sync="endDateMenuOpen"
                v-model="dates.end"
                :close-on-content-click="false"
                :nudge-left="48"
              />

              <TimePicker
                class="ml-2"
                label="End Time"
                :menuOpen.sync="endingTimeMenuOpen"
                v-model="dates.endingTime"
                :disabled="dates.end === null"
              />
            </div>

            <v-btn
              block
              color="primary"
              @click="exportReport"
              :loading="exportLoading"
            >
              Export
            </v-btn>

            <v-divider class="my-3"></v-divider>

            <div class="body-2">Recently exported reports</div>
            <v-simple-table>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left">Name</th>
                    <th class="text-left">Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="item in exports" :key="item.name">
                    <td :class="textColor(item.status)">{{ item.name }}</td>
                    <td>
                      <v-btn
                        icon
                        @click="downloadReport(item.name)"
                        autocapitalize="sentences"
                        color="primary"
                        class="caption"
                      >
                        <v-icon>file_download</v-icon>
                      </v-btn>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-card>
</template>
<script>
import CreateCard from "@/components/utils/CreateCard";
import axios from "axios";
import ApiService from "@/services/ApiService";
import Sales from "@/components/reports/modules/Sales";
import TimePicker from "@/components/utils/TimePicker";
import DatePicker from "@/components/utils/DatePicker";

export default {
  components: {
    CreateCard,
    Sales,
    TimePicker,
    DatePicker,
  },
  computed: {
    user_cards() {
      let data = [];
      this.overview_data.user_cards.forEach((item) => {
        if (item !== null && item.id != null) {
          data.push(item);
        }
      });
      return data;
    },

    auto_cards() {
      let data = [];
      this.overview_data.auto_cards.forEach((item) => {
        if (item !== null && item.id != null) {
          data.push(item);
        }
      });
      return data;
    },
  },
  data() {
    return {
      sorting: "",
      select_loader: false,
      analysis: null,
      title: {
        name: "Loading",
        id: 0,
      },
      analysis_type: [
        {
          key: "sales",
          value: "Sales",
        },
        {
          key: "stocks",
          value: "Stocks",
        },
        {
          key: "orders",
          value: "Orders",
        },
        {
          key: "survey",
          value: "Survey",
        },
      ],
      step: 1,
      snackbar: false,
      loading: false,
      report: null,
      exports: [],
      dialog: {
        dialog: false,
        title: "",
        message: "",
        id: 0,
      },
      snackMessage: "All field are required",
      overview_data: null,
      exportLoading: false,
      dates: {
        start: null,
        end: null,
        startingTime: null,
        endingTime: null,
      },
      startDateMenuOpen: false,
      endDateMenuOpen: false,
      startingTimeMenuOpen: false,
      endingTimeMenuOpen: false,
    };
  },
  methods: {
    textColor(status) {
      switch (status) {
        case "Pending":
          return "text-orange";
        case "Done":
          return "text-green";
        case "Failed":
          return "text-red";
      }
    },
    getColor(list, item) {
      switch (list.indexOf(item)) {
        case 0:
          return "green";
        case 1:
          return "orange";
        case 2:
          return "red";
        default:
          return "white";
      }
    },
    downloadBar(index) {
      this.$refs[index][0].downloadChart();
    },
    deleteCard(id) {
      axios
        .delete(`/report/card/${id}`)
        .then((response) => {
          console.log(response);
          this.loadReportData();
        })
        .catch((error) => {
          console.log(error);
        });
    },

    loadReportData() {
      axios
        .get(`/report/card/${this.$route.params.id}`)
        .then((response) => {
          this.overview_data = response.data.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getReport() {
      ApiService.getReportInfo()
        .then((response) => {
          this.report = response.data.data.report;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    exportReport() {
      this.exportLoading = true;
      ApiService.exportReport(this.$route.params.id, this.getExportDateTimes())
        .then((response) => {
          this.exportLoading = false;
          this.snackMessage = response.data.data;
          this.snackbar = true;
        })
        .catch((error) => {
          this.exportLoading = false;
          console.log(error);
        });
    },
    getExportDateTimes() {
      return {
        start: this.getDateTime(this.dates.start, this.dates.startingTime),
        end: this.getDateTime(this.dates.end, this.dates.endingTime),
      };
    },
    getDateTime(date, time) {
      let finalDateTime = null;
      if (date !== null) {
        finalDateTime = date;
        if (time !== null) {
          finalDateTime += ` ${time}:00`;
        }
      }

      return finalDateTime;
    },
    downloadReport(name) {
      ApiService.downloadFiles(name).then(function (resp) {
        const headers = resp.headers;
        const blob = new Blob([resp.data], { type: headers["content-type"] });
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = name;
        link.click();
      });
    },
    getReport() {
      axios
        .get(`/reports/${this.$route.params.id}`)
        .then((response) => {
          this.title = response.data.data;
          //window.document.title = this.report.name;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getExports() {
      ApiService.getReportExports(this.$route.params.id)
        .then((response) => {
          this.exports = response.data.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  mounted() {
    this.getReport();
    this.loadReportData();
    this.getExports();
  },
};
</script>
