<template>
  <VChart :option="option" :style="{ height: '400px' }" />
</template>

<script>
import { use } from "echarts/core";
import { CanvasRenderer } from "echarts/renderers";
import { BarChart } from "echarts/charts";
import {
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  GridComponent,
} from "echarts/components";
import VChart, { THEME_KEY } from "vue-echarts";

use([
  CanvasRenderer,
  BarChart,
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  GridComponent,
]);

export default {
  props: {
    labels: Array,
    charts: Array,
    title: {
      type: String,
      required: false,
      default: "",
    },
    color: {
      type: String,
      required: false,
      default: "#2554FF",
    },
    axisLabelsRotate: {
      type: Number,
      default: -30,
    },
    seriesName: {
      type: String,
      default: "",
    },
  },
  components: {
    VChart,
  },
  provide: {
    [THEME_KEY]: "light",
  },
  data() {
    return { option: {} };
  },
  /*
  data() {
    return {
      options: {
        scales: {
          yAxes: [
            {
              ticks: {
                beginAtZero: true,
              },
              gridLines: {
                display: false,
              },
            },
          ],
          xAxes: [
            {
              gridLines: {
                display: true,
              },
            },
          ],
        },
        legend: {
          display: false,
        },
        responsive: true,
        maintainAspectRatio: false,
      },
      chartData: {
        labels: this.labels,
        datasets: [
          {
            barThickness: 14,
            label: this.title,
            borderWidth: 1,
            borderColor: "rgba(54, 162, 235, 0.2)",
            backgroundColor: this.color,
            pointBorderColor: "#2554FF",
            data: this.charts,
          },
        ],
      },
    };
  },
  */
  methods: {
    loadMap() {
      /*
      this.renderChart(this.chartData, this.options);
      */
    },

    downloadChart() {
      const canvas = this.$refs.canvas;
      const image = canvas.toDataURL("image/jpeg", 1.0);
      let link = document.createElement("a");
      link.download = this.title;
      link.href = image;
      link.click();
    },

    setOption() {
      this.option = {
        title: { text: this.title },
        scales: {
          yAxes: [
            {
              ticks: {
                beginAtZero: true,
              },
              gridLines: {
                display: false,
              },
            },
          ],
          xAxes: [
            {
              gridLines: {
                display: true,
              },
            },
          ],
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
        },
        responsive: true,
        maintainAspectRatio: false,
        legend: {},
        xAxis: {
          type: "value",
          //boundaryGap: [0, 0.01],
          axisLabel: {
            interval: 0,
            rotate: this.axisLabelsRotate, //If the label names are too long you can manage this by rotating the label.
          },
        },
        yAxis: {
          type: "category",
          data: this.labels,
        },
        grid: {
          containLabel: true,
          left: "3%",
          //right: "4%",
          bottom: "3%",
        },
        series: [
          {
            name: this.seriesName,
            type: "bar",
            barWidth: 14,
            stack: "",
            data: this.charts,
            itemStyle: { color: this.color },
          },
        ],
      };
    },
  },
  mounted() {
    this.loadMap();
    this.setOption();
  },
  watch: {
    charts: function () {
      this.loadMap();
      this.setOption();
    },
    labels: function () {
      this.loadMap();
      this.setOption();
    },
  },
};
</script>
