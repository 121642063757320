<template>
    <v-overlay :value="overlay">
        <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
</template>
<script>
    export default {
        data() {
            return {
                overlay: true,
            };
        },
        // mounted() {
        //     this.$store.dispatch('retrieveUser')
        //         .then(res => {
        //             switch (res.data.account_type) {
        //                 case 0:
        //                 case 1:
        //                     this.$router.push({
        //                         name: 'dashboard',
        //                     });
        //                     break;
        //                 case 2:
        //                     this.$router.push({
        //                         name: 'account',
        //                     });
        //                     break;
        //                 case 3:
        //                 case 4:
        //                 case 5:
        //                     this.$router.push({
        //                         name: 'projects',
        //                     });
        //             }
        //         });
        // }
    };
</script>
