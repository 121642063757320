<template>
  <v-card flat height="100%" tile>
    <v-alert :value="alert" :type="alertType" dark transition="scale-transition">
      {{ alertMessage }}
    </v-alert>

    <v-card-text>
      <v-row justify="center" no-gutters>
        <v-card max-width="800" min-width="500" outlined>
          <v-card-subtitle>Add new team leader</v-card-subtitle>
          <v-card-text>
            <v-autocomplete
                v-model="model"
                :items="items"
                full-width
                flat
                light
                solo-inverted
                :search-input.sync="search"
                chips
                clearable
                hide-details
                hide-selected
                item-text="phone"
                item-value="name"
                label="Search registered user by phone number eg 254720000000"
                return-object>

              <template v-slot:no-data>
                <v-list-item>
                  <v-list-item-title>
                    Start typing user
                    <strong>Phone number</strong>
                  </v-list-item-title>
                </v-list-item>
              </template>
              <template v-slot:selection="{ attr, on, item, selected }">
                <v-chip
                    v-bind="attr"
                    :input-value="selected"
                    color="blue-grey"
                    class="white--text"
                    v-on="on"
                >
                  <v-icon left>account_circle</v-icon>

                  <span v-text="item.name"></span>
                </v-chip>
              </template>
              <template v-slot:item="{ item }">
                <v-list-item-avatar
                    color="indigo"
                    class="headline font-weight-light white--text"
                >
                  {{ item.name.charAt(0) }}
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title v-text="item.name"></v-list-item-title>
                  <v-list-item-subtitle v-text="item.phone"></v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action>
                  <v-icon>account_circle</v-icon>
                </v-list-item-action>
              </template>
            </v-autocomplete>

            <v-btn
                class="mt-4 mr-2"
                :loading="loading"
                :disabled="loading"
                block
                color="primary"
                @click="createTeam()"
            >
              Add team leader
            </v-btn>

            <div class="caption mt-4 grey--text lighten-4">If a user is not found, make sure that they have registered
              in
              the Hokela app with
              the provided phone number
            </div>
          </v-card-text>
        </v-card>
      </v-row>
      <v-divider class="mt-4"></v-divider>

      <v-simple-table height="1000">
        <template v-slot:default>
          <thead>
          <tr>
            <th class="text-left">
              #
            </th>
            <th class="text-left">
              Name
            </th>
            <th class="text-left">
              Role
            </th>
            <th class="text-left">
              Added
            </th>
            <th class="text-left">
              Actions
            </th>
          </tr>
          </thead>
          <tbody>
          <tr
              v-for="(item, index) in teams"
              :key="item.id"

          >
            <td class="text-left">{{ index + 1 }}</td>
            <td class="text-left">
              <v-btn link text @click="showTeam(item.id)">
                {{ item.hokela.name }}
              </v-btn>
            </td>
            <td class="text-left">{{ item.user_type===1?'TL':'BA' }}</td>
            <td class="text-left">{{ item.created_at }}</td>
            <td class="text-left ">
              <v-btn small class="ml-4" color="red"
                     @click="deleteTeam(item.id)"
                     dark>
                Remove
              </v-btn>
            </td>
          </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card-text>
  </v-card>
</template>
<script>
import axios from 'axios';
import ApiService from "@/services/ApiService";

export default {

  data() {
    return {
      teams: [],
      loading: false,
      items: [],
      model: null,
      search: null,
      success: false,
      error: false,
      errorText: '',
      successText: '',
      alert: false,
      alertType: 'info',
      alertMessage: '',
      itemsPerPage: 8,
      itemsPerPageArray: [8, 12, 16, 20],
      page: 1,
    };
  },
  methods: {
    deleteTeam(id) {
      this.isLoading = true;

      // get event
      ApiService.deleteTeam(id)
          .then((response) => {
            this.getTeam()
          })
          .catch((error) => {
            console.log(error);
          });
    },
    nextPage() {
      if (this.page + 1 <= this.numberOfPages) this.page += 1
    },
    formerPage() {
      if (this.page - 1 >= 1) this.page -= 1
    },
    updateItemsPerPage(number) {
      this.itemsPerPage = number
    },
    showTeam(id) {
      this.$router.push({name: 'team', params: {id: id}});
    },

    // get event
    querySelections(val) {
      this.isLoading = true;
      axios.get(`/search/users?query=${val}`)
          .then((response) => {
            this.items = response.data;
          })
          .catch((error) => {
            console.log(error);
          })
          .finally(() => (this.isLoading = false));
    },
    createTeam() {
      if (this.model && this.model.phone) {

        axios.defaults.headers.common.Authorization = `Bearer ${this.$store.state.token}`;

        // create team
        axios.post('/teams', this.createTeamObject())
            .then((response) => {
              this.items = response.data;
              this.alertMessage = 'User added successfully';
              this.alertType = 'success';
              this.alert = true;
              this.loading = false;
              this.model = null
              this.getTeam()
            })
            .catch((error) => {
              console.log(error);
              this.alertMessage = 'An error occurred while creating the user. This could be caused by a poor internet connection or you lack the permission to complete the action';
              this.alertType = 'error';
              this.alert = true;
              this.loading = false;
            });
      } else {
        this.alertMessage = 'You cannot add an empty user ';
        this.alertType = 'error';
        this.alert = true;
        this.loading = false;
      }
    },
    createTeamObject() {
      return {
        'id': this.model.id,
        'phone': this.model.phone,
        'event': this.$route.params.id
      };
    },

    getTeam() {
      ApiService.getTeamByEvent(this.$route.params.id)
          .then((response) => {
            this.teams = response.data.data
          })
          .catch((error) => {
            console.log(error)
          })
    }
  },
  computed: {
    numberOfPages() {
      return Math.ceil(this.teams.length / this.itemsPerPage)
    },
  },
  watch: {
    search(val) {
      val && val !== this.select && this.querySelections(val);
    },
    alert() {
      if (this.alert === true) {
        setTimeout(() => (this.alert = false), 3000)
      }
    },

  },
  mounted() {
    this.getTeam()
  }
};

</script>
