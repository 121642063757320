<template>
  <v-card
    tile
    flat
    class="d-flex flex-row align-stretch"
    min-height="100vh"
    height="100%"
  >
    <v-navigation-drawer
      permanent
      class="flex-shrink-0"
      dark
      color="primary"
      min-height="100vh"
      height="100%"
    >
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title class="title">
            {{ user.name }}
          </v-list-item-title>
          <v-list-item-subtitle class="mt-3">
            <v-btn text :to="{ name: 'logout' }">Logout</v-btn>
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>

      <v-divider></v-divider>

      <v-list dense nav expand class="text-left" v-if="response != null">
        <v-list-item link :to="{ name: 'dashboard' }">
          <v-list-item-icon>
            <v-icon>home</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Dashboard</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-divider></v-divider>

        <v-list-item class="caption text-center blue--text"
          >Event Menu Options</v-list-item
        >

        <v-divider></v-divider>

        <v-list-item link :to="{ name: 'event'}">
          <v-list-item-icon>
            <v-icon>assessment</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title class="text-left">Overview</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item link :to="{ name: 'event_team'}"
                     v-if="showUsers">
          <v-list-item-icon>
            <v-icon>group</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title class="text-left"
              >Team Management</v-list-item-title
            >
          </v-list-item-content>
        </v-list-item>

        <v-list-item link :to="{ name: 'event_map' }">
          <v-list-item-icon>
            <v-icon>explore</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title class="text-left">Checkin Map</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item
          link
          :to="{ name: 'event_route'}"
          v-if="response.type === 1 && response.open_plan"
        >
          <v-list-item-icon>
            <v-icon>directions</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title class="text-left">Route Plans</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item
          link
          :to="{ name: 'event_locations' }"
          v-else-if="response.type === 1 && !response.open_plan"
        >
          <v-list-item-icon>
            <v-icon>location_on</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title class="text-left">Locations</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item link :to="{ name: 'event_timestamps' }">
          <v-list-item-icon>
            <v-icon>timelapse</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title class="text-left">Timestamps</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item
          link
          :to="{ name: 'event_images'}"
        >
          <v-list-item-icon>
            <v-icon>insert_photo</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title class="text-left">Images</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-divider></v-divider>

        <v-list-item class="caption text-center blue--text"
          >System shortcuts</v-list-item
        >

        <v-divider></v-divider>

        <v-list-item link :to="{ name: 'locations' }">
          <v-list-item-icon>
            <v-icon>location_on</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Retail Network</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <div class="flex-grow-1 flex-shrink-1 grey lighten-5">
      <v-overlay absolute :value="overlay">
        <v-progress-circular indeterminate size="64"></v-progress-circular>
      </v-overlay>
      <v-toolbar light flat>
        <v-app-bar-nav-icon v-if="response != null">
          <v-icon v-if="!response.open_plan">lock</v-icon>
          <v-icon v-else>lock_open</v-icon>
        </v-app-bar-nav-icon>
        <v-toolbar-title class="headline" v-if="response != null">
          {{ response.name }}
        </v-toolbar-title>

        <v-spacer></v-spacer>
        <v-btn v-if="exports" color="primary" dark @click="dialog = true">
          <v-icon>get_app</v-icon>
          <span>Export report</span>
        </v-btn>

        <v-menu bottom left close-on-click>
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon v-bind="attrs" v-on="on">
              <v-icon>mdi-dots-vertical</v-icon>
            </v-btn>
          </template>

          <v-list>
            <v-list-item>
              <v-list-item-title>
                <edit-event :event="response" />
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-toolbar>
      <router-view></router-view>
    </div>

    <v-dialog v-model="dialog" max-width="500" scrollable>
      <v-card>
        <v-card-title>
          <span>Select export duration</span>
          <v-spacer></v-spacer>
          <v-btn icon @click="dialog = false">
            <v-icon>close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-btn-toggle v-model="exportOptions" dense>
            <v-btn value="day">
              today
            </v-btn>

            <v-btn value="week">
              this week
            </v-btn>

            <v-btn value="month">
              this month
            </v-btn>

            <v-btn value="custom">
              custom
            </v-btn>
          </v-btn-toggle>

          <div class="caption mt-5" v-if="exportOptions === 'custom'">
            {{ getCustomRange }}
          </div>

          <v-date-picker
            class="mt-5"
            full-width
            :min="response.event.created_at"
            v-if="exportOptions === 'custom'"
            v-model="customRange"
            range
          ></v-date-picker>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text color="primary">Export</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>
<script>
import ApiService from "@/services/ApiService";
import { mapGetters } from "vuex";
import EditEvent from "@/components/modals/EditEvent";

export default {
  components: { EditEvent },
  data() {
    return {
      exports: false,
      overlay: false,
      absolute: true,
      tab: null,
      markers: [],
      routes: [],
      response: null,
      images: [],
      info: {},
      mapData: {
        markers: [],
      },
      timestamps: [],
      dialog: false,
      exportOptions: null,
      customRange: [],
    };
  },
  watch: {
    exportOptions(val) {
      console.log(val);
    },
  },
  computed: {
    ...mapGetters({
      user: "auth/user",
    }),
    getCustomRange() {
      switch (this.customRange.length) {
        case 0:
          return "Range not set";
        case 1:
          return `Start: ${this.customRange[0]} - Not Set`;
        case 2:
          return `Start: ${this.customRange[0]} - End: ${this.customRange[1]}`;
      }
    },
    showUsers() {
      if (this.response.type) {
        return this.response.open_plan;
      } else {
        return true;
      }
    },
  },
  mounted() {
    this.getEvent();
  },
  methods: {
    getEvent() {
      ApiService.getEvent(this.$route.params.id)
        .then((response) => {
          this.response = response.data.data;
          this.overlay = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>
