import Vue from 'vue';
import Master from './components/Master.vue';
import router from './router';
import store from './store';
import vuetify from '@/plugins/vuetify';
import VueClipboard from 'vue-clipboard2'
import ECharts from 'vue-echarts'
import Snackbar from './components/utils/Snackbar.vue'
import Sidebar from "@/components/utils/Sidebar";
import VueAnalytics from 'vue-analytics'
import axios from "axios";

axios.defaults.baseURL = `${process.env.VUE_APP_API_URL}/admin/` //'https://develop.hokela.co.ke/api/admin/'

// Add a request interceptor
axios.interceptors.request.use(function (config) {
    let token = localStorage.getItem('access_token');
    if (token) {
        config.headers.Authorization = `Bearer ${token}`;
    }
    axios.defaults.headers.get['Accept'] = 'application/json'
    axios.defaults.headers.post['Accept'] = 'application/json'

    config.headers
    return config;
});

require('@/store/subscriber')

Vue.use(VueClipboard)

Vue.use(VueAnalytics, {
    id: 'G-MC1XVRJJEZ'
})

Vue.component('v-chart', ECharts)

// Local helper components
Vue.component('snackbar', Snackbar)
Vue.component('side-bar', Sidebar)


Vue.config.productionTip = false;

router.beforeEach((to, from, next) => {

    if (to.matched.some(record => record.meta.requiresAuth)) {
        // this route requires auth, check if logged in
        // if not, redirect to login page.
        if (!store.getters["auth/checkAuth"]) {
            next({
                name: 'login',
            });
        } else {
            if (to.name === 'dashboard') {
                let user = store.getters["auth/user"]

                switch (user.account_type) {
                    case 0:
                    case 1:
                        next();
                        break;
                    case 2:
                        next({
                            name: 'account_page'
                        })
                        break;
                    case 3:
                    case 4:
                    case 5:
                        next({
                            name: 'manager',
                        });
                        break;
                }
            }

            next();
        }
    } else if (to.matched.some(record => record.meta.requiresVisitor)) {
        // this route requires auth, check if logged in
        // if not, redirect to login page.
        if (store.getters["auth/checkAuth"]) {
            next({
                name: 'dashboard',
            });
        } else {
            next();
        }
    } else {
    }
});

Vue.config.productionTip = false;

store.dispatch('auth/attempt', localStorage.getItem('access_token'))
    .then(() => {
        new Vue({
            router,
            store,
            vuetify,
            render: h => h(Master),
        }).$mount('#app');
    })
