<template>
  <v-dialog v-model="dialog" max-width="600px" ma="auto">
    <template v-slot:activator="{ on }">
      <v-btn v-if="showActivator" text dark class="text-left" color="black" v-on="on">New Project</v-btn>

      <v-btn v-else dark icon outlined color="primary" large height="105" width="105" v-on="on">
        <v-icon size="100">add</v-icon>
      </v-btn>
    </template>
    <v-card>
      <v-card-title>
        <span class="headline">Create new project</span>
        <v-spacer></v-spacer>
        <v-btn icon @click="dialog=false">
          <v-icon>
            close
          </v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="12">
            <v-text-field outlined v-model="projectName" label="Provide a name for your project"
                          required></v-text-field>
          </v-col>
        </v-row>
        <v-btn color="blue darken-1" :loading="loading" dark block @click="createProject()">
          Create project
        </v-btn>
      </v-card-text>
      <v-card-actions>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import axios from "axios";

export default {
  props: {
    client_id: Number,
    showActivator: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      dialog: false,
      projectName: '',
      user: this.$store.state.user_obj,
      loading: false
    }
  },
  methods: {
    createProject() {
      this.loading = true
      if (this.projectName.length > 0) {
        axios.defaults.headers.common.Authorization = `Bearer ${this.$store.state.token}`;
        axios.post('/projects', this.parseData())
            .then(response => {
              if (response.status === 201) {
                this.dialog = false
                this.loading = false

                this.openProject(response.data.data.id)

              }
            })
            .catch(error => {
              console.log(error);
              this.loading = false
            });
      }
    },

    parseData() {

      if (this.user && this.user.account_type === 2) {

        return {
          'name': this.projectName
        }
      } else {
        return {
          'name': this.projectName,
          'client_id': this.client_id
        }
      }
    },
    openProject(id) {
      this.$router.push({
        name: 'project',
        params: {id: id}
      });
    },
  }
}
</script>
