<template>
    <div>
        <v-row>
            <v-col cols="12">
                <v-card flat>
                    <v-card-title>
                        <v-text-field
                                v-model="search"
                                append-icon="mdi-magnify"
                                label="Search"
                                single-line
                                hide-details
                        ></v-text-field>
                    </v-card-title>
                    <v-data-table
                            :search="search"
                            :headers="headers"
                            :items="this.locations"
                            class="elevation-1"
                            :loading="this.loading"
                            loading-text="Loading... Please wait"
                    >
                    </v-data-table>
                </v-card>
            </v-col>
        </v-row>
    </div>

</template>
<script>
    import axios from "axios";
    import ApiService from "@/services/ApiService";

    export default {
        data() {
            return {
                search: '',
                headers: [
                    {
                        text: 'Location',
                        align: 'start',
                        value: 'name',
                    },
                    {text: 'County', value: 'county'},
                    {text: 'Category', value: 'category'},
                    {text: 'Best Visit Day', value: 'best_visit_day'},
                    {text: 'Local Area', value: 'local_area'},
                    {text: 'Vehicle Option', value: 'vehicle_option'}
                ],
                counties: ['Mombasa', 'Kwale', 'Kilifi', 'Tana River', 'Lamu', 'Taita-Taveta', 'Garissa', 'wajir', 'Mandera', 'Marsabit',
                    'Isiolo', 'Meru', 'Tharaka-Nithi', 'Embu', 'Kitui', 'Machakos', 'Makueni', 'Nyandarua', 'Nyeri', 'Kirinyaga',
                    'Murang\'a', 'Kiambu', 'Turkana', 'West Pokot', 'Samburu', 'Trans Nzoia', 'Uasin Gishu', 'Elgeyo-Marakwet', 'Nandi', 'Baringo',
                    'Laikipia', 'Nakuru', 'Narok', 'Kajiado', 'Kericho', 'Bomet', 'Kakamega', 'Vihiga', 'Bungoma', 'Busia',
                    'Siaya', 'Kisumu', 'Homa Bay', 'Migori', 'Kisii', 'Nyamira', 'Nairobi'],
                categories: ['Wholesaler', 'Shop', 'Supermarket', 'Kiosk'],
                vehicles: ['True', 'False'],
                days: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'],
                loading: false,
                locations: [],
            }
        },
        methods: {
            //Changes the incoming data to be displayed in the table
            //WILL NOT WORK unless it is above the getLocations() function
            parseTableData() {
                this.locations.forEach(location => {
                    location.category = this.categories[location.category - 1];
                    location.best_visit_day = this.days[location.best_visit_day - 1];
                    location.county = this.counties[location.county - 1];
                    location.vehicle_option = (location.vehicle_option === 1) ? 'True' : 'False';
                })
            },

            getLocations() {
                this.loading = true;
                ApiService.getRSMLocations()
                    .then((response) => {
                        this.data = response.data.data.event.locations[0].location;
                        this.locations.push(this.data);
                        this.parseTableData();
                        this.loading = false;
                    })
                    .catch((error) => {
                        console.log(error);
                    });
            },
        },

        mounted() {
            this.getLocations();
        }
    }
</script>
