<template>
    <div class="flat-card">

        <v-app-bar dark color="primary" flat>

            <v-toolbar-title>{{team[0].user}}'s team</v-toolbar-title>

            <v-spacer></v-spacer>

        </v-app-bar>

        <v-container>
            <v-simple-table>
                <template v-slot:default>
                    <thead>
                    <tr>
                        <th class="text-left">User</th>
                        <th class="text-left">Role</th>
                        <th class="text-left">Added on</th>
                        <th class="text-left">Status</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="item in team" :key="item.id">
                        <td class="text-left">{{ item.user }}</td>

                        <td class="text-left">{{item.role}}</td>
                        <td class="text-left">{{item.created}}</td>
                        <td class="text-left">{{item.status}}</td>
                    </tr>
                    </tbody>
                </template>
            </v-simple-table>
        </v-container>
    </div>
</template>

<script>
  import ApiService from "@/services/ApiService";

  export default {
    name: 'team-list',
    data() {
      return {
        tab: null,
        name: 'Loading...',
        team: []
      };
    },
    mounted() {
      this.getTeamData();
    },
    methods: {
      getTeamData() {
        ApiService.getTeamData(this.$route.params.id)
          .then((response) => {
            this.team = response.data.data;
          })
          .catch((error) => {
            console.log(error);
          });
      }
    }
  };
</script>
