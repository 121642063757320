<template>
  <div></div>
</template>

<script>
import {mapActions} from 'vuex'

export default {
  mounted(){
    this.signOut()
  },
  methods: {
    ...mapActions({
      signOutAction: 'auth/signOut'
    }),
    signOut() {
      this.signOutAction().then(() => {
        this.$router.replace({name: 'start'})
      })
    }
  }
};
</script>
