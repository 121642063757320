<template>
  <v-card flat tile height="100vh" min-height="100vh" width="100%">
    <v-overlay absolute width="100%" height="100%" :value="loading">
      <v-progress-circular color="red" indeterminate> </v-progress-circular>
    </v-overlay>
    <v-card flat outlined>
      <v-card-text>
        <v-row justify="space-around">
          <v-col cols="6" sm="3" md="3">
            <v-card height="100" flat tile outlined color="primary">
              <v-card-text>
                <div class="headline white--text">
                  {{ resp.overview.total_users || 0 }}
                </div>
                <div class="white--text caption mt-5">Total users</div>
              </v-card-text>
            </v-card>
          </v-col>

          <v-col cols="6" sm="3" md="3">
            <v-card height="100" flat tile color="green">
              <v-card-text>
                <div class="headline white--text">
                  {{ resp.overview.today_checkins_count || 0 }}
                </div>
                <div class="white--text caption mt-5">Outlets Today</div>
              </v-card-text>
            </v-card>
          </v-col>

          <v-col cols="6" sm="3" md="3">
            <v-card height="100" flat tile color="purple">
              <v-card-text>
                <div class="headline white--text">
                  {{ `${resp.overview.current_active || 0}` }}
                </div>
                <div class="white--text caption darken-3 mt-5">
                  Current Active
                </div>
              </v-card-text>
            </v-card>
          </v-col>
          <!-- <v-col cols="6" sm="3" md="3">
            <v-card height="100" flat tile color="grey darken-2">
              <v-card-text>
                <div class="headline white--text">
                  {{ `${resp.overview.percentage_attendance || 0} %` }}
                  <span class="body-2">
                    {{
                      `(${
                        resp.overview.percentage_attendance_count || 0
                      } Agents)`
                    }}</span
                  >
                </div>
                <div class="white--text caption darken-3 mt-5">
                  Percentage Attendance
                </div>
              </v-card-text>
            </v-card>
          </v-col> -->
          <v-col cols="6" sm="3" md="3">
            <v-card height="100" flat tile color="black">
              <v-card-text>
                <div class="headline white--text">
                  {{
                    totalCheckins == null
                      ? `${resp.overview.average_time || "00:00"}`
                      : totalCheckins
                  }}
                </div>
                <div class="white--text caption darken-3 mt-5">
                  {{
                    totalCheckins == null
                      ? "Average Time/User"
                      : "Total Checkins"
                  }}
                </div>
              </v-card-text>
            </v-card>
          </v-col>
          <!-- <v-col cols="6" sm="3" md="3">
            <v-card height="100" flat tile color="red">
              <v-card-text>
                <div class="headline white--text">
                  {{ `${resp.overview.total_sales || 0}` }}
                </div>
                <div class="white--text caption darken-3 mt-5">Sales today</div>
              </v-card-text>
            </v-card>
          </v-col> -->
          <!-- <v-col cols="6" sm="3" md="3">
            <v-card height="100" flat tile color="primary darken-2">
              <v-card-text>
                <div class="headline white--text">
                  {{ `${resp.overview.total_orders || 0}` }}
                </div>
                <div class="white--text caption darken-3 mt-5">
                  Orders today
                </div>
              </v-card-text>
            </v-card>
          </v-col> -->

          <!-- <v-col cols="6" sm="3" md="3">
            <v-card height="100" flat tile color="orange">
              <v-card-text>
                <div class="headline white--text">
                  {{ `${resp.overview.total_stock || 0}` }}
                </div>
                <div class="white--text caption darken-3 mt-5">
                  Stocks today
                </div>
              </v-card-text>
            </v-card>
          </v-col> -->
        </v-row>
      </v-card-text>
    </v-card>

    <!-- Local checkin list -->
    <v-card outlined flat width="100%" class="mt-4">
      <v-card-title>
        Today's activity
        <v-spacer></v-spacer>
        <v-btn icon @click="exportCheckinsToday" :loading="exportLoading">
          <v-icon> print </v-icon>
        </v-btn>
      </v-card-title>

      <!-- Closed RSM -->
      <v-card-text
        v-if="!resp.event.open_plan"
        outlined
        height="100%"
        color="grey lighten-5"
      >
        <v-card-title class="body-1">
          <span v-if="resp.locations"
            >Assigned Locations ({{ resp.locations.length }})
          </span>
          <v-spacer></v-spacer>
          <span v-if="resp.event.teams">{{
            resp.event.teams[0].hokela.name || ""
          }}</span>
        </v-card-title>
        <v-divider light></v-divider>
        <v-card-text v-if="resp.locations">
          <v-card
            flat
            class="mt-3"
            v-for="(location, index) in resp.locations"
            :dark="location.active === 1"
            :color="location.active === 1 ? 'green' : 'white'"
            :key="index"
          >
            <v-card-text>
              <v-row no-gutters>
                <v-col cols="1">
                  <div v-if="location.active === 0">
                    <v-icon large dark color="grey">schedule</v-icon>
                    <div class="caption">Pending</div>
                  </div>

                  <div v-if="location.active === 1">
                    <v-icon large dark>location_on</v-icon>
                    <div class="caption">Active</div>
                  </div>

                  <div v-if="location.active === 2">
                    <v-icon large dark color="orange">check_circle</v-icon>
                    <div class="caption orange--text">Done</div>
                  </div>
                </v-col>
                <v-col cols="1">
                  <v-divider vertical light></v-divider>
                </v-col>
                <v-col cols="10">
                  <div>
                    <v-row justify="space-between" dense no-gutters>
                      <div class="body-1 font-weight-bold text-left">
                        {{ location.name }}
                      </div>
                      <div>
                        <span class="ml-2"></span>
                      </div>
                    </v-row>
                  </div>
                  <v-divider class="my-4"></v-divider>
                  <v-row justify="space-around">
                    <div class="body-2">
                      Time in:<span class="font-weight-bold ml-2">{{
                        location.checkin_time || "N/A"
                      }}</span>
                    </div>
                    <div class="body-2">
                      Time out:<span class="font-weight-bold ml-2">{{
                        location.checkout_time || "N/A"
                      }}</span>
                    </div>
                    <div class="body-2">
                      Time spent:<span class="font-weight-bold ml-2">{{
                        location.time_spent || "00:00"
                      }}</span>
                    </div>
                  </v-row>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-card-text>
      </v-card-text>

      <!-- Open RSM -->
      <v-card-text v-else-if="resp.event.open_plan">
        <v-data-table
          :headers="openheaders"
          :items="resp.overview.today_checkins"
          :items-per-page="20"
        >
          <template v-slot:item.status="{ item }">
            <v-avatar
              :color="item.status ? 'green' : 'orange'"
              size="10"
            ></v-avatar>
            <span class="ml-3">{{ item.status ? "Live" : "Done" }}</span>
          </template>

          <template v-slot:item.gps="{ item }">
            <v-btn
              small
              text
              link
              @click="showLocation(item.gps)"
              autocapitalize="sentences"
              color="primary"
              class="caption"
            >
              View
            </v-btn>
          </template>

          <template v-slot:item.photo="{ item }">
            <v-btn
              small
              text
              link
              @click="showImage(item.photo)"
              autocapitalize="sentences"
              color="primary"
              class="caption"
            >
              View
            </v-btn>
          </template>
        </v-data-table>
        <div class="caption grey--text lighten-2 mt-4">
          To see timestamps for other days visit the timestamps tab
        </div>
      </v-card-text>

      <v-card-text v-else>
        <v-data-table
          :headers="closedheaders"
          :items="resp.overview.today_checkins"
          :items-per-page="20"
        >
          <template v-slot:item.status="{ item }">
            <v-avatar
              :color="item.status ? 'green' : 'orange'"
              size="10"
            ></v-avatar>
            <span class="ml-3">{{ item.status ? "Live" : "Done" }}</span>
          </template>
        </v-data-table>

        <div class="caption grey--text lighten-2 mt-4">
          To see timestamps for other days visit the timestamps tab
        </div>
      </v-card-text>
    </v-card>

    <v-dialog v-model="dialog" scrollable width="560">
      <v-card min-height="300" width="550">
        <v-card-title>
          <v-spacer></v-spacer>

          <v-btn icon @click="dialog = false">
            <v-icon>close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="text-center">
          <v-img
            v-if="clickedImage"
            contain
            max-width="500"
            max-height="700"
            lazy-src="@assets/placeholder.png"
            :src="`https://develop.hokela.co.ke/api/images?uri=${clickedImage}`"
          >
            <template v-slot:placeholder>
              <v-row class="fill-height ma-0" align="center" justify="center">
                <v-progress-circular
                  indeterminate
                  color="grey lighten-5"
                ></v-progress-circular>
              </v-row>
            </template>
          </v-img>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-card>
</template>
<script>
import ApiService from "@/services/ApiService";

export default {
  computed: {
    event_() {
      return this.resp;
    },
  },
  data() {
    return {
      dialog: null,
      exportLoading: false,
      clickedImage: null,
      resp: {
        event: {
          id: 0,
          name: "Loading...",
          open_plan: false,
        },
        overview: {
          today_checkins_count: 0,
          today_checkins: [
            {
              name: "",
              status: "",
            },
          ],
          total_users: 0,
          current_active: 0,
          total_checkins: null,
        },
      },
      openheaders: [
        { text: "User", value: "name", align: "start" },
        { text: "Location", value: "location", align: "start" },
        { text: "Status", value: "status", align: "start" },
        { text: "Checkin", value: "checkin" },
        { text: "Time spent", value: "time" },
        { text: "Checkout", value: "checkout" },
        { text: "Map", value: "gps" },
        { text: "Photo", value: "photo" },
      ],
      closedheaders: [
        { text: "User", value: "name", align: "start" },
        { text: "Location", value: "location", align: "start" },
        { text: "Status", value: "status", align: "center" },
        { text: "Checkin", value: "checkin" },
        { text: "Time spent", value: "time" },
        { text: "Checkout", value: "checkout" },
        { text: "Map", value: "gps" },
      ],
      activity: [],
      loading: true,
    };
  },
  computed: {
    totalCheckins() {
      if (
        this.resp.overview.total_checkins != null &&
        this.resp.overview.total_checkins != undefined
      ) {
        return this.resp.overview.total_checkins;
      } else {
        return null;
      }
    },
  },
  methods: {
    exportCheckinsToday() {
      this.exportLoading = true;
      ApiService.exportDailyCheckins(this.$route.params.id).then(function (
        resp
      ) {
        const headers = resp.headers;
        const blob = new Blob([resp.data], { type: headers["content-type"] });
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = "Daily_export";
        link.click();

        this.exportLoading = false;
      });
    },
    showLocation(gps) {
      let link = `https://www.google.com/maps/search/?api=1&query=${gps.checkin_lat},${gps.checkin_lng}`;
      window.open(link, "_blank");
    },
    showImage(image) {
      this.dialog = true;
      this.clickedImage = image;
    },

    //Changes chip color
    setStatus(status) {
      if (status === "") {
        return "transparent";
      } else if (status === true) {
        return "green";
      } else if (status === false) {
        return "orange";
      } else return "grey";
    },
    openReport(id) {
      this.$router.push({
        name: "report",
        params: { id: id },
      });
    },
    locationStatus(status) {
      switch (status) {
        case 0:
          return "Pending";
        case 1:
          return "Active";
        case 2:
          return "Completed";
      }
    },
    getEvent() {
      ApiService.getEventOverview(this.$route.params.id)
        .then((response) => {
          this.resp = response.data.data;
          this.loading = false;

          console.log(this.resp);
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  mounted() {
    this.getEvent();
  },
};
</script>
