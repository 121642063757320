<template>
  <v-card flat color="#FAFAFA">
    <v-card-text>
      <v-row>
        <v-col cols="12" sm="6" md="6">
          <v-card max-height="480" flat>
            <v-card-title class="body-1">Weekly activity</v-card-title>
            <v-divider></v-divider>
            <v-card-text>

              <!--  Bar chart  -->
              <bar-chart
                  v-if="chartData.labels.length>0"
                  :charts="chart"
                  :labels="labels"
                  title="Checkins"
              ></bar-chart>
            </v-card-text>
          </v-card>
        </v-col>

        <v-col cols="12" sm="6" md="6">

          <!-- Live events areas -->
          <v-card max-height="480" min-height="480" flat>
            <v-card-title class="body-2">Active events</v-card-title>
            <v-divider></v-divider>
            <v-card-text>
              <div class="text-center heading">
                There are no active events at the moment
              </div>

              <v-list dense max-height="400" class="overflow-x-auto" v-if="spotlight.length>0">
                <v-list-item three-line-line v-for="item in spotlight" @click="viewEvent(item.id)">
                  <v-list-item-content>
                    <v-list-item-title class="text-left">
                      {{ item.name }}
                    </v-list-item-title>

                    <v-list-item-subtitle>
                      <div class="caption text-left">Type:</div>
                    </v-list-item-subtitle>
                    <div>
                      <v-row dense>
                        <v-col cols="4">

                          <v-card
                              max-width="150"
                              flat
                              color="#F0F8FF"
                          >
                            <div class="grey--text caption">Total users</div>
                            <div class="body-1 blue--text">
                              {{ item.teams }}
                            </div>
                          </v-card>
                        </v-col>

                        <v-col cols="4">

                          <v-card
                              max-width="150"
                              flat
                              color="green"
                          >
                            <div class="white--text caption">Active users</div>
                            <div class="body-1 white--text">
                              {{ item.active }}
                            </div>
                          </v-card>
                        </v-col>
                        <v-col cols="4">

                          <v-card
                              max-width="150"
                              flat
                              color="#F0F8FF"
                          >
                            <div class="grey--text caption">Submitted reports</div>
                            <div class="body-1 blue--text">
                              {{ item.reports }}
                            </div>
                          </v-card>
                        </v-col>
                      </v-row>
                    </div>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

      <v-row>

        <!-- Calendar-->
        <v-col cols="12">
          <v-card flat>
            <v-card-title class="body-2">Activity calendar</v-card-title>
            <v-divider></v-divider>
            <v-card-text>
              <v-sheet height="64" class="mt-5">
                <v-toolbar flat color="white">
                  <v-btn outlined class="mr-4" color="primary darken-2" @click="setToday">
                    Today
                  </v-btn>
                  <v-btn fab text small color="primary darken-2" @click="prev">
                    <v-icon small>mdi-chevron-left</v-icon>
                  </v-btn>
                  <v-btn fab text small color="grey darken-2" @click="next">
                    <v-icon small>mdi-chevron-right</v-icon>
                  </v-btn>
                  <v-toolbar-title>{{ title }}</v-toolbar-title>
                  <v-spacer></v-spacer>
                  <v-menu bottom right>
                    <template v-slot:activator="{ on }">
                      <v-btn
                          outlined
                          color="grey darken-2"
                          v-on="on"
                      >
                        <span>{{ typeToLabel[type] }}</span>
                        <v-icon right>mdi-menu-down</v-icon>
                      </v-btn>
                    </template>
                    <v-list>
                      <v-list-item @click="type = 'day'">
                        <v-list-item-title>Day</v-list-item-title>
                      </v-list-item>
                      <v-list-item @click="type = 'week'">
                        <v-list-item-title>Week</v-list-item-title>
                      </v-list-item>
                      <v-list-item @click="type = 'month'">
                        <v-list-item-title>Month</v-list-item-title>
                      </v-list-item>
                      <v-list-item @click="type = '4day'">
                        <v-list-item-title>4 days</v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </v-toolbar>
              </v-sheet>
              <v-sheet>
                <v-calendar
                    ref="calendar"
                    v-model="focus"
                    color="primary"
                    :events="events"
                    :event-color="getEventColor"
                    :now="today"
                    :type="type"
                    @click:event="showEvent"
                    @click:more="viewDay"
                    @click:date="viewDay"
                    @change="updateRange"
                ></v-calendar>
                <v-menu
                    v-model="selectedOpen"
                    :close-on-content-click="false"
                    :activator="selectedElement"
                    offset-x
                    max-width="500px"
                >
                  <v-card
                      width="500px"
                      flat
                  >
                    <v-toolbar
                        dark
                        flat
                        :color="selectedEvent.color"
                    >
                      <v-btn icon>
                        <v-icon>description</v-icon>
                      </v-btn>
                      <v-toolbar-title v-html="selectedEvent.name"></v-toolbar-title>
                      <v-spacer></v-spacer>
                    </v-toolbar>
                    <v-card-text>
                      <v-timeline dense>
                        <v-timeline-item
                            class="text-left"
                            icon="today"
                            fill-dot
                        >
                          Start: {{ selectedEvent.start }}
                        </v-timeline-item>
                        <v-timeline-item
                            class="text-left"
                            icon="event"
                            fill-dot
                        >
                          End: {{ selectedEvent.end }}
                        </v-timeline-item>
                      </v-timeline>
                    </v-card-text>
                    <v-card-actions>
                      <p class="ml-5">{{ selectedEvent.admin }}</p>
                      <v-spacer></v-spacer>
                      <v-btn
                          text
                          color="secondary"
                          @click="selectedOpen = false"
                      >
                        close
                      </v-btn>
                      <v-btn
                          text
                          color="primary"
                          @click="viewEvent(selectedEvent.id)"
                      >
                        view
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-menu>
              </v-sheet>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>
<script>
import axios from "axios";
import BarChart from "../utils/chart/BarChart.vue";

export default {
  components: {
    BarChart,
  },
  data: () => ({
    totalProjects: 10,
    activeProjects: 4,
    focus: '',
    type: 'month',
    typeToLabel: {
      month: 'Month',
      week: 'Week',
      day: 'Day',
      '4day': '4 Days',
    },
    start: null,
    end: null,
    selectedEvent: {},
    selectedElement: null,
    selectedOpen: false,
    events: [],
    spotlight: [],
    activity: [],
    chartData: {
      checkin: [],
      checkout: [],
      labels: [],
    },
  }),
  computed: {
    chart() {
      return this.chartData.checkin;
    },
    labels() {
      return this.chartData.labels
    },
    user_id() {
      return this.$store.state.user_obj.id
    },
    today() {
      let date = new Date()
      return this.formatDate(date, false)
    },
    percentage() {
      return (this.activeProjects / this.totalProjects) * 100;
    },
    title() {
      const {start, end} = this
      if (!start || !end) {
        return ''
      }

      const startMonth = this.monthFormatter(start)
      const endMonth = this.monthFormatter(end)
      const suffixMonth = startMonth === endMonth ? '' : endMonth

      const startYear = start.year
      const endYear = end.year
      const suffixYear = startYear === endYear ? '' : endYear

      const startDay = start.day + this.nth(start.day)
      const endDay = end.day + this.nth(end.day)

      switch (this.type) {
        case 'month':
          return `${startMonth} ${startYear}`
        case 'week':
        case '4day':
          return `${startMonth} ${startDay} ${startYear} - ${suffixMonth} ${endDay} ${suffixYear}`
        case 'day':
          return `${startMonth} ${startDay} ${startYear}`
      }
      return ''
    },
    monthFormatter() {
      return this.$refs.calendar.getFormatter({
        timeZone: 'UTC', month: 'long',
      })
    },
  },
  mounted() {
    this.$refs.calendar.checkChange();
    this.loadData()
    this.getSpotlight()

  },
  methods: {
    loadData() {
      axios.get(`checkins/${this.$route.params.id | 0}`)
          .then((response) => {
            this.chartData = response.data.data.chartData;
          })
          .catch((error) => {
            console.log(error);
          });
    },
    copyLink() {
      this.$copyText(this.link)
          .then(function (e) {
            alert('Copied');
            console.log(e);
          }, function (e) {
            alert('Can not copy');
            console.log(e);
          });
    },
    getSpotlight() {
      /**
       * do a network call for the range
       */

      axios.get(`spotlight/${this.$route.params.id | 0}`)
          .then((response) => {
            this.spotlight = response.data.data;
          })
          .catch((error) => {
            console.log(error);
          });
    },
    viewEvent(id) {
      this.$router.push({
        name: 'event',
        params: {id: id}
      });
    },
    viewDay({date}) {
      this.focus = date;
      this.type = 'day'
    },
    getEventColor(event) {
      return event.color
    },
    setToday() {
      this.focus = this.today
    },
    prev() {
      this.$refs.calendar.prev()
    },
    next() {
      this.$refs.calendar.next()
    },
    showEvent({nativeEvent, event}) {
      const open = () => {
        this.selectedEvent = event
        this.selectedElement = nativeEvent.target
        setTimeout(() => this.selectedOpen = true, 10)
      };

      if (this.selectedOpen) {
        this.selectedOpen = false
        setTimeout(open, 10)
      } else {
        open()
      }

      nativeEvent.stopPropagation()
    },
    updateRange({start, end}) {

      console.log(`start:${start.date} end:${end.date}`);
      let events = [];

      /**
       * do a network call for the range
       */
      axios.defaults.headers.common.Authorization = `Bearer ${this.$store.state.token}`;
      const params = {
        start: start.date,
        end: end.date
      };
      axios.get(`calendar/${this.$route.params.id | 0}`, {params})
          .then((response) => {
            events = response.data.data;
            this.start = start;
            this.end = end;
            this.events = events
          })
          .catch((error) => {
            console.log(error);
          });
    },
    nth(d) {
      return d > 3 && d < 21
          ? 'th'
          : ['th', 'st', 'nd', 'rd', 'th', 'th', 'th', 'th', 'th', 'th'][d % 10]
    },
    formatDate(a, withTime) {
      return withTime
          ? `${a.getFullYear()}-${a.getMonth() + 1}-${a.getDate()} ${a.getHours()}:${a.getMinutes()}`
          : `${a.getFullYear()}-${a.getMonth() + 1}-${a.getDate()}`
    },
  },
}
</script>
