<template>
  <v-navigation-drawer
      height="100%"
      bottom
      expand-on-hover
      mini-variant
      permanent
      fixed
      id="noprint"
  >

    <v-list class="py-0" nav>

      <v-list-item>
        <v-list-item-avatar>
          <v-icon>account_circle</v-icon>
        </v-list-item-avatar>

        <v-list-item-content>
          <v-list-item-title>{{ user_obj.name }}</v-list-item-title>

          <v-list-item-subtitle>{{ getAccountType(user_obj.account_type) }}</v-list-item-subtitle>

          <v-list-item-subtitle>
            <v-btn text color="primary" :to="{ name: 'logout' }">Logout</v-btn>
          </v-list-item-subtitle>

        </v-list-item-content>
      </v-list-item>

      <v-divider></v-divider>

      <v-list-item v-for="(item,key) in user_menu"
                   v-if="!item.id"
                   :to="{ name: item.name}"
                   :key="key"
      >
        <v-list-item-icon>
          <v-icon>{{ item.icon }}</v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title>{{ item.title }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>

<script>

export default {
  data() {
    return {
      drawer: true,
      miniVariant: true,
      expandOnHover: true,
      color: '#fff',
      account_type: 6,
      client_i: 18,
      menu_: [],
      pms: [
        {
          title: 'Projects',
          icon: 'assignment',
          name: 'projects',
        },
        {
          title: 'Tools',
          icon: 'tune',
          name: 'tools'
        },
      ],
      accounts: [
        {
          title: 'Dashboard',
          icon: 'home',
          name: 'account',
        },
        {
          title: 'Help page',
          icon: 'help',
          name: 'help'
        },
      ],
      admins: [
        {
          title: 'Dashboard',
          icon: 'home',
          name: 'dashboard',
        },

        {
          title: 'Accounts',
          icon: 'supervised_user_circle',
          name: 'accounts',
        },
        {
          title: 'Users',
          icon: 'face',
          name: 'users',
        },
        {
          title: 'Tools',
          icon: 'tune',
          name: 'tools'
        },
      ],
    };
  },
  computed: {
    user_obj() {
      let user = this.$store.state.user_obj;
      if (user !== null) {
        return user;
      } else {

        return {
          name: 'Loading',
          account_type: 6,
        };
      }
    },

    user_id() {
      return this.$store.state.user_obj.id
    },

    user_menu() {
      let menu = [];
      switch (this.account_type) {
        case 0:
          menu = this.admins;
          break;
        case 1:
          menu = this.admins;
          break;
        case 2:
          menu = this.accounts;
          break;
        case 3:
          menu = this.pms;
          break;
        case 4:
          menu = this.pms;
          break;
        case 5:
          menu = this.pms;
          break;
      }
      return menu;
    }
  },
  methods: {
    getAccountType(type) {
      this.account_type = type;
      switch (type) {
        case 0:
          type = 'Super Admin';
          break;
        case 1:
          type = 'Admin Assistant';
          break;
        case 2:
          type = 'Account';
          break;
        case 3:
          type = 'Project Manager';
          break;
        case 4:
          type = 'Assistant Project Manager';
          break;
        case 5:
          type = 'Client Service';
          break;
        default:
          type = 'Loading..';
      }
      return type;
    }
  }
};
</script>

<style>
@media print {
  #noprint {
    display: none;
  }
}
</style>
