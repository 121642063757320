<template>
  <l-map
    :style="{ width: '100%', height: '600px' }"
    @click="mapClicked"
    :center="[0.48, 37.84]"
    :zoom="6"
  >
    <l-tile-layer :url="tileUrl"></l-tile-layer>

    <l-marker
      v-for="(marker, index) in markers"
      :key="index"
      :lat-lng="marker.latLng"
    >
      <l-popup>
        Name: {{ marker.title }} <br />
        <template v-if="marker.desc"> Route {{ marker.desc }} <br /> </template>
        Check-in time: {{ marker.checkinTime }} <br />
        <template v-if="marker.checkoutTime"
          >Check-out time: {{ marker.checkoutTime }}
        </template>
      </l-popup>
      >
    </l-marker>
  </l-map>
</template>

<script>
import { LMap, LTileLayer, LMarker, LPopup } from "vue2-leaflet";
import * as turf from "@turf/turf";
import L, { Icon } from "leaflet";
import kenyaGeoJson from "../../../assets/kenya-geo-json.json";
import "leaflet/dist/leaflet.css";

delete Icon.Default.prototype._getIconUrl;
Icon.Default.mergeOptions({
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
});

/*
const testLocations = {
  Nairobi: {
    "Uchumi Nairobi West": [-1.3181401448892966, 36.824392316658994],
    Tmall: [-1.3129681250534437, 36.81676799860959],
    "Gallerial Shopping Mall": [-1.34374414520638, 36.7652349847898],
    "Kamulu Shopping Centre": [-1.2834704350393762, 37.08717750012242],
    "Athi Hardware and Retail": [-1.2825158079969328, 37.08230660859369],
  },
};
*/

const locations = ["NAIROBI", "KISUMU", "MOMBASA", "NAKURU"];

const firstNames = ["John", "James", "Jane", "Jill", "Vanessa", "Matthew"];
const lastNames = ["Oloo", "Baraza", "Kimani", "Amani", "Karanja", "Odhiambo"];

const locationNames = [
  "Uchumi",
  "Naivas",
  "Magharibi Place",
  "Budget Store",
  "Eastmatt",
  "Society Store",
  "Khetia",
];

export default {
  components: {
    LMap,
    LTileLayer,
    LMarker,
    LPopup,
  },
  props: {
    markers: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
    tileUrl: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
    //markers: [],
    geoJsonLayers: {},
  }),
  /*
  async mounted() {
    L.geoJSON(kenyaGeoJson, {
      onEachFeature: (feature, layer) => {
        const name = feature.properties.COUNTY_NAM;
        for (const location of locations) {
          if (name && location === name.trim()) {
            this.geoJsonLayers[location] = layer;
          }
        }
      },
    });
    await this.getMarkers();
  },
  */
  methods: {
    mapClicked(event) {
      console.log(event);
    },

    async getMarkers() {
      this.markers = [];
      const max = 3;
      const min = 5;
      const numberOfMarkers = Math.floor(Math.random() * (max - min) + min);

      Object.entries(this.geoJsonLayers).forEach(([name, layer]) => {
        for (let i = 0; i < numberOfMarkers; i++) {
          const { startTime, endTime } = this.getRandomStartEndTimes();
          this.markers.push({
            latLng: this.getRandomPointInLayer(layer),
            name: this.getRandomName(),
            location: this.getRandomLocationName(),
            checkinTime: startTime,
            checkoutTime: endTime,
          });
        }
      });
    },

    getRandomPointInLayer(layer, timesRan = 0) {
      const bounds = layer.getBounds().pad(-0.9);
      const xMin = bounds.getEast();
      const xMax = bounds.getWest();
      const yMin = bounds.getSouth();
      const yMax = bounds.getNorth();

      const lat = yMin + Math.random() * (yMax - yMin);
      const lng = xMin + Math.random() * (xMax - xMin);

      const point = turf.point([lng, lat]);
      const polygon = layer.feature; //turf.polygon(layer.feature.geometry.coordinates)
      const inside = turf.booleanPointInPolygon(point, polygon);

      if (inside || timesRan > 2) {
        return [point.geometry.coordinates[1], point.geometry.coordinates[0]];
      } else {
        return this.getRandomPointInLayer(layer, ++timesRan);
      }
    },

    getRandomName() {
      const randomFirstName =
        firstNames[Math.floor(Math.random() * firstNames.length)];
      const randomLastName =
        lastNames[Math.floor(Math.random() * lastNames.length)];

      return `${randomFirstName} ${randomLastName}`;
    },

    getRandomLocationName() {
      return locationNames[Math.floor(Math.random() * locationNames.length)];
    },

    getRandomStartEndTimes() {
      const minStartTime = new Date().setHours(8, 0, 0);
      const maxStartTime = new Date().setHours(17, 0, 0);
      const timeBetween = maxStartTime - minStartTime;
      const randomTime = Math.random() * timeBetween;

      const startTime = minStartTime + randomTime;
      const endTime = startTime + 60000 * 5;

      const getMinutes = (timeInMilliseconds) => {
        const minutes = new Date(timeInMilliseconds).getMinutes();
        if (minutes < 10) {
          return `0${minutes}`;
        }
        return minutes;
      };

      return {
        startTime: new Date(startTime).getHours() + ":" + getMinutes(startTime),
        endTime: new Date(endTime).getHours() + ":" + getMinutes(endTime),
      };
    },

    getRandomCoordinates(location = "Nairobi West") {},
  },
};
</script>