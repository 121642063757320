<template>
  <v-card tile flat class="d-flex flex-row align-stretch" min-height="100vh">
    <v-navigation-drawer permanent class="flex-shrink-0" dark color="primary">
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title class="title">
            {{ user.name }}
          </v-list-item-title>
          <v-list-item-subtitle class="mt-3">
            <v-btn text :to="{ name: 'logout' }">Logout</v-btn>
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>

      <v-divider></v-divider>

      <v-list dense nav expand class="text-left">
        <v-list-item link :to="{ name: 'start' }">
          <v-list-item-icon>
            <v-icon>home</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Dashboard</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-divider></v-divider>

        <v-list-item class="caption text-center blue--text"
          >Project Menu Options</v-list-item
        >

        <v-divider></v-divider>

        <v-list-item link :to="{ name: 'project' }">
          <v-list-item-icon>
            <v-icon>assessment</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Overview</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item link :to="{ name: 'project_events' }">
          <v-list-item-icon>
            <v-icon>today</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Campaigns</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item
          link
          :to="{
            name: 'project_reports',
          }"
        >
          <v-list-item-icon>
            <v-icon>description</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Reports</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-divider></v-divider>

        <v-list-item class="caption text-center blue--text"
          >System shortcuts</v-list-item
        >

        <v-divider></v-divider>

        <v-list-item link :to="{ name: 'locations' }">
          <v-list-item-icon>
            <v-icon>location_on</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Retail Network</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <v-card height="100vh" class="flex-grow-1 flex-shrink-1 grey lighten-5">
      <v-toolbar light flat>
        <v-toolbar-title>Project Management</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn text @click="dialog = true">Delete Project</v-btn>
      </v-toolbar>
      <snackbar :color="snackColor" :snackbar="snack" :text="snackMessage" />
      <v-row>
        <v-col cols="12" sm="6">
          <v-card height="92vh" flat outlined :loading="fetching">
            <v-card-subtitle> Manage {{ project.name }} </v-card-subtitle>
            <v-divider></v-divider>
            <v-card-text>
              <v-text-field
                autofocus
                outlined
                filled
                solo
                flat
                v-model="projectName"
                label="Project name"
                required
              ></v-text-field>

              <v-divider></v-divider>
            </v-card-text>
            <v-card-actions>
              <v-btn
                color="blue darken-1"
                dark
                :loading="loading"
                block
                @click="updateProject"
                >Update Project</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-col>
        <v-col cols="12" sm="6" md="6">
          <v-card flat outlined height="92vh" :loading="assignLoading">
            <v-card-subtitle>Manage {{ project.name }} users</v-card-subtitle>
            <v-divider></v-divider>
            <v-card-text>
              <v-autocomplete
                v-model="assigned"
                :items="items"
                :loading="isLoading"
                :search-input.sync="search"
                chips
                solo
                flat
                filled
                outlined
                clearable
                hide-details
                hide-selected
                item-text="email"
                item-value="name"
                hint="Only registered users can be added"
                label="Search user by email or name"
                return-object
              >
                <template v-slot:no-data>
                  <v-list-item>
                    <v-list-item-title>
                      Start typing client
                      <strong>Email</strong>
                    </v-list-item-title>
                  </v-list-item>
                </template>
                <template v-slot:selection="{ attr, on, item, selected }">
                  <v-chip
                    v-bind="attr"
                    :input-value="selected"
                    color="blue-grey"
                    class="white--text"
                    v-on="on"
                  >
                    <v-icon left>account_circle</v-icon>

                    <span v-text="item.name"></span>
                  </v-chip>
                </template>
                <template v-slot:item="{ item }">
                  <v-list-item-avatar
                    color="indigo"
                    class="headline font-weight-light white--text"
                  >
                    {{ item.name.charAt(0) }}
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title v-text="item.name"></v-list-item-title>
                    <v-list-item-subtitle
                      v-text="item.phone"
                    ></v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-action>
                    <v-icon>account_circle</v-icon>
                  </v-list-item-action>
                </template>
              </v-autocomplete>

              <v-btn
                class="my-3"
                dark
                color="primary"
                block
                :loading="assignSubmitLoad"
                @click="addAssigned"
                >Add user to project
              </v-btn>
              <v-divider></v-divider>

              <div class="caption mt-4">
                ({{ assignments.length || "0" }}) users assigned to the project
              </div>
              <v-simple-table>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-left">
                        Name
                      </th>

                      <th class="text-left">
                        Date
                      </th>
                      <th class="text-left">
                        Actions
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="assign in assignments" :key="assign.id">
                      <td class="text-left">{{assign.admin.name }}</td>
                      <td class="text-left">{{ assign.created_at }}</td>
                      <td>
                        <v-btn icon @click="deleteAssigned(assign.id)">
                          <v-icon>delete</v-icon>
                        </v-btn>
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-card>
  </v-card>
</template>

<script>
import ApiService from "@/services/ApiService";
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      dialog: false,
      snack: false,
      snackMessage: "",
      loading: false,
      snackColor: "primary",
      assigned: null,
      fetching: true,
      assignments: [],
      project: {
        name: "",
        assigned: [],
      },
      items: [],
      select: null,
      search: null,
      isLoading: false,
      projectName: null,
      assignLoading: false,
      assignSubmitLoad: false,
    };
  },
  computed: {
    ...mapGetters({
      user: "auth/user",
    }),
  },
  watch: {
    search(val) {
      val && val !== this.select && this.querySelections(val);
    },
    snack(val) {
      if (val === true) {
        setTimeout(() => (this.snack = false), 2000);
      }
    },
  },
  mounted() {
    this.getProject();
    this.getAssigned();
  },
  methods: {
    gotToStart() {
      this.$router.push({
        name: "start",
      });
    },

    // delete Project
    deleteProject() {
      ApiService.deleteProject(this.project.id)
        .then((response) => {
          this.response = response.data.data;
          this.overlay = false;
          this.gotToStart();
        })
        .catch((error) => {
          console.log(error);
        });
    },

    getAssigned() {
      this.assignLoading = true;
      ApiService.getAssigned(this.$route.params.id)
        .then((response) => {
          this.assignments = response.data.data;
          this.assignLoading = false;
        })
        .catch((error) => {
          console.log(error);
          this.snackMessage = error.data.message;
          this.snack = true;
          this.snackColor = "red";
        });
    },
    getProject() {
      this.fetching = true;
      ApiService.getProjectInfo(this.$route.params.id)
        .then((response) => {
          if (response.status === 200) {
            this.project = response.data.data;
            this.fetching = false;
            this.projectName = this.project.name;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    updateProject() {
      this.loading = true;
      ApiService.updateProject(this.$route.params.id, {
        name: this.projectName,
      })
        .then((response) => {
          if (response.status === 200) {
            this.loading = false;
            //this.snackMessage = response.data.message;
            this.snackMessage="Project Updated"
            this.snack = true;
            this.getProject();
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    addAssigned() {
      this.assignSubmitLoad = true;
      let addAssigned = {
        project_id: this.$route.params.id,
        admin_id: this.assigned.id,
      };
      ApiService.addAssigned(addAssigned)
        .then((response) => {
          this.assignSubmitLoad = false;
          if (response.status === 201) {
            this.snackMessage = response.data.message;
            this.snackColor = "green";
            this.snack = true;
            this.assigned = null;
            this.getAssigned();
          } else {
            this.snackMessage = response.data.message;
            this.snackColor = "red";
            this.snack = true;
          }
        })
        .catch((error) => {
          console.log(error);
          this.snackMessage = error.response.data.message;
          this.snackColor = "red";
          this.snack = true;
          this.assignSubmitLoad = false;
        });
    },
    deleteAssigned(id) {
      this.assignLoading = true;
      ApiService.deleteAssigned(id)
        .then((response) => {
          if (response.status === 200) {
            this.assignLoading = false;
            this.snackMessage = response.data.message;
            this.snackbar = true;
            this.getAssigned();
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    querySelections(val) {
      this.isLoading = true;

      ApiService.searchClientService(val)
        .then((response) => {
          this.items = response.data;
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => (this.isLoading = false));
    },
  },
};
</script>
