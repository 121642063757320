<template>
  <v-sheet flat>

    <!--  Snackbar  -->
    <template>
      <div class="text-center">
        <v-snackbar v-model="snackbar" :timeout="3000">
          {{ snackMessage }}
          <template v-slot:action="{ attrs }">
            <v-btn color="blue" text v-bind="attrs" @click="snackbar = false">Close</v-btn>
          </template>
        </v-snackbar>
      </div>
    </template>

    <!--  Help Post Creator   -->
    <v-row justify="center">
      <v-dialog v-model="dialog" persistent max-width="600px">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
              color="primary"
              dark
              v-bind="attrs"
              v-on="on"
              v-if="$store.state.token"
          >
            Add Help Post
          </v-btn>
        </template>
        <v-card>
          <v-card-title>
            <span class="headline ml-auto">Add Help Post</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                      label="Type"
                      v-model="type"
                      required
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                      label="Question"
                      v-model="question"
                      required
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                      label="Answer"
                      v-model="answer"
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-file-input
                      accept="image/*"
                      label="Image">
                  </v-file-input>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="reset">Reset</v-btn>
            <v-btn color="blue darken-1" text @click="dialog = false">Close</v-btn>
            <v-btn color="blue darken-1" text v-if="isChanged" @click="updatePost">Update</v-btn>
            <v-btn color="success" small v-else @click="savePost">Save</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
    <v-card v-if="loading" :loading="loading" height="100vh">Loading</v-card>

    <v-list three-line class="text-left" v-if="!loading">
      <template v-for="(post, i) in helpPosts">
        <v-list-item :key="i">
          <v-list-item-content>
            <v-card width="100%" flat tile @click="showPost(post.id)">
              <v-card-text>
                <v-row no-gutters justify="space-between">
                  <v-col cols="10" md="10" sm="6">
                    <div class="black--text body-1">{{ post.question }}</div>
                    <v-list-item-subtitle class="caption grey--text lighten-2">{{ post.answer }}</v-list-item-subtitle>
                    <v-chip small class="caption mt-3" color="primary" dark>{{ post.type }}</v-chip>
                  </v-col>

                  <v-col cols="0" sm="3" v-if="$vuetify.breakpoint.mdAndUp">
                    <v-btn v-if="$store.state.token" icon dark large color="blue" @click="onEdit(post.id)">
                      <v-icon>edit</v-icon>
                    </v-btn>
                    <v-btn v-if="$store.state.token" icon large dark color="red" @click="onDelete(post.id)">
                      <v-icon>delete</v-icon>
                    </v-btn>
                  </v-col>
                  <v-col cols="2" md="2" sm="3">
                    <v-img height="80" width="50" contain
                           src="https://images.unsplash.com/photo-1593642632823-8f785ba67e45?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1489&q=80"></v-img>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-list-item-content>
        </v-list-item>
        <v-divider></v-divider>
      </template>
    </v-list>
  </v-sheet>
</template>
<script>
import axios from 'axios';

export default {
  name: 'HelpPostsTab',
  data: () => ({
    helpPosts: [],
    dialog: false,
    type: null,
    question: null,
    answer: null,
    loading: false,
    snackbar: false,
    snackMessage: null,
    isChanged: false,
    editedPost: null,
  }),
  mounted() {
    this.getHelpPosts();
  },
  methods: {

    // Gets the active career posts from the server
    getHelpPosts() {
      this.loading = true;
      axios.get("/admin/help")
          .then(response => {
            this.helpPosts = response.data;
            this.loading = false;
          })
          .catch(error => {
            console.log(error);
          });
    },
    showPost(post_id) {
      this.$router.push({name: 'help-post', params: {id: post_id}});
    },

    // Saves a newly created FAQ post
    savePost() {
      axios.post("https://develop.hokela.co.ke/api/admin/help", {
        type: this.type,
        question: this.question,
        answer: this.answer,
      }).then(response => {
        if (response.status === 201) {
          this.getHelpPosts();
          this.dialog = false;
          this.snackbar = true;
          this.snackMessage =
              "New post successful.";
          this.type = null;
          this.question = null;
          this.answer = null;
        } else {
          this.getHelpPosts();
          this.dialog = true;
          this.loading = false;
          this.snackbar = true;
          this.snackMessage =
              "Post failed. Check your internet or try again later.";
        }
      })
    },

    // Edits the selected FAQ post
    onEdit(id) {
      this.dialog = true;
      this.helpPosts.forEach((post) => {
        if (post.id === id) {
          this.isChanged = true;
          this.type = post.type;
          this.question = post.question;
          this.answer = post.answer;
          this.editedPost = post;
        }
      })
    },

    // Updates the edited career post
    updatePost() {
      axios.put(`https://develop.hokela.co.ke/api/admin/help/${this.editedPost.id}`, {
        type: this.type,
        question: this.question,
        answer: this.answer,
      }).then(response => {
        if (response.status === 200) {
          this.getHelpPosts();
          this.dialog = false;
          this.snackbar = true;
          this.snackMessage =
              "Post updated successfully.";
          this.type = null;
          this.question = null;
          this.answer = null;
          this.isChanged = false;
        } else {
          this.getHelpPosts();
          this.dialog = true;
          this.loading = false;
          this.snackbar = true;
          this.snackMessage =
              "Post NOT updated. Check your internet or try again later.";
        }
      })
    },

    // Deletes the selected FAQ post
    onDelete(id) {
      axios.delete(`https://develop.hokela.co.ke/api/admin/help/${id}`)
          .then(response => {
            if (response.status === 200) {
              this.snackbar = true;
              this.snackMessage =
                  `Post deleted.`;
              this.getHelpPosts();
              this.type = null;
              this.question = null;
              this.answer = null;
            } else {
              this.snackbar = true;
              this.snackMessage =
                  `Post NOT deleted. Check your internet or try again later.`;
              this.getHelpPosts();
            }
          })
          .catch(error => console.log(error));
    },
    //
    // Resets input values
    reset() {
      this.type = null;
      this.question = null;
      this.answer = null;
      this.isChanged = false;
    }
  }
}
</script>
