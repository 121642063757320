<template>
  <v-card flat>
    <v-toolbar light flat>
      <v-toolbar-title class="title">Edit report</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn
        class="pa-2 ma-3"
        color="primary"
        dark
        :disabled="name.length === 0 || questions.length === 0"
        :loading="loading"
        @click="handleUploading"
      >
        <v-icon left dark>edit</v-icon>
        Edit report
      </v-btn>
    </v-toolbar>

    <v-card flat color="grey lighten-5">
      <v-card-text>
        <v-card flat>
          <v-card-text>
            <v-text-field
              v-model="name"
              type="text"
              outlined
              label="Enter the report name"
              counter
              maxlength="80"
              autofocus
              autocapitalize="sentences"
            ></v-text-field>
          </v-card-text>
        </v-card>
        <v-card flat tile>
          <div class="body-1" v-if="questions.length === 0">
            Questions added will appear here
          </div>
          <v-list>
            <v-list-group
              v-for="(item, position) in questions"
              :key="position"
              v-model="item.editing"
              :prepend-icon="getIcon(item)"
              no-action
            >
              <template v-slot:activator>
                <v-list-item>
                  <v-list-item-title
                    class="text-left body-1"
                    v-text="item.label"
                  ></v-list-item-title>
                  <v-list-item-action>
                    <v-card flat tile width="200" color="transparent">
                      <v-row>
                        <v-btn
                          small
                          text
                          @click="sortUp(position)"
                          :disabled="position == 0"
                        >
                          <v-icon>arrow_upward</v-icon>
                        </v-btn>

                        <v-btn
                          small
                          text
                          @click="sortDown(position)"
                          :disabled="position == questions.length - 1"
                        >
                          <v-icon>arrow_downward</v-icon>
                        </v-btn>

                        <v-btn small text @click="deleteQuestion(item)">
                          <v-icon>close</v-icon>
                        </v-btn>
                      </v-row>
                    </v-card>
                  </v-list-item-action>
                </v-list-item>
              </template>

              <v-list-item>
                <v-list-item-content>
                  <v-row justify="space-around">
                    <v-col cols="6">
                      <v-text-field
                        outlined
                        v-model="item.label"
                        maxlength="80"
                        counter
                        label="Edit question"
                      >
                      </v-text-field>
                    </v-col>
                    <v-col cols="6">
                      <v-text-field
                        outlined
                        maxlength="80"
                        counter
                        v-model="item.hint"
                        label="Add question hint"
                      >
                      </v-text-field>
                    </v-col>
                    <v-col
                      cols="12"
                      v-if="item.type === 'select' || item.type === 'multiple'"
                    >
                      <!--  Select questions   -->
                      <v-card
                        outlined
                        flat
                        v-if="
                          item.type === 'select' ||
                          item.type === 'dropdown' ||
                          item.type === 'multiple'
                        "
                        class="pa-3"
                      >
                        <v-row>
                          <v-col>
                            <v-text-field
                              dense
                              outlined
                              clearable
                              v-model="select"
                              label="Type a selection option"
                            ></v-text-field>
                          </v-col>
                          <v-col>
                            <v-checkbox
                              v-if="item.type === 'select'"
                              :disabled="disabled"
                              v-model="def"
                              :label="`Default selection`"
                            ></v-checkbox>
                          </v-col>
                          <v-col>
                            <v-btn
                              color="primary"
                              @click="addSelection(position)"
                              >Add selection</v-btn
                            >
                          </v-col>
                        </v-row>
                        <v-divider></v-divider>
                        <v-row align="center" justify="start" class="mt-3">
                          <v-col
                            v-for="(selection, index) in item.selections"
                            :key="selection.option"
                            class="shrink"
                          >
                            <v-chip
                              close
                              :dark="selection.def"
                              @click:close="removeSelection(item, index)"
                            >
                              {{ selection.option }}
                            </v-chip>
                          </v-col>
                        </v-row>
                      </v-card>
                    </v-col>

                    <v-col cols="3">
                      <v-select
                        v-model="item.type"
                        :items="questionTypes"
                        dense
                        outlined
                        label="Edit question type"
                        item-text="name"
                        item-value="type"
                      >
                        <template v-slot:selection="{ item }">
                          <v-icon>{{ item.icon }}</v-icon>
                          <span class="body-2 ml-2">{{ item.name }}</span>
                        </template>

                        <template v-slot:item="{ item, on }">
                          <v-list-item v-on="on">
                            <v-list-item-icon>
                              <v-icon>{{ item.icon }}</v-icon>
                            </v-list-item-icon>
                            <v-list-item-title class="text-left">
                              {{ item.name }}
                            </v-list-item-title>
                          </v-list-item>
                          <v-divider></v-divider>
                        </template>
                      </v-select>
                    </v-col>

                    <v-col cols="3">
                      <v-switch
                        class="caption"
                        dense
                        label="Set question mandatory"
                        v-model="item.required"
                      ></v-switch>
                    </v-col>
                    <v-col cols="3">
                      <v-btn text> Add Rules </v-btn>
                    </v-col>

                    <v-col
                      cols="3"
                      v-if="item.type === 'number' || item.type === 'matrix'"
                    >
                      <v-select
                        label="Question analysis"
                        :items="questionAnalysis"
                        v-model="item.analysis_type"
                        item-text="name"
                        item-value="value"
                        outlined
                        dense
                      ></v-select>
                    </v-col>

                    <v-col cols="12">
                      <v-card flat>
                        <v-row>
                          <v-col cols="5">
                            <v-text-field
                              dense
                              outlined
                              label="Add question value (Keep it short)"
                              v-model="matrixValue"
                              maxlength="50"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="4">
                            <v-select
                              v-model="matrixType"
                              :items="matrixQuestionType"
                              dense
                              outlined
                              label="Select value type"
                              item-text="name"
                              item-value="type"
                            >
                              <template v-slot:selection="{ item }">
                                <v-icon>{{ item.icon }}</v-icon>
                                <span class="body-2 ml-2">{{ item.name }}</span>
                              </template>

                              <template v-slot:item="{ item, on }">
                                <v-list-item v-on="on">
                                  <v-list-item-icon>
                                    <v-icon>{{ item.icon }}</v-icon>
                                  </v-list-item-icon>
                                  <v-list-item-title class="text-left">
                                    {{ item.name }}
                                  </v-list-item-title>
                                </v-list-item>
                                <v-divider></v-divider>
                              </template>
                            </v-select>
                          </v-col>
                          <v-col cols="2">
                            <v-btn
                              primary
                              dark
                              color="primary"
                              maxlength="50"
                              counter
                              @click="addMatrix(position)"
                            >
                              Add Question
                            </v-btn>
                          </v-col>
                        </v-row>
                        <span class="red--text">
                          Disclaimer: Question analysis works with number
                          matrices</span
                        >
                        <v-list class="mt-2 left" dense width="350" outlined>
                          <v-list-item
                            v-for="(matrix, index) in item.matrix"
                            :key="index"
                          >
                            <v-list-item-icon>
                              <v-icon class="mt-3">{{
                                matrix.type == "text" ? "text_fields" : "money"
                              }}</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                              <v-list-item-title
                                v-text="matrix.title"
                              ></v-list-item-title>
                            </v-list-item-content>

                            <v-list-item-action>
                              <v-btn icon @click="item.matrix.splice(index,1)">
                                <v-icon>close</v-icon>
                              </v-btn>
                            </v-list-item-action>
                          </v-list-item>
                        </v-list>
                      </v-card>
                    </v-col>
                  </v-row>
                </v-list-item-content>
              </v-list-item>
            </v-list-group>
          </v-list>
        </v-card>

        <!--   Edit Area   -->
        <v-card flat outlined>
          <v-card-title class="body-2">Add new questions</v-card-title>
          <v-card-text>
            <v-row>
              <v-col cols="12" md="6">
                <v-text-field
                  type="text"
                  v-model="label"
                  label="Create a new question"
                  autocapitalize="sentences"
                  outlined
                  counter
                  dense
                  maxlength="80"
                >
                </v-text-field>
              </v-col>

              <v-col cols="12" md="3">
                <v-select
                  v-model="questionType"
                  :items="questionTypes"
                  dense
                  outlined
                  label="Select question type"
                  item-text="name"
                  item-value="type"
                >
                  <template v-slot:selection="{ item }">
                    <v-icon>{{ item.icon }}</v-icon>
                    <span class="body-2 ml-2">{{ item.name }}</span>
                  </template>

                  <template v-slot:item="{ item, on }">
                    <v-list-item v-on="on">
                      <v-list-item-icon>
                        <v-icon>{{ item.icon }}</v-icon>
                      </v-list-item-icon>
                      <v-list-item-title class="text-left">
                        {{ item.name }}
                      </v-list-item-title>
                    </v-list-item>
                    <v-divider></v-divider>
                  </template>
                </v-select>
              </v-col>
              <v-col cols="12" md="2">
                <v-btn dark color="primary" @click="addQuestion"
                  >Create Question</v-btn
                >
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-card-text>
    </v-card>

    <v-snackbar left v-model="snackbar" color="primary">
      {{ snackMessage }}

      <template v-slot:action="{ attrs }">
        <v-btn
          color="white"
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
import axios from "axios";
import ApiService from "@/services/ApiService";

export default {
  data() {
    return {
      questionType: null,
      questionTypes: [
        {
          name: "Text value",
          type: "text",
          icon: "text_fields",
        },
        {
          name: "Number Value",
          type: "number",
          icon: "money",
        },
        {
          name: "GPS Location",
          type: "gps",
          icon: "location_on",
        },
        {
          name: "Open Camera",
          type: "camera",
          icon: "camera_alt",
        },
        {
          name: "Select Options",
          type: "select",
          icon: "radio_button_checked",
        },
        {
          name: "Mutliple Select",
          type: "multiple",
          icon: "check_box",
        },
        {
          name: "Dropdown Options",
          type: "dropdown",
          icon: "arrow_drop_down",
        },
        {
          name: "Decimal Value",
          type: "photo",
          icon: "money",
        },
        {
          name: "Attach Photo",
          type: "decimal",
          icon: "insert_photo",
        },
        {
          name: "Attach File",
          type: "file",
          icon: "attachment",
        },
        {
          name: "Select Date",
          type: "calendar",
          icon: "calendar_today",
        },
        {
          name: "Select Time",
          type: "time",
          icon: "watch_later",
        },
        {
          name: "Question Matrix",
          type: "matrix",
          icon: "list_alt",
        },
      ],
      snackbar: false,
      snackMessage: null,
      snackColor: "primary",
      hidden: true,
      alert: false,
      edit: null,
      loading: false,
      loader: null,
      label: "",
      name: "",
      alertMessage: "",
      responseMessage: "",
      alertType: "info",
      infoAlert: false,
      questions: [],
      select: null,
      def: false,
      disabled: false,
      report_type: null,
      matrix: null,
      category: null,
      questionAnalysis: [
        {
          name: "Default",
          value: 0,
        },
        {
          name: "Sales",
          value: 1,
        },
        {
          name: "Order",
          value: 2,
        },
        {
          name: "Stock",
          value: 3,
        },
        {
          name: "Customer",
          value: 4,
        },
      ],
      reportCategories: [
        {
          name: "Order report",
          value: 1,
        },
        {
          name: "Stock report",
          value: 2,
        },
        {
          name: "Sales report",
          value: 3,
        },
        {
          name: "Other report",
          value: 0,
        },
      ],
      matrixValue: null,
      matrixType: "string",
      matrixQuestionType: [
        {
          name: "Text value",
          type: "text",
          icon: "text_fields",
        },
        {
          name: "Number Value",
          type: "number",
          icon: "money",
        },
      ],
      fetchingReport: true,
    };
  },

  async mounted() {
    await this.getReport();
  },

  methods: {
    sortUp(index) {
      const newQuestion = [...this.questions];
      newQuestion[index] = this.questions[index - 1];
      newQuestion[index - 1] = this.questions[index];
      this.questions = newQuestion;
      // this.$emit("input", newQuestion);
    },

    sortDown(index) {
      const newQuestion = [...this.questions];
      newQuestion[index] = this.questions[index + 1];
      newQuestion[index + 1] = this.questions[index];
      this.questions = newQuestion;
      // this.$emit("input", newQuestion);
    },

    getIcon(item) {
      return this.questionTypes.find((element) => element.type === item.type)
        .icon;
    },
    addMatrix(index) {
      this.questions[index].matrix.push({
        type: this.matrixType,
        title: this.matrixValue,
      });

      this.matrixType = null;
      this.matrixValue = null;
    },
    addCategory(index) {
      this.questions[index].matrix.push({
        label: this.category,
        questions: [],
      });
      this.category = null;
    },
    addQuestion() {
      if (this.label.trim().length === 0) {
        this.snackMessage = "The question cannot be empty";
        this.snackColor = "red";
        //this.snackbar = true;
        return;
      }
      if (this.questionType == null) {
        this.snackMessage = "The question type cannot be empty";
        this.snackColor = "red";
        //this.snackbar = true;
        return;
      }

      this.questions.push({
        label: this.label,
        type: this.questionType,
        required: false,
        selections: [],
        matrix: [],
        hint: "",
        editing: false,
        analysis_type: 0,
        position: this.questions.length,
      });
      console.log(this.questions);
      this.label = "";
      this.questionType = "";

      // Before we start editing the new question
      // Make sure one can set default
      this.disabled = false;
    },
    deleteQuestion(question) {
      this.questions.splice(this.questions.indexOf(question), 1);
      this.snackMessage = `${question.label} has been deleted.`;
      this.snackColor = "primary";
      //this.snackbar = true;
    },
    handleUploading() {
      this.loader = "loading";
      axios
        .post(`/reports/edit/${this.$route.params.id}`, this.parseReportData())
        .then((response) => {
          this.loading = false;

          this.snackMessage = "Report Edited";
          this.snackbar = true;
          /*
          if (response.status === 201) {
            this.loader = null;
            this.questions = [];
            this.name = "";
            this.responseMessage =
              "Report created successfully. Its will be visible to users assigned to this event only";
            this.alertType = "success";
            this.infoAlert = true;
          }
          */
        })
        .catch((error) => {
          console.log(error);
          this.loading = null;
          this.responseMessage =
            "Something went wrong. Try again after a while or contact the admin";
          this.alertType = "error";
          this.infoAlert = true;
        });
    },

    parseReportData() {
      return {
        event_id: this.$route.params.id,
        questions: this.questions,
        name: this.name,
        project_id: this.$route.params.id,
        category: this.report_type,
      };
    },

    addSelection(id) {
      if (this.select) {
        this.questions[id].selections.push({
          option: this.select,
          def: this.def,
        });
        if (this.def) {
          this.disabled = true;
        }
        this.select = null;
        this.def = false;
      }
    },

    removeSelection(question, i) {
      if (question.selections[i].def === true) {
        this.disabled = false;
      }
      question.selections.splice(i, 1);
    },

    duplicateReport(report) {
      this.name = report.name;
      this.questions = report.questions;
    },

    async getReport() {
      const {
        data: { data: report },
      } = await ApiService.getReportWithQuestions(this.$route.params.id);
      this.name = report.name;
      this.questions = report.questions;
    },

    /**
     * call this code when the api returns a response
     *
     //setTimeout(() => (this[l] = false), 3000);
     //this.loader = null;
     **/
  },
  computed: {
    ...mapGetters({
      user: "auth/user",
    }),
  },

  watch: {
    loader() {
      const l = this.loader;
      this[l] = !this[l];
    },
    selected() {
      this.select = null;
    },
  },
};
</script>
