<template>
  <v-snackbar v-model="snackbar" :color="color" :timeout="timeout"
              dark
              absolute
              right
              shaped
              bottom>
    {{ text }}
  </v-snackbar>
</template>
<script>
export default {
  props: {
    text: {
      type: String,
      required: true
    },
    timeout: {
      type: Number,
      default: 2000
    },

    color: {
      type: String,
      default: 'primary'
    },

    snackbar: {
      type: Boolean,
      default: false
    }
  },
  // watch: {
  //   snackbar(val) {
  //     if (val === true){
  //       console.log(this.timeout)
  //      setTimeout(() => this.snackbar = false, this.timeout)
  //     }
  //   },
  // },
}
</script>
