<template>
  <v-content>
    <v-app-bar dark flat color="transparent" fixed>
      <v-spacer v-if="!this.$vuetify.breakpoint.xs"></v-spacer>
      <div class="hidden-xs-only">
        <solutions />
        <contact />
        <v-btn text link color="white" class="body-2" @click="openLogin"
          >Login</v-btn
        >
      </div>
      <v-spacer v-if="this.$vuetify.breakpoint.xs"></v-spacer>
      <v-app-bar-nav-icon
        @click.stop="drawer = !drawer"
        class="hidden-sm-and-up"
      ></v-app-bar-nav-icon>
    </v-app-bar>

    <v-navigation-drawer v-model="drawer" absolute dark left temporary>
      <div class="text-left mt-5">
        <v-spacer></v-spacer>
      </div>
      <div class="text-left mt-3">
        <solutions />
      </div>
      <div class="text-left mt-3">
        <contact />
      </div>
      <div class="text-left mt-3">
        <v-btn text link color="white" class="body-2" @click="openLogin"
          >Dashboard Login</v-btn
        >
      </div>
    </v-navigation-drawer>

    <!--   Carousel for sm and larger screens     -->
    <v-carousel
      cycle
      hide-delimiter-background
      hide-delimiters
      :show-arrows="false"
      :height="
        $vuetify.breakpoint.md || $vuetify.breakpoint.sm ? '40vh' : '100vh'
      "
      interval="5000"
      v-if="!this.$vuetify.breakpoint.xs"
    >
      <v-carousel-item v-for="(image, i) in slides" :key="i" eager>
        <v-img :src="image.imgSrc" eager></v-img>
      </v-carousel-item>
    </v-carousel>

    <!--   Carousel for xs screens     -->
    <v-carousel
      cycle
      hide-delimiter-background
      hide-delimiters
      :show-arrows="false"
      height="100vh"
      interval="5000"
      v-if="this.$vuetify.breakpoint.xs"
    >
      <v-carousel-item
        v-for="(image, i) in mobSlides"
        :key="i"
        height="100%"
        eager
      >
        <v-img :src="image.imgSrc" eager></v-img>
      </v-carousel-item>
    </v-carousel>
    <About />
    <v-container fluid class="mt-5">
      <div class="title blue--text mr-12 text-center">
        Companies we help grow
      </div>
      <v-row justify="space-around">
        <v-col cols="6" sm="2" lg="2">
          <v-img
            aspect-ratio="1"
            height="150"
            width="150"
            contain
            :src="require('@/assets/bp_logo.svg')"
            class="ma-auto"
          ></v-img>
        </v-col>
        <v-col cols="6" sm="2" lg="2" align="center">
          <v-card
            link
            href="https://letadrinks.com/"
            target="_blank"
            align="center"
            flat
            color="transparent"
          >
            <v-img
              aspect-ratio="1"
              height="150"
              width="150"
              contain
              :src="require('@/assets/leta_drinks_gold.png')"
              class="ma-auto"
            ></v-img>
          </v-card>
        </v-col>
        <v-col cols="6" sm="2" lg="2">
          <v-card
            link
            href="http://briskmarketing.co.ke"
            target="_blank"
            flat
            color="transparent"
          >
            <v-img
              aspect-ratio="1"
              contain
              height="150"
              width="150"
              :src="require('@/assets/brisk_logo.svg')"
              class="ma-auto"
            ></v-img>
          </v-card>
        </v-col>
        <v-col cols="6" sm="2" lg="2">
          <v-img
            aspect-ratio="1"
            contain
            height="150"
            width="150"
            :src="require('@/assets/checkout_logo.png')"
            class="ma-auto"
          ></v-img>
        </v-col>
        <v-col cols="6" sm="2" lg="2">
          <v-card
            link
            href="https://qikapu.com/"
            target="_blank"
            flat
            color="transparent"
          >
            <v-img
              aspect-ratio="1"
              contain
              height="100"
              width="100"
              class="mt-sm-5 ma-auto"
              :src="require('@/assets/qikapu_logo.png')"
            ></v-img>
          </v-card>
        </v-col>
        <v-col cols="6" sm="2" lg="2">
          <v-card link href="#" target="_blank" flat color="transparent">
            <v-img
              aspect-ratio="1"
              contain
              height="100"
              width="100"
              class="mt-sm-5 ma-auto"
              :src="require('@/assets/eabl.svg')"
            ></v-img>
          </v-card>
        </v-col>
        <v-col cols="6" sm="2" lg="2">
          <v-card link href="#" target="_blank" flat color="transparent">
            <v-img
              aspect-ratio="1"
              contain
              height="100"
              width="100"
              class="mt-sm-5 ma-auto"
              :src="require('@/assets/fruitville.svg')"
            ></v-img>
          </v-card>
        </v-col>
        <v-col cols="6" sm="2" lg="2">
          <v-card link href="#" target="_blank" flat color="transparent">
            <v-img
              aspect-ratio="1"
              contain
              height="100"
              width="100"
              class="mt-sm-5 ma-auto"
              :src="require('@/assets/exp.svg')"
            ></v-img>
          </v-card>
        </v-col>
        <v-col cols="6" sm="2" lg="2">
          <v-card link href="#" target="_blank" flat color="transparent">
            <v-img
              aspect-ratio="1"
              contain
              height="100"
              width="100"
              class="mt-sm-5 ma-auto"
              :src="require('@/assets/jaggermeister.svg')"
            ></v-img>
          </v-card>
        </v-col>
        <v-col cols="6" sm="2" lg="2">
          <v-card link href="#" target="_blank" flat color="transparent">
            <v-img
              aspect-ratio="1"
              contain
              height="100"
              width="100"
              class="mt-sm-5 ma-auto"
              :src="require('@/assets/jetlak.svg')"
            ></v-img>
          </v-card>
        </v-col>
        <v-col cols="6" sm="2" lg="2">
          <v-card link href="#" target="_blank" flat color="transparent">
            <v-img
              aspect-ratio="1"
              contain
              height="100"
              width="100"
              class="mt-sm-5 ma-auto"
              :src="require('@/assets/savannah.svg')"
            ></v-img>
          </v-card>
        </v-col>
        <v-col cols="6" sm="2" lg="2">
          <v-card link href="#" target="_blank" flat color="transparent">
            <v-img
              aspect-ratio="1"
              contain
              height="100"
              width="100"
              class="mt-sm-5 ma-auto"
              :src="require('@/assets/snickers.svg')"
            ></v-img>
          </v-card>
        </v-col>
        <v-col cols="6" sm="2" lg="2">
          <v-card link href="#" target="_blank" flat color="transparent">
            <v-img
              aspect-ratio="1"
              contain
              height="100"
              width="100"
              class="mt-sm-5 ma-auto"
              :src="require('@/assets/tetrapark.svg')"
            ></v-img>
          </v-card>
        </v-col>
        <v-col cols="6" sm="2" lg="2">
          <v-card link href="#" target="_blank" flat color="transparent">
            <v-img
              aspect-ratio="1"
              contain
              height="100"
              width="100"
              class="mt-sm-5 ma-auto"
              :src="require('@/assets/unga.svg')"
            ></v-img>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <v-footer color="black" padless dark>
      <v-row justify="space-around" no-gutters>
        <v-col cols="12" sm="3" lg="3" align="center">
          <a
            href="https://play.google.com/store/apps/details?id=co.ke.hokela&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1"
          >
            <v-img
              width="100"
              height="100"
              contain
              alt="Get it on Google Play"
              src="https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png"
            >
            </v-img>
          </a>
        </v-col>
        <v-col cols="12" sm="3" lg="3">
          <v-btn
            color="grey"
            text
            rounded
            class="my-3 body-1"
            @click="openCareersPage"
          >
            Careers
          </v-btn>
        </v-col>
        <v-col cols="12" sm="3" lg="3">
          <v-btn color="grey" text rounded class="my-3 body-1">
            Privacy policy
          </v-btn>
        </v-col>
        <v-col class="black py-4 text-center white--text" cols="12">
          {{ new Date().getFullYear() }} —
          <strong class="body-1">Hokela Interactive Africa</strong>
        </v-col>
      </v-row>
    </v-footer>
  </v-content>
</template>

<script>
import Contact from "../modals/Contact.vue";
import Solutions from "../modals/Solutions.vue";
import About from "./About";

export default {
  components: {
    Contact,
    Solutions,
    About,
  },
  data() {
    return {
      drawer: false,
      slides: [
        { imgSrc: require("@/assets/sliderimages/img-01.png") },
        { imgSrc: require("@/assets/sliderimages/img-02.png") },
        { imgSrc: require("@/assets/sliderimages/img-03.png") },
        { imgSrc: require("@/assets/sliderimages/img-04.png") },
      ],
      mobSlides: [
        { imgSrc: require("@/assets/mobileimages/img-01.png") },
        { imgSrc: require("@/assets/mobileimages/img-02.png") },
        { imgSrc: require("@/assets/mobileimages/img-03.png") },
        { imgSrc: require("@/assets/mobileimages/img-04.png") },
      ],
    };
  },
  methods: {
    openLogin() {
      this.$router.push("login");
    },
    openCareersPage() {
      this.$router.push({ name: "careers" });
    },
  },
};
</script>
