<script>
import { POINT_MARKER_ICON_CONFIG } from "./constants/mapSettings.js";

export default {
  props: {
    google: {
      type: Object,
      required: true,
    },
    map: {
      type: Object,
      required: true,
    },
    marker: {
      type: Object,
      required: true,
    },
    cluster: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      contentString:
        '<div class="mx-auto" style="height: 150px; width: 300px; padding: 10px">\n' +
        '  <div class = "d-flex">\n' +
        '<object data="https://hokela.co.ke/img/ic_logo_blue.3883b8e5.svg" type="image/png" height="60px" width="60px">' +
        '  <img v-if="" class="v-avatar v-list-item__avatar"  src="' +
        `https://develop.hokela.co.ke/api/images?uri=${this.marker.position
          .src || "placeholder"}` +
        '"  alt="Avatar" style="height: 60px; width: 60px">\n' +
        "</object>" +
        '<div class="v-list-item__content">' +
        '    <div class="v-list-item__title headline">' +
        `${this.marker.position.title || ""}` +
        "</div> \n" +
        "</div>" +
        "    </div>\n" +
        '    <p class="body-2">' +
        `${this.marker.position.desc || ""}` +
        "</p> \n" +
        "</div>",
    };
  },
  mounted() {
    const { InfoWindow } = this.google.maps;

    const { Marker } = this.google.maps;

    const marker = new Marker({
      position: this.marker.position,
      marker: this.marker,
      map: this.map,
      title: this.marker.position.title,
    });

    // Add a marker clusterer to manage the markers.
    const cluster = new MarkerClusterer(this.google.maps, this.marker, {
      imagePath:
        "https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m",
    });

    if (this.marker.position.title) {
      const infoWindow = new InfoWindow({
        content: this.contentString,
      });

      marker.addListener("click", function() {
        console.log("Clicked");
        infoWindow.open(this.google, marker);
      });
    }
  },

  render() {},
};
</script>
