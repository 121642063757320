<template>

  <v-card tile flat class="d-flex flex-row align-stretch" height="100%" min-height="100vh">
    <v-navigation-drawer permanent color="primary" dark class="flex-shrink-0">
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title class="title">
            {{ user.name }}
          </v-list-item-title>
          <v-list-item-subtitle class="mt-3">
            <v-btn text :to="{ name: 'logout' }">Logout</v-btn>
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>

      <v-divider></v-divider>

      <v-list
          dense
          nav expand
          class="text-left"
      >
        <v-divider></v-divider>

        <v-list-item link :to="{ name: 'manager' }">
          <v-list-item-icon>
            <v-icon>assignment</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Projects</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item link :to="{ name: 'manager_campaigns' }">
          <v-list-item-icon>
            <v-icon>work</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Campaigns</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item link :to="{ name: 'manager_reports' }">
          <v-list-item-icon>
            <v-icon>description</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Reports</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

      </v-list>
    </v-navigation-drawer>

    <div class="flex-grow-1 flex-shrink-1">
      <router-view></router-view>
    </div>
  </v-card>

</template>
<script>
import ProjectList from '../projects/ProjectList.vue';
import ApiService from "@/services/ApiService"; 
import {mapGetters} from "vuex"

export default {
  components: {
    ProjectList,
  },
  data() {
    return {
      projects: [],
    };
  },
  computed: {
    ...mapGetters({
      user: 'auth/user'
    }),
    project() {
      return this.projects;
    }
  },
  mounted() {
    this.getProjects();
  },
  methods: {
    getProjects() {
      ApiService.getProjects()
          .then((response) => {
            this.projects = response.data.data;
          })
          .catch((error) => {
            console.log(error);
          });
    },
    createProject() {
      if (this.projectName.length > 0) {
        this.loading = true;

        ApiService.createProject(this.projectName)
            .then(response => {
              this.loading = false;

              //redirect to the created project
              if (response.status === 201) {
                this.dialog = false;
                this.alertMessage = response.data.data.name + ' created successfully. You might need to refresh your page to view the changes';
                this.alert = true;
              }
            })
            .catch(error => {
              console.log(error);
              this.loading = true;
            });
      }
    }
  }
};
</script>
