<template>
  <v-card flat height="100vh" class="d-flex flex-row align-stretch">
    <v-navigation-drawer permanent class="flex-shrink-0" color="primary" dark>
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title class="title">
            {{ user.name }}
          </v-list-item-title>
          <v-list-item-subtitle class="mt-3">
            <v-btn text :to="{ name: 'logout' }">Logout</v-btn>
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>

      <v-divider dark></v-divider>

      <div class="caption text-center white--text my-3">Quick Access Panel</div>
      <v-list
          nav expand dense
          class="text-left">

        <v-list-item link :to="{ name: 'start' }">
          <v-list-item-icon>
            <v-icon>home</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title class="body-1">Dashboard</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <div class="flex-grow-1 flex-shrink-1">
      <v-overlay absolute :value="overlay">
        <v-progress-circular indeterminate size="64"></v-progress-circular>
      </v-overlay>
      <v-tabs grow show-arrows v-model="tab">
        <v-tab>
          Dashboard Users
        </v-tab>

        <v-tab>
          App Users
        </v-tab>
      </v-tabs>

      <v-tabs-items v-model="tab">
        <v-tab-item>
          <v-card flat>
            <v-card-text>
              <v-text-field
                  v-model="dash_users"
                  flat
                  solo-inverted
                  label="Search Dashboard users"
                  prepend-inner-icon="search"
                  single-line
                  hide-details
              ></v-text-field>
              <v-data-table
                  :headers="dashUsersHeaders"
                  :items="dashUsers"
                  :search="dash_users"
                  :items-per-page="10"
                  class="elevation-1"
              ></v-data-table>
            </v-card-text>
          </v-card>
        </v-tab-item>
        <v-tab-item>
          <v-card flat>
            <v-card-title>
              <v-text-field
                  v-model="search"
                  flat
                  solo-inverted
                  label="Search App users"
                  prepend-inner-icon="search"
                  single-line
                  hide-details
              ></v-text-field>
            </v-card-title>
            <v-card-text>
              <v-data-table
                  :headers="appUsersheaders"
                  :items="hokelaUsers"
                  :items-per-page="10"
                  @click:row="openUser"
                  :search="search"
                  class="elevation-0"
              ></v-data-table>
            </v-card-text>
          </v-card>
        </v-tab-item>
      </v-tabs-items>
    </div>
  </v-card>
</template>

<script>
import Sidebar from '../utils/Sidebar.vue';
import ApiService from "@/services/ApiService";
import {mapGetters} from 'vuex'

export default {
  name: 'users',
  components: {Sidebar},
  data() {
    return {
      search: null,
      dash_users: null,
      overlay: false,
      users: '',
      accounts: [],
      hokelaUsers: [],
      dashUsers: [],
      tab: null,
      dashUsersHeaders: [
        {
          text: '#',
          align: 'left',
          sortable: false,
          value: 'id'
        },
        {
          text: 'Name',
          value: 'name',
        },
        {
          text: 'Email',
          value: 'email',
        },
        {
          text: 'Account Type',
          value: 'account_type',
        },
        {
          text: 'Status',
          value: 'active'
        }
      ],
      appUsersheaders: [
        {
          text: '#',
          align: 'left',
          sortable: false,
          value: 'id'
        },
        {
          text: 'Phone Number',
          value: 'phone',
        },
        {
          text: 'Name',
          value: 'name',
        },
        {
          text: 'National ID',
          value: 'national_id',
        },
        {
          text: 'County',
          value: 'county',
        },
        {
          text: 'Status',
          value: 'active',
        },
        {
          text: 'Joined',
          value: 'created_at',
        },
      ]
    };
  },

  mounted() {
    this.overlay = true;
    this.getClients()

    ApiService.hokelaPage()
        .then(response => this.hokelaData(response.data.data))
        .catch(error => console.log(error));

    ApiService.users()
        .then(response => this.passData(response.data.data))
        .catch(error => console.log(error));
    this.overlay = false;
  },
  methods: {
    getClients() {
      ApiService.getClients()
          .then((response) => {
            this.accounts = response.data.data;
          })
          .catch((error) => {
            console.log(error);
          });
    },
    showAccountPage(id) {
      this.$router.push({
        name: 'account',
        params: {id: id}
      });
      this.$root.$emit('save_client_projects', this.users);
    },
    openUser(value) {
      this.$router.push({
        name: 'user',
        params: {id: value.id}
      });
    },
    getAccountStatus(status) {
      if (status === 1) {
        return 'Active'
      } else
        return 'Inactive'
    },
    passData(data) {
      data.forEach(entry => {
            entry.active = entry.active === 1 ? 'Active' : 'Inactive';
            switch (entry.account_type) {
              case 0:
                entry.account_type = 'Super Admin';
                break;
              case 1:
                entry.account_type = 'Admin Assistant';
                break;
              case 2:
                entry.account_type = 'Client';
                break;
              case 3:
                entry.account_type = 'Project Manager';
                break;
              case 4:
                entry.account_type = 'Assistant Project Manager';
                break;
              case 5:
              default:
                entry.account_type = 'Client Service';
                break;
            }
          }
      );
      this.dashUsers = data;
    },
    hokelaData(data) {
      data.forEach(entry => {
        entry.active = entry.active == 1 ? 'Active' : 'Inactive';
      });
      this.hokelaUsers = data;
    }
  },
  computed: {
    ...mapGetters({
      user: 'auth/user'
    })
  }
};
</script>
