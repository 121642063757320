<template>
  <v-card flat>
    <v-card-text>
      <v-text-field
          v-model="search"
          flat
          light
          solo
          background-color="grey lighten-3"
          color="black"
          label="Search Routes"
          prepend-i nner-icon="search"
          single-line
          hide-details
      ></v-text-field>

      <v-data-table
          :search="search"
          :headers="headers"
          :items="values"
          :items-per-page="20"
          :loading="loading"
          no-data-text="Routes created by users will appear here"
          loading-text="Loading... Please wait"
      >
        <template v-slot:item.name="{ item }" class="text-left">

          <v-btn text link class="text-left text-capitalize capitalize" @click="openRoute(item.id)">
            <v-icon color="primary">
              transfer_within_a_station
            </v-icon>
            <span class="ml-4">
            {{ item.name }}
            </span>
          </v-btn>
        </template>
      </v-data-table>

    </v-card-text>
  </v-card>
</template>

<script>
import Sidebar from "../utils/Sidebar.vue";
import PieChart from "../utils/chart/PieChart.vue";
import BarChart from "../utils/chart/BarChart.vue";
import Map from "../utils/map/Map.vue";
import ApiService from "@/services/ApiService";

export default {
  components: {
    Sidebar,
    PieChart,
    BarChart,
    Map,
  },
  data() {
    return {
      search: null,
      loading: true,
      routes: {
        headers: [],
        values: []
      }
    }
  },
  mounted() {
    this.getRoutes()
  },
  methods: {
    openRoute(id) {
      this.$router.push({
        name: 'route',
        params: {id: id}
      });
    },
    getRoutes() {
      ApiService.getRoutesByCampaign(this.$route.params.id)
          .then((response) => {
            this.routes = response.data.data
            this.loading = false
          })
          .catch((error) => {
            console.log(error)
            this.loading = false
          })
    },

    statusText() {
      if (this.routes && this.routes.session.length > 0) {
        return 'Active';
      } else
        return 'Inactive'
    },
    statusColor() {
      if (this.routes && this.routes.session.length > 0) {
        return 'green';
      } else
        return 'orange'
    },
  },
  computed: {
    headers() {
      return this.routes.headers
    },
    values() {
      return this.routes.values
    }
  },
}
</script>
