<template>
  <v-card flat tile>
    <v-card-text>
      <v-data-iterator
        hide-default-footer
        :loading="loading"
        :page="page"
        :items="images"
        loading-text="Loading image data..."
        :items-per-page.sync="itemsPerPage"
      >
        <template v-slot:header v-if="selectedImages.length > 0">
          <!-- Selected images -->
          <v-toolbar dense class="mb-6">
            <v-toolbar-title
              >Selected Images ({{ selectedImages.length }})</v-toolbar-title
            >

            <!-- Selected images actions -->
            <div class="d-flex justify-space-between flex-grow-1">
              <!-- Selected images download button-->
              <v-btn small text class="ml-4" @click="downloadSelectedImages">
                <div class="d-flex justify-center align-center">
                  {{ downloadingSelectedImages ? "Downloading" : "Download" }}

                  <v-progress-circular
                    v-if="downloadingSelectedImages"
                    indeterminate
                    size="16"
                    color="blue"
                    class="ml-2"
                  />
                </div>
              </v-btn>

              <!-- Clear selected images button -->
              <v-tooltip bottom>
                <template #activator="{ on, attrs }">
                  <v-btn
                    small
                    v-on="on"
                    v-bind="attrs"
                    icon
                    @click="selectedImages = []"
                  >
                    <v-icon>mdi-close-circle</v-icon>
                  </v-btn>
                </template>

                <span> Clear Selection </span>
              </v-tooltip>
            </div>
          </v-toolbar>
        </template>

        <template v-slot:default="props">
          <v-row>
            <v-col
              v-for="image in props.items"
              :key="image.id"
              cols="12"
              sm="6"
              md="4"
              lg="3"
            >
              <v-card
                max-width="400px"
                flat
                color="#F0F8FF"
                :style="{ position: 'relative' }"
              >
                <v-img
                  :src="`https://develop.hokela.co.ke/api/images?uri=${image.src}`"
                  class="white--text align-end"
                  height="250px"
                >
                  <template v-slot:placeholder>
                    <v-row
                      class="fill-height ma-0"
                      align="center"
                      justify="center"
                    >
                      <v-progress-circular
                        indeterminate
                        color="black"
                      ></v-progress-circular>
                    </v-row>
                  </template>
                  <v-card-title class="title">{{
                    image.title || ""
                  }}</v-card-title>
                </v-img>
                <v-card-subtitle class="pb-0 caption">{{
                  image.subtitle || ""
                }}</v-card-subtitle>
                <v-card-text class="text--primary caption">
                  <div>{{ image.description || "" }}</div>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-checkbox :value="image" v-model="selectedImages" />

                  <v-btn icon @click="showFullImage(image.src)">
                    <v-icon>mdi-eye</v-icon>
                  </v-btn>

                  <v-btn icon @click="downloadImage(image.src, image.title)">
                    <v-icon>cloud_download</v-icon>
                  </v-btn>
                </v-card-actions>

                <!-- Highlight for selected image -->
                <div
                  v-if="selectedImages.includes(image)"
                  :style="{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    backgroundColor: 'black',
                    opacity: 0.2,
                    width: '100%',
                    height: '100%',
                    borderRadius: '4px',
                    pointerEvents: 'none',
                  }"
                />
              </v-card>
            </v-col>
          </v-row>
        </template>
        <template v-slot:footer>
          <v-row class="mt-2" align="center" justify="center">
            <v-spacer></v-spacer>
            <span class="grey--text">Items per page</span>
            <v-menu offset-y>
              <template v-slot:activator="{ on }">
                <v-btn dark text color="primary" class="ml-2" v-on="on">
                  {{ itemsPerPage }}
                  <v-icon>mdi-chevron-down</v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item
                  v-for="(number, index) in itemsPerPageArray"
                  :key="index"
                  @click="updateItemsPerPage(number)"
                >
                  <v-list-item-title>{{ number }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
            <span class="mr-5 grey--text"
              >Page {{ page }} of {{ numberOfPages }}</span
            >
            <v-btn text color="warning" class="mr-1" @click="formerPage">
              Previous
            </v-btn>
            <v-btn text color="blue darken-3" class="ml-1" @click="nextPage">
              Next page
            </v-btn>
          </v-row>
        </template>
      </v-data-iterator>
      <v-dialog v-model="dialog" overlay-opacity="0.8" width="80%" scrollable>
        <v-card flat tile>
          <v-card-title>
            {{ image.title || "" }}
            <v-spacer></v-spacer>
            <v-btn class="mr-10" icon light @click="dialog = false">
              <v-icon>close</v-icon>
            </v-btn>
          </v-card-title>
          <v-card-text>
            <v-img
              :src="`https://develop.hokela.co.ke/api/images?uri=${image}`"
              class="white--text align-end"
              contain
              height="80vh"
            >
              <v-card-title class="title">{{ image.title || "" }}</v-card-title>
            </v-img>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-card-text>
  </v-card>
</template>

<script>
import axios from "axios";
import ApiService from "@/services/ApiService";

export default {
  data() {
    return {
      itemsPerPage: 8,
      itemsPerPageArray: [8, 12, 16, 20],
      page: 1,
      absolute: true,
      dialog: false,
      loading: true,
      image: "",
      images: [],
      selectedImages: [],
      downloadingSelectedImages: false,
    };
  },
  mounted() {
    this.$route.name === "event_images"
      ? this.getEventImages()
      : this.getReportImages();
  },
  methods: {
    showFullImage(id) {
      this.image = id;
      this.dialog = true;
    },
    nextPage() {
      if (this.page + 1 <= this.numberOfPages) this.page += 1;
    },
    formerPage() {
      if (this.page - 1 >= 1) this.page -= 1;
    },
    updateItemsPerPage(number) {
      this.itemsPerPage = number;
    },

    forceFileDownload(response, name) {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `${name}.jpeg`); //or any other extension
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    async downloadImage(path, name) {
      try {
        const response = await axios({
          method: "get",
          url: `https://develop.hokela.co.ke/api/images?uri=${path}`,
          responseType: "arraybuffer",
        });
        this.forceFileDownload(response, name);
      } catch (error) {
        return console.log("error occurred", error);
      }
    },

    getEventImages() {
      ApiService.getEventImages(this.$route.params.id)
        .then((response) => {
          this.images = response.data.data;
          this.loading = false;
        })
        .catch((error) => {
          console.log("error occurred", error);
          this.loading = false;
        });
    },
    getReportImages() {
      ApiService.getReportImages(this.$route.params.id)
        .then((response) => {
          this.images = response.data.data;
          this.loading = false;
        })
        .catch((error) => {
          console.log("error occurred", error);
          this.loading = false;
        });
    },

    /**
     * Calls downloadImage method for each image in selectedImages array
     * Also sets downloadingSelectedImages
     */
    async downloadSelectedImages() {
      if (this.selectedImages.length > 0) {
        try {
          this.downloadingSelectedImages = true;
          const promises = [];

          for (const selectedImage of this.selectedImages) {
            promises.push(
              this.downloadImage(selectedImage.src, selectedImage.title)
            );
          }

          await Promise.all(promises);
        } finally {
          this.downloadingSelectedImages = false;
        }
      }
    },
  },
  computed: {
    numberOfPages() {
      return Math.ceil(this.images.length / this.itemsPerPage);
    },
  },
};
</script>
