import Vue from 'vue';
import VueRouter from 'vue-router';
import Login from '../components/auth/Login.vue';
import Logout from '../components/auth/Logout.vue';
import Register from '../components/auth/Register.vue';
import About from '../components/pages/About.vue';
import ViewProject from '../components/pages/ViewProject.vue';
import Event from '../components/pages/ViewEvent.vue';
import ViewClient from '../components/pages/ViewClient.vue';
import Users from '../components/pages/Users.vue';
import Dashboard from '../components/pages/Dashboard.vue';
import Manager from '../components/pages/Manager.vue';
import Team from '../components/pages/Team.vue';
import TeamList from '../components/pages/TeamList';
import CreateReport from '../components/pages/CreateReport.vue';
import ViewReport from '../components/pages/ViewReport.vue';
import AccountPage from '../components/pages/AccountPage.vue';
import Landing from '../components/pages/Landing.vue';
import ViewUser from "../components/pages/ViewUser.vue";
import Locations from "../components/pages/Locations.vue"
import Merch from "../components/pages/Merch.vue"
import ViewMerch from "../components/pages/ViewMerch.vue";
import StartPage from "../components/pages/StartPage.vue"
import CareersPage from "../views/CareersPage";
import Career from "@/views/Career";
import HelpPost from "../components/help/helppage/HelpPost";
import UserManagement from "@/components/pages/UserManagement";
import EditProject from "@/components/pages/EditProject";
import ForgotPassword from "@/components/auth/ForgotPassword";
import NotFound from "../components/NotFound";
import HelpPostsTab from "@/components/help/helppage/HelpPostsTab";
import AdminHelp from "@/components/help/AdminHelp";
import Route from "@/components/pages/Route"
import Images from "@/components/utils/Images";
import DataTableList from "@/components/utils/DataTableList";
import Report from "@/components/reports/Report";
import RetailNetwork from "@/views/RetailNetwork";
import Map from "@/components/utils/map/Map";

import EventsList from "@/components/utils/EventsList";
import ProjectOverview from "@/components/projects/ProjectOverview";
import EventOverview from "@/components/pages/EventOverview";
import ViewMap from "@/components/utils/map/ViewMap";
import RoutePlan from "@/components/pages/RoutePlan";
import RsmLocations from "@/components/pages/RsmLocations";
import Timestamps from "@/components/events/Timestamps";
import EventTeam from "@/components/events/EventTeam";
import Reports from "@/components/events/Reports";
import DashboardOverview from "@/components/homepage/DashboardOverview";
import ClientsList from "@/components/clients/ClientsList";
import Overview from "@/components/clients/Overview";
import ProjectList from "@/components/projects/ProjectList";
import ShowMap from "@/components/pages/ShowMap";
import EditReport from "@/components/reports/EditReport"

Vue.use(VueRouter);

// routes
const routes = [
  {
    path: "",
    name: "start",
    component: StartPage,
    meta: {
      requiresVisitor: true,
    },
  },
  {
    path: "/login",
    name: "login",
    component: Login,
    meta: {
      requiresVisitor: true,
    },
  },
  {
    path: "/dashboard",
    component: Dashboard,
    children: [
      {
        component: ClientsList,
        path: "accounts",
        name: "dashboard_accounts",
        props: true,
      },
      {
        component: DashboardOverview,
        path: "/",
        name: "dashboard",
        props: true,
      },
      {
        component: ProjectList,
        path: "projects",
        name: "dashboard_projects",
        props: true,
      },
      {
        component: Reports,
        path: "reports",
        name: "dashboard_reports",
        props: true,
      },
      {
        component: EventsList,
        path: "campaigns",
        name: "dashboard_campaigns",
        props: true,
      },
    ],
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/about",
    name: "about",
    component: About,
  },
  {
    path: "/login/:token",
    name: "register",
    component: Register,
  },
  {
    path: "/logout",
    name: "logout",
    component: Logout,
    meta: {
      requiresAuth: true,
    },
  },

  {
    path: "/manager",
    component: Manager,
    children: [
      {
        component: ProjectList,
        name: "manager",
        path: "/",
        props: true,
      },
      {
        component: Reports,
        path: "reports",
        name: "manager_reports",
        props: true,
      },
      {
        component: EventsList,
        path: "campaigns",
        name: "manager_campaigns",
        props: true,
      },
    ],
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/users",
    name: "users",
    component: Users,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/users/:id",
    name: "user",
    component: ViewUser,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/users/manage",
    name: "create_user",
    component: UserManagement,
    meta: {
      requiresAuth: true,
    },
  },

  {
    path: "/accounts/:id",
    component: ViewClient,
    children: [
      {
        component: Overview,
        name: "account",
        path: "/",
        props: true,
      },

      {
        component: ProjectList,
        path: "projects",
        name: "account_projects",
        props: true,
      },

      {
        component: EventsList,
        path: "campaigns",
        name: "account_campaigns",
        props: true,
      },

      {
        component: Reports,
        path: "reports",
        name: "account_reports",
        props: true,
      },
    ],
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/projects/:id/",
    component: ViewProject,
    children: [
      {
        component: ProjectOverview,
        name: "project",
        path: "/",
        props: true,
      },
      {
        component: EventsList,
        path: "events",
        name: "project_events",
        props: true,
      },
      {
        component: Reports,
        path: "reports",
        name: "project_reports",
        props: true,
      },
    ],
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/projects/edit/:id/",
    name: "edit_project",
    component: EditProject,
    props: true,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/event/:id/",
    component: Event,
    children: [
      {
        component: EventOverview,
        path: "/",
        name: "event",
        props: true,
      },
      {
        component: EventTeam,
        path: "team",
        name: "event_team",
        props: true,
      },
      {
        component: ViewMap,
        path: "map",
        name: "event_map",
        props: true,
      },
      {
        component: RoutePlan,
        path: "route",
        name: "event_route",
        props: true,
      },
      {
        component: RsmLocations,
        path: "locations",
        name: "event_locations",
        props: true,
      },
      {
        component: Images,
        path: "images",
        name: "event_images",
        props: true,
      },
      {
        component: Reports,
        path: "reports",
        name: "event_reports",
        props: true,
      },
      {
        component: Timestamps,
        path: "timestamps",
        name: "event_timestamps",
        props: true,
      },
    ],
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/team/:id/",
    name: "team",
    component: Team,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/teams",
    name: "teams",
    component: TeamList,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/reports/create/:id",
    name: "reports.create",
    component: CreateReport,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/reports/:id",
    component: ViewReport,

    children: [
      {
        component: Report,
        name: "report",
        path: "/",
        props: true,
        meta: {
          title: "View Report",
        },
      },
      {
        component: DataTableList,
        path: "table",
        name: "report_table",
        props: true,
      },
      {
        component: Images,
        path: "images",
        name: "report_images",
        props: true,
      },
      {
        component: EditReport,
        path: "edit",
        name: "report_edit",
      },
    ],
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/help",
    name: "help",
    component: HelpPostsTab,
    meta: {
      requiresVisitor: true,
    },
  },
  {
    path: "/client",
    component: AccountPage,
    children: [
      {
        component: Overview,
        name: "account_page",
        path: "/",
        props: true,
      },

      {
        component: ProjectList,
        path: "projects",
        name: "client_projects",
        props: true,
      },

      {
        component: EventsList,
        path: "campaigns",
        name: "client_campaigns",
        props: true,
      },

      {
        component: Reports,
        path: "reports",
        name: "client_reports",
        props: true,
      },
    ],
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/home",
    name: "landing",
    component: Landing,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/network",
    component: RetailNetwork,
    children: [
      {
        component: Locations,
        name: "locations",
        path: "/",
        props: true,
      },
      {
        component: Map,
        path: "map",
        name: "retail_map",
        props: true,
      },
    ],
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/merch",
    name: "merches",
    component: Merch,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/merch/:id",
    name: "merch",
    component: ViewMerch,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/careers",
    name: "careers",
    component: CareersPage,
    meta: {
      requiresVisitor: true,
    },
  },
  {
    path: "/careers/:id",
    name: "career",
    component: Career,
    meta: {
      requiresVisitor: true,
    },
  },
  {
    path: "/help/:id",
    name: "help-post",
    component: HelpPost,
  },
  {
    path: "/404",
    component: NotFound,
  },
  {
    path: "*",
    redirect: "/404",
  },
  {
    path: "/password/reset",
    name: "passwordReset",
    component: ForgotPassword,
    meta: {
      requiresVisitor: true,
    },
  },
  {
    path: "/tools",
    name: "tools",
    component: AdminHelp,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/route/:id",
    name: "route",
    component: Route,
    meta: {
      requiresAuth: true,
    },
  },

  {
    path: "/maps",
    name: "maps",
    component: ShowMap,
    props: true,
    meta: {
      requiresAuth: true,
    },
  },
];

const router = new VueRouter({
    mode: 'history',
    routes,
});

export default router;
