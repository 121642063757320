<template>
  <v-card flat tile>
    <v-card-text>

      <v-data-iterator
          hide-default-footer
          :page="page"
          :items="users"
          :items-per-page.sync="itemsPerPage">
        <template v-slot:default="props">
          <v-row class="grey lighten-5">
            <v-col
                v-for="user in props.items"
                :key="user.id"
                cols="12"
                sm="6"
                md="6">

              <v-card flat @click="showClientPage(user)">

                <v-card-title class="aliceblue lighten-4">
                  <v-row>
                    <v-avatar color="grey" v-if="user.client_icon">
                      <v-img :src="`https://develop.hokela.co.ke/api/images?uri=${user.client_icon}`"></v-img>
                    </v-avatar>
                    <v-avatar color="#0264E4" v-else>
                      <span class="white--text" style="word-break: break-word">{{
                          getInitials(user.account_name)
                        }}</span>
                    </v-avatar>
                    <p class="title ml-3 text-wrap mt-2">{{ user.account_name }}</p>
                  </v-row>
                </v-card-title>
                <v-divider class="white lighten-2"></v-divider>
                <v-card-text class="align-content-start justify-start">
                  <p class="body-2 align-start">{{ user.name }}</p>
                </v-card-text>

                <v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <preview-client :client="user"/>
                    <edit-client @fetchClient="getClients" :client="user"/>
                  </v-card-actions>
                </v-card-text>
              </v-card>

            </v-col>
          </v-row>
        </template>
        <template v-slot:footer>
          <v-row class="mt-2" align="center" justify="center">
            <v-spacer></v-spacer>
            <span class="grey--text">Items per page</span>
            <v-menu offset-y>
              <template v-slot:activator="{ on }">
                <v-btn
                    dark
                    text
                    color="primary"
                    class="ml-2"
                    v-on="on"
                >
                  {{ itemsPerPage }}
                  <v-icon>mdi-chevron-down</v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item
                    v-for="(number, index) in itemsPerPageArray"
                    :key="index"
                    @click="updateItemsPerPage(number)"
                >
                  <v-list-item-title>{{ number }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>

            <span class="mr-5 grey--text">Page {{ page }} of {{ numberOfPages }}</span>
            <v-btn
                text
                color="warning"
                class="mr-1"
                @click="formerPage"
            >
              Previous
            </v-btn>
            <v-btn
                text
                color="blue darken-3"
                class="ml-1"
                @click="nextPage"
            >
              Next page
            </v-btn>
          </v-row>
        </template>
      </v-data-iterator>
    </v-card-text>
  </v-card>
</template>
<script>
import EditClient from '../modals/EditClient.vue';
import PreviewClient from '../modals/PreviewClient.vue';
import AddUsers from '../pages/UserManagement.vue';

import ApiService from "@/services/ApiService";

export default {
  components: {
    EditClient,
    PreviewClient,
    AddUsers,
  },
  data() {
    return {
      link: '',
      itemsPerPage: 8,
      isLoading: false,
      items: [],
      model: null,
      status: false,
      new_user: {},
      users: [],
      overlay: false,
      absolute: true,
      valid: false,
      itemsPerPageArray: [8, 12, 16, 20],
      page: 1,
    };
  },
  computed: {
    numberOfPages() {
      return Math.ceil(this.users.length / this.itemsPerPage)
    },
  },

  methods: {
    openUserManagement() {
      this.$router.push({
        name: 'create_user',
      });
    },
    nextPage() {
      if (this.page + 1 <= this.numberOfPages) this.page += 1
    },
    formerPage() {
      if (this.page - 1 >= 1) this.page -= 1
    },
    updateItemsPerPage(number) {
      this.itemsPerPage = number
    },
    getInitials(value) {
      let names = value.split(' '),
          initials = names[0].substring(0, 1)
              .toUpperCase();

      if (names.length > 1) {
        initials += names[names.length - 1].substring(0, 1)
            .toUpperCase();
      }
      return initials;
    },
    copyLink() {
      this.$copyText(this.link)
          .then(function (e) {
            alert('Copied');
            console.log(e);
          }, function (e) {
            alert('Can not copy');
            console.log(e);
          });
    },

    showClientPage(client) {
      this.$router.push({
        name: 'account',
        params: {id: client.id}
      });
    },

    getClients() {
      ApiService.getAllClients()
          .then((response) => {
            this.users = (response.data.data);
          })
          .catch((error) => {
            console.log(error);
          });
    },
    handleOverlay(val) {
      this.overlay = val;
    },
    showLink(link) {
      this.link = link;
      this.status = true;
      this.overlay = false;
    }
  },
  mounted() {
    this.getClients();
  },
  events: {
    fetchClient: function () {
      this.getClients();
    }
  }
};
</script>
