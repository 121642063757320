<template>
  <v-card flat color="grey lighten-5">
    <v-card-text>

      <v-data-iterator
          :items="admins"
          item-key="name"
          :items-per-page="10"
          single-expand
      >
        <template v-slot:default="{ items, isExpanded, expand }">
          <v-row>
            <v-col
                v-for="item in items"
                :key="item.name"
                cols="12"
                sm="6"
                md="4"
                lg="3"
            >
              <v-card flat>
                <v-card-title>
                  <v-avatar size="40">
                    <v-icon color="brown">face</v-icon>
                  </v-avatar>
                  <span class="body-1 ml-2">{{ item.name }}</span>
                </v-card-title>
                <v-divider></v-divider>
                <v-card-text>
                  <v-row justify="space-between" class="mt-3">
                    <div class="ml-3">
                      <v-icon>email</v-icon>
                      <span class="ml-2">{{ item.email }}</span>
                    </div>
                    <div>
                      <v-avatar size="15" :color="getAccountColor(item.active)"></v-avatar>
                      <span class="ml-2 mt-2">{{ getAccountStatus(item.active) }}</span>
                    </div>
                  </v-row>

                  <div class="text-left  mt-2">
                    <v-icon color="primary">today</v-icon>
                    <span class="ml-2 mt-2">{{ item.created_at }}</span>
                  </div>

                  <div class="text-left mt-3">
                    <v-icon color="red">gavel</v-icon>
                    <span class="ml-2 mt-2">{{ getAccountType(item.account_type) }}</span>
                  </div>


                </v-card-text>

                <v-btn icon @change="(v) => expand(item, v)">
                  <v-icon>expand_more</v-icon>
                </v-btn>
                <v-switch
                    :input-value="isExpanded(item)"
                    :label="isExpanded(item) ? 'Expanded' : 'Closed'"
                    class="pl-4 mt-0"
                    @change="(v) =>expandTile(v)"
                ></v-switch>
                <v-divider></v-divider>
                <v-list
                    v-if="isExpanded(item)"
                    dense
                >
                </v-list>
              </v-card>
            </v-col>
          </v-row>
        </template>
      </v-data-iterator>
    </v-card-text>
  </v-card>

</template>
<script>
export default {
  props: {
    admins: Array,
  },
  methods: {
    expandTile(v){
      console.log(v)
    },
    getAccountStatus(status) {
      if (status === 1) {
        return 'Active'
      } else
        return 'Inactive'
    },
    getAccountColor(status) {
      if (status === 1) {
        return 'green'
      } else
        return 'orange'
    },

    getAccountType(type) {
      this.account_type = type;
      switch (type) {
        case 0:
          type = 'Super Admin';
          break;
        case 1:
          type = 'Admin Assistant';
          break;
        case 2:
          type = 'Client';
          break;
        case 3:
          type = 'Project Manager';
          break;
        case 4:
          type = 'Assistant Project Manager';
          break;
        case 5:
          type = 'Client Service';
          break;
        default:
          type = 'Loading..';
      }
      return type;
    },
  },
};
</script>
