var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-form',[_c('v-text-field',{staticClass:"mt-3",attrs:{"prepend-inner-icon":"text_fields","outlined":"","label":"Name","required":""},model:{value:(_vm.event_name),callback:function ($$v) {_vm.event_name=$$v},expression:"event_name"}}),_c('v-menu',{ref:"menu",attrs:{"close-on-content-click":false,"return-value":_vm.starting_date,"transition":"scale-transition","offset-y":"","min-width":"290px"},on:{"update:returnValue":function($event){_vm.starting_date=$event},"update:return-value":function($event){_vm.starting_date=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-text-field',_vm._g({attrs:{"outlined":"","label":"Starting date","prepend-inner-icon":"event","readonly":""},model:{value:(_vm.starting_date),callback:function ($$v) {_vm.starting_date=$$v},expression:"starting_date"}},on))]}}]),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c('v-date-picker',{attrs:{"no-title":"","scrollable":""},model:{value:(_vm.starting_date),callback:function ($$v) {_vm.starting_date=$$v},expression:"starting_date"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.menu = false}}},[_vm._v("Cancel")]),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.$refs.menu.save(_vm.starting_date)}}},[_vm._v(" OK ")])],1)],1),_c('v-menu',{ref:"menu2",attrs:{"close-on-content-click":false,"return-value":_vm.ending_date,"transition":"scale-transition","offset-y":"","min-width":"290px"},on:{"update:returnValue":function($event){_vm.ending_date=$event},"update:return-value":function($event){_vm.ending_date=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-text-field',_vm._g({attrs:{"label":"Ending date","prepend-inner-icon":"event","outlined":"","readonly":""},model:{value:(_vm.ending_date),callback:function ($$v) {_vm.ending_date=$$v},expression:"ending_date"}},on))]}}]),model:{value:(_vm.menu2),callback:function ($$v) {_vm.menu2=$$v},expression:"menu2"}},[_c('v-date-picker',{attrs:{"no-title":"","scrollable":""},model:{value:(_vm.ending_date),callback:function ($$v) {_vm.ending_date=$$v},expression:"ending_date"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.menu = false}}},[_vm._v("Cancel")]),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.$refs.menu2.save(_vm.ending_date)}}},[_vm._v(" OK ")])],1)],1),_c('v-menu',{ref:"checkinMenu",attrs:{"close-on-content-click":false,"nudge-right":40,"return-value":_vm.checkin_time,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"290px"},on:{"update:returnValue":function($event){_vm.checkin_time=$event},"update:return-value":function($event){_vm.checkin_time=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-text-field',_vm._g({attrs:{"label":"Expected check-in time","prepend-inner-icon":"access_time","outlined":"","readonly":""},model:{value:(_vm.checkin_time),callback:function ($$v) {_vm.checkin_time=$$v},expression:"checkin_time"}},on))]}}]),model:{value:(_vm.checkinMenu),callback:function ($$v) {_vm.checkinMenu=$$v},expression:"checkinMenu"}},[(_vm.checkinMenu)?_c('v-time-picker',{attrs:{"full-width":"","format":"24hr"},on:{"click:minute":function($event){return _vm.$refs.checkinMenu.save(_vm.checkin_time)}},model:{value:(_vm.checkin_time),callback:function ($$v) {_vm.checkin_time=$$v},expression:"checkin_time"}}):_vm._e()],1),_c('v-menu',{ref:"checkoutMenu",attrs:{"close-on-content-click":false,"nudge-right":40,"return-value":_vm.checkout_time,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"290px"},on:{"update:returnValue":function($event){_vm.checkout_time=$event},"update:return-value":function($event){_vm.checkout_time=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-text-field',_vm._g({attrs:{"label":"Expected check-out time","prepend-inner-icon":"access_time","outlined":"","readonly":""},model:{value:(_vm.checkout_time),callback:function ($$v) {_vm.checkout_time=$$v},expression:"checkout_time"}},on))]}}]),model:{value:(_vm.checkoutMenu),callback:function ($$v) {_vm.checkoutMenu=$$v},expression:"checkoutMenu"}},[(_vm.checkoutMenu)?_c('v-time-picker',{attrs:{"full-width":"","format":"24hr"},on:{"click:minute":function($event){return _vm.$refs.checkoutMenu.save(_vm.checkout_time)}},model:{value:(_vm.checkout_time),callback:function ($$v) {_vm.checkout_time=$$v},expression:"checkout_time"}}):_vm._e()],1)],1),_c('v-card',{attrs:{"flat":"","outlined":""}},[_c('div',{staticClass:"grey--text"},[_vm._v(" Geo fence will restrict user checkin to the set location")]),_c('v-card-text',[_c('div',{staticClass:"my-4"},[_c('v-autocomplete',{attrs:{"items":_vm.locationSearches,"search-input":_vm.locations,"chips":"","clearable":"","hide-details":"","hide-selected":"","type":"text","item-text":"lat","item-value":"name","label":"Search location by name","return-object":"","outlined":""},on:{"update:searchInput":function($event){_vm.locations=$event},"update:search-input":function($event){_vm.locations=$event}},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_c('v-list-item',[_c('v-list-item-title',[_vm._v(" Start typing "),_c('strong',[_vm._v("location name")])])],1)]},proxy:true},{key:"selection",fn:function(ref){
var attr = ref.attr;
var on = ref.on;
var item = ref.item;
var selected = ref.selected;
return [_c('v-chip',_vm._g(_vm._b({staticClass:"white--text",attrs:{"input-value":selected,"color":"blue-grey"}},'v-chip',attr,false),on),[_c('v-icon',{attrs:{"left":""}},[_vm._v("location_on")]),_c('span',{domProps:{"textContent":_vm._s(item.name)}})],1)]}},{key:"item",fn:function(ref){
var item = ref.item;
return [_c('v-list-item-avatar',{staticClass:"headline font-weight-light white--text",attrs:{"color":"indigo"}},[_vm._v(" "+_vm._s(item.name.charAt(0))+" ")]),_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"textContent":_vm._s(item.name)}}),_c('v-list-item-subtitle',{domProps:{"textContent":_vm._s(item.lat+' ,'+item.lng)}})],1),_c('v-list-item-action',[_c('v-icon',[_vm._v("location_on")])],1)]}}]),model:{value:(_vm.location),callback:function ($$v) {_vm.location=$$v},expression:"location"}})],1)])],1),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","block":"","loading":_vm.loading},on:{"click":function($event){return _vm.createEvent()}}},[_vm._v("Create Event")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }