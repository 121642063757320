<template>
  <v-card>
    <v-row class="ma-2">
      <v-spacer></v-spacer>
      <v-btn icon @click="exportTimeStamps">
        <v-icon>
          print
        </v-icon>
      </v-btn>
    </v-row>
    <v-text-field
      v-model="search"
      flat
      light
      solo
      background-color="grey lighten-3"
      color="black"
      label="Search Reports"
      prepend-inner-icon="search"
      single-line
      hide-details
    ></v-text-field>

    <v-data-table
      :search="search"
      :headers="headers"
      :items="timestamps"
      :loading="loading"
      no-data-text="No timestamps found. Reload page or check later"
      :items-per-page="20"
    >
      <template v-slot:item.gps="{ item }">
        <v-btn
          small
          text
          link
          @click="showLocation(item.gps)"
          autocapitalize="sentences"
          color="primary"
          class="caption"
        >
          View
        </v-btn>
      </template>

      <template v-slot:item.photo="{ item }">
        <v-btn
          v-if="item.photo"
          small
          text
          link
          @click="showImage(item.photo)"
          autocapitalize="sentences"
          color="primary"
          class="caption"
        >
          View
        </v-btn>
      </template>

      <template v-slot:item.status="{ item }">
        <v-avatar
          :color="item.status ? 'green' : 'orange'"
          size="10"
        ></v-avatar>
        <span class="ml-3">{{ item.status ? "Live" : "Done" }}</span>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import ApiService from "@/services/ApiService";

export default {
  data() {
    return {
      search: null,
      timestamps: [],
      loading: true,
      headers: [
        { text: "User", value: "name", align: "start" },
        { text: "Location", value: "location", align: "start" },
        { text: "Date", value: "date", align: "start" },
        { text: "Status", value: "status", align: "start" },
        { text: "Checkin", value: "checkin" },
        { text: "Time spent", value: "time" },
        { text: "Checkout", value: "checkout" },
        { text: "Map", value: "gps" },
        { text: "Photo", value: "photo" },
      ],
    };
  },
  methods: {
    showLocation(gps) {
      let link = `https://www.google.com/maps/search/?api=1&query=${gps.checkin_lat},${gps.checkin_lng}`;
      window.open(link, "_blank");
    },
    showImage(image) {
      this.dialog = true;
      this.clickedImage = image;
    },

    getTimestampsByEvent() {
      ApiService.getTimestampsByEvent(this.$route.params.id)
        .then((response) => {
          this.timestamps = response.data.data;
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getTimestamps() {
      ApiService.getTimestamps()
        .then((response) => {
          this.timestamps = response.data.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    exportTimeStamps() {
      ApiService.exportTimestamps(this.$route.params.id).then(function(resp) {
        const headers = resp.headers;
        const blob = new Blob([resp.data], { type: headers["content-type"] });
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = "timestamps_export";
        link.click();
      });
    },
  },
  mounted() {
    this.$route.name === "event_timestamps"
      ? this.getTimestampsByEvent()
      : this.getTimestamps();
  },
};
</script>
