<template>
  <v-card
    tile
    flat
    class="d-flex flex-row align-stretch"
    min-height="100vh"
    height="100%"
  >
    <v-navigation-drawer permanent class="flex-shrink-0" dark color="primary">
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title class="title">
            {{ user.name }}
          </v-list-item-title>
          <v-list-item-subtitle class="mt-3">
            <v-btn text :to="{ name: 'logout' }">Logout</v-btn>
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>

      <v-divider></v-divider>

      <v-list dense nav expand class="text-left">
        <v-list-item link :to="{ name: 'start' }">
          <v-list-item-icon>
            <v-icon>home</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Dashboard</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-divider></v-divider>

        <v-divider></v-divider>

        <v-list-item class="caption text-center blue--text"
          >System shortcuts</v-list-item
        >

        <v-divider></v-divider>

        <v-list-item link :to="{ name: 'locations' }">
          <v-list-item-icon>
            <v-icon>location_on</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Retail Network</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <div class="flex-grow-1">
      <v-toolbar light flat>
        <v-toolbar-title
          >Route:<span class="ml-2">{{ route.name }}</span></v-toolbar-title
        >
      </v-toolbar>

      <v-card color="grey lighten-4" flat tile>
        <v-card-text>
          <v-card flat height="100%">
            <v-card-title class="body-2">Outlet checkins</v-card-title>
            <v-divider></v-divider>
            <v-card-text>
              <v-data-table
                :search="search"
                :headers="route.checkins.headers"
                :items="route.checkins.values"
                :items-per-page="20"
                :loading="loading"
                no-data-text="Outlets visited will appear here"
                loading-text="Loading... Please wait"
              >
              </v-data-table>
            </v-card-text>
          </v-card>
        </v-card-text>
      </v-card>
    </div>
  </v-card>
</template>
<script>
import Map from "@/components/utils/map/Map";
import ApiService from "@/services/ApiService";
import { mapGetters } from "vuex";

export default {
  components: { Map },
  data() {
    return {
      route: {
        name: "Loading",
        checkins:{
          headers:[],
          values:[]
        }
      },
    };
  },
  mounted() {
    this.getRoute();
  },
  computed: {
    ...mapGetters({
      user: "auth/user",
    }),
  },
  methods: {
    getRoute() {
      ApiService.getRoute(this.$route.params.id)
        .then((response) => {
          this.route = response.data.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>
