<template>
    <div>
        <sidebar/>
        <div class="main_container">
            <show/>
        </div>

    </div>
</template>

<script>

    import Sidebar from '../utils/Sidebar.vue';
    import Show from '../users/Show.vue';

    export default {
        components: {
            Sidebar,
            Show,
        }
    };
</script>
