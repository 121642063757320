<template>
  <v-sheet height="98%" flat tile>
    <v-toolbar dark color="primary" flat>
      <v-toolbar-title class="title" @click="openHome">Hokela</v-toolbar-title>
    </v-toolbar>

    <!--  Snackbar  -->
    <template>
      <div class="text-center">
        <v-snackbar v-model="snackbar" timeout="2000">
          {{ snackMessage }}
          <template v-slot:action="{ attrs }">
            <v-btn color="blue" text v-bind="attrs" @click="snackbar = false">Close</v-btn>
          </template>
        </v-snackbar>
      </div>
    </template>

    <!--   Application Title    -->
    <v-container>
      <v-card-text>

        <div class="text-center display-1">{{ job.title }}</div>
        <v-container>
          <v-row>
            <v-col cols="12" sm="4" md="4">
              <v-card outlined>
                <v-card-text>
                  <div class="text-center body-1">Skill Level</div>
                  <div class="text-center caption">{{ job.level || 'Not set' }}</div>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="12" sm="4" md="4">
              <v-card outlined>
                <v-card-text>
                  <div class="text-center body-1">Location</div>
                  <div class="text-center caption">{{ job.location }}</div>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="12" sm="4" md="4">
              <v-card outlined align="center">
                <v-card-text>
                  <div class="text-center body-1">Deadline</div>
                  <div class="text-center caption">{{ job.deadline }}</div>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-text align="center">
        <v-card flat tile>
          <div class="title text-center">Role Description</div>
          <v-card-text>{{ job.description }}</v-card-text>
        </v-card>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-text>
        <div class="title text-center">Apply for this position</div>

          <v-form
              v-model="valid"
              lazy-validation
          >
            <v-row>
              <v-col cols="12" sm="6">
                <v-text-field
                    label="First Name"
                    :rules="nameRules"
                    v-model="firstName"
                    filled
                    required
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field
                    label="Last Name"
                    :rules="nameRules"
                    v-model="lastName"
                    filled
                    required
                ></v-text-field>
              </v-col>
            </v-row>

            <v-text-field
                label="Email"
                type="email"
                :rules="emailRules"
                v-model="email"
                filled
                required
            ></v-text-field>

            <v-text-field
                label="Phone Number"
                type="number"
                :rules="numberRules"
                v-model="phone"
                filled
                required
            ></v-text-field>

            <v-text-field
                label="Residence"
                :rules="residenceRules"
                v-model="residence"
                hint="What is your current area of residence"
                filled
                required
            ></v-text-field>

            <v-text-field
                label="Your Portfolio Link"
                :rules="urlRules"
                v-model="url"
                filled
                required
            ></v-text-field>

            <v-btn
                block
                dark
                large
                :loading="loading"
                class="mx-auto"
                @click="submitContent"
                color="primary"
            >Sumbit Application
            </v-btn>
          </v-form>
      </v-card-text>
    </v-container>

    <!--   Footer     -->
    <v-footer
        dark
        padless
    >
      <v-card
          align="center"
          class="flex"
          flat
          tile
      >
        <v-card-text class="py-2 white--text text-center">
          {{ new Date().getFullYear() }} — <strong>Hokela Interactive Africa</strong>
        </v-card-text>
      </v-card>
    </v-footer>
  </v-sheet>
</template>

<script>
import axios from "axios";

export default {
  async mounted() {
    axios.defaults.baseURL = "https://develop.hokela.co.ke/api";

    // DO NOT DELETE. WILL BE USED FOR LATER
    //axios.defaults.headers.common.Authorization = `Bearer ${this.$store.state.token}`;
    axios
        .get(`/admin/careers/${this.$route.params.id}`)
        .then(response => {
          this.job = response.data.data;
        })
        .catch(error => {
          console.log(error);
        });
  },
  data() {
    return {
      valid: true,
      loading: false,
      firstName: null,
      lastName: null,
      email: null,
      phone: null,
      residence: null,
      url: null,
      snackbar: false,
      snackMessage: null,
      job: null,

      nameRules: [v => !!v || "Name is required"],
      numberRules: [
        v => !!v || "Phone Number is required",
        v =>
            (v && v.length >= 10) ||
            "Phone Number must be 10 or more characters"
      ],
      emailRules: [
        v => !!v || "E-mail is required",
        v => /.+@.+\..+/.test(v) || "E-mail must be valid"
      ],
      residenceRules: [],
      urlRules: [v => !!v || "Portfolio url is required"]
    }
  },

  methods: {
    validate() {
      //this.loading = true;
      this.$refs.form.validate();
    },
    submitContent() {
      this.loading = true;
      // this.$refs.form.validate();

      // if (this.valid) {
      axios.post("https://develop.hokela.co.ke/api/admin/apply", {
            name: `${this.firstName} ${this.lastName}`,
            email: this.email,
            phone: this.phone,
            residence: this.residence,
            portfolio_url: this.url,
            career_id: this.$route.params.id,
          }
      ).then((response) => {
        if (response.status === 201) {
          this.loading = false;
          this.snackbar = true;
          this.snackMessage =
              "Application submitted successfully. We will be in contact soon.";
        } else {
          this.loading = false;
          this.snackMessage =
              "Unable to get feedback. Check data or try again later.";
          this.snackbar = true;
        }
      })
          .catch(error => {
            console.log(error);
            this.loading = false;
            this.snackMessage =
                "An error occurred while submitting. This could be caused by poor internet connection or incomplete details. Try again later.";
            this.snackbar = true;
          });
    }
    //}
  }
};
</script>
