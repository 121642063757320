<template>
    <v-sheet height="100%" width="100%">
        <GoogleMapLoader
                :mapConfig="mapConfig"
                apiKey="AIzaSyCGCKONNY2r7sqRYm1lTyR8KtDOnKJATZ4"
        >
            // insert your google maps api key to render styled map
            <template slot-scope="{ google, map }">
                <GoogleMapMarker
                        v-for="marker in points"
                        :key="marker.id"
                        :marker="marker"
                        :google="google"
                        :map="map"
                />
            </template>
        </GoogleMapLoader>
    </v-sheet>

</template>

<script>

  import GoogleMapMarker from './GoogleMapMarker.vue';
  import GoogleMapLoader from './GoogleMapLoader.vue';
  import GoogleMapLine from './GoogleMapLine.vue';
  import { mapSettings } from './constants/mapSettings';

  export default {
    props: {
      markers: Array,
    },
    components: {
      GoogleMapMarker,
      GoogleMapLoader,
      GoogleMapLine
    },

    data() {
      return {
        google: null,
        map: null,
        center: {
          lat: -1.286389,
          lng: 36.817223
        },
        zoom: 8,
      };
    },
    computed: {
      mapConfig() {
        return {
          ...mapSettings,
          center: this.mapCenter,
          zoom: this.zoom
        };
      },

      mapCenter() {
        return this.center;
      },
      points(){
        return this.markers;
      }
    }
  };
</script>
<style>
    .google-map {
        width: 100%;
        height: 600px;
    }
</style>
