<template>
  <v-card tile flat class="d-flex flex-row align-stretch" min-height="100vh" height="100%">

    <v-navigation-drawer permanent class="flex-shrink-0" dark color="primary">
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title class="title">
            {{ user_obj.name }}
          </v-list-item-title>
          <v-list-item-subtitle class="mt-3">
            <v-btn text :to="{ name: 'logout' }">Logout</v-btn>
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>

      <v-divider></v-divider>

      <v-list
          dense
          nav expand
          class="text-left"
          v-if="response !=null"
      >
        <v-list-item link :to="{ name: 'start' }">
          <v-list-item-icon>
            <v-icon>home</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Dashboard</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-divider></v-divider>

        <v-list-item class="caption text-center blue--text">Event Menu Options</v-list-item>

        <v-divider></v-divider>

        <v-list-item link :to="{ name: 'event_closed', params:{event_:response.event}}"
                     v-if="response.event.type=== 1 && !response.event.open_plan">
          <v-list-item-icon>
            <v-icon>assessment</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title class="text-left">Overview</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item link :to="{ name: 'event_overview', params:{event:response}}"
                     v-else>
          <v-list-item-icon>
            <v-icon>assessment</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title class="text-left">Overview</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item link :to="{ name: 'event_team', params:{teams:response.event.teams}}"
                     v-if="showUsers">
          <v-list-item-icon>
            <v-icon>group</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title class="text-left">Team Management</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item link :to="{ name: 'event_map'}">
          <v-list-item-icon>
            <v-icon>explore</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title class="text-left">Checkin Map</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item link :to="{ name: 'event_route', params:{routes:response.routes}}"
                     v-if="response.event.type===1 && response.event.open_plan">
          <v-list-item-icon>
            <v-icon>directions</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title class="text-left">Route Plans</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item link :to="{ name: 'event_locations'}"
                     v-else-if="response.event.type===1 && !response.event.open_plan">
          <v-list-item-icon>
            <v-icon>location_on</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title class="text-left">Locations</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item link :to="{ name: 'event_timestamps', params:{timestamps:timestamps}}" v-else>
          <v-list-item-icon>
            <v-icon>timelapse</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title class="text-left">Timestamps</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item link :to="{ name: 'event_images', params:{images:response.images}}">
          <v-list-item-icon>
            <v-icon>insert_photo</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title class="text-left">Images</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item link :to="{ name: 'event_reports', params:{reports:response.submissions}}">
          <v-list-item-icon>
            <v-icon>assessment</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title class="text-left">Reports</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-divider></v-divider>

        <v-list-item class="caption text-center blue--text">System shortcuts</v-list-item>

        <v-divider></v-divider>

        <v-list-item link :to="{ name: 'locations' }">
          <v-list-item-icon>
            <v-icon>location_on</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Retail Network</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

      </v-list>
    </v-navigation-drawer>

    <div class="flex-grow-1 flex-shrink-1 grey lighten-5">

      <map :markers="markers"/>

    </div>
  </v-card>
</template>
<script>
import Map from "@/components/utils/map/Map";

export default {
  components: {Map},
  props: {
    markers: Array
  }
}

</script>
